/**
 * @description Create A registaion page with AWS Acogniti Authentication Porces 
 * @version 1.0
 * @author Ruchi kapil <ruchi@appypiellp.com>
 * @file CompanyRegistaion.js
 */
import { getCookie } from '../../services/service';
import React, { Component } from 'react';
import './Login.scss';
import { DOMAIN_NAME, COMMON_LOGIN_URL, DEFAULT_COMMONLOGIN_BLANK_KEYWORD, GATEWAY_URL, FUNCTION_API_KEY } from '../../config/siteConfig';
//import { mailUrl } from "../../config/mailFunctions.js";
import {
	signupLoginCheck,
	isUserExistWithEmail,
	getSessionCheck,
	logInWithCustomToken
} from "./../../config/SignupLogin";
// import db from './../../config/firebase';

import {
	createNewUser, updateSessioninCollection,
	getOwnerUserDataForLogin,
	updateAgentData,
	checkCompanyAliasAndCreateUnique
} from './Model/DbModel'
import { checkIsUserOrOwnerLogin, getUTCTimeDate, cleanupString } from './OnboardHelper';
///import data from "../../localization/login-registration.json";
import { activityLog } from "../../services/service";
import { saveAgentSession } from "../livechat/components/Comman";
import { deleteFilters } from "../livechat/components/Dblovefield";
import * as Realm from 'realm-web';


const firebaseAuthKey = "firebaseAuthInProgress";
const appTokenKey = "appToken";

const moment = require("moment-timezone");

const md5 = require("md5");
let REALM_ID = 'accounts-live-sdpwm';
let REALM_KEY = '';
const config = process.env.NODE_ENV === 'production' ? 'producofig' : 'devCofig';

if (process.env.REACT_APP_STAGE === "dev") {
  REALM_ID = 'accounts-dev-ctclr';
  REALM_KEY = 'Ve9dvUzJyMvbT1rkjmTRalt2No5wQhge5J7y0ZQWD6K7dHn9lqFkCgDE9y49X7if';
}



const credentials = Realm.Credentials.serverApiKey(REALM_KEY);
const app = new Realm.App({ id: REALM_ID });
let user = app.currentUser;

class VerifyEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loader: true
		}
	}

	// sendEmail = (body) => {
	// 	fetch(GATEWAY_URL + '/sendEmail', {
	// 		method: "post",
	// 		body: body,
	// 		headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
	// 	})
	// 		.then()
	// 		.then(response => {
	// 			console.log(response);
	// 		})
	// 		.catch(e => {
	// 			console.log(e, "ERROR");
	// 		});
	// }
	/**
	 * check if login or not
	 */
	componentDidMount = async () => {
			
			var userCommonLoginData=await this.getRealmSession()
			console.log(userCommonLoginData,"userCommonLoginData",userCommonLoginData.email)
			if (userCommonLoginData&&userCommonLoginData.email&&userCommonLoginData.email!='null'){
			var userdata=JSON.parse(localStorage.getItem('userdata'))
			console.log(userdata,'PREVERIFY')
			userdata.email=userCommonLoginData.email
			localStorage.setItem("userdata",JSON.stringify(userdata))
			
			window.location.href=DOMAIN_NAME + "/common-session"
			}else{
				window.location.href = COMMON_LOGIN_URL + "/verifycheck?frompage=" + DOMAIN_NAME + "/verify-email&website=" + DOMAIN_NAME;

			}
			
	}


	


 getRealmSession = async () => {
  try {
    const md5 = require('md5');
    if (!user) {
      user = await app.logIn(credentials);
    }
    // console.log(getCookie('connect.sid'),"COOKIESSSSSSS",getCookie("APPYID"))
    const fileName = getCookie("APPYID")
    console.log(fileName, "connectsid")
    const realmData = await user.functions.getSessionData(md5(fileName), 'both');
    let userData = realmData && realmData.userdata ? JSON.parse(realmData.userdata) : '';
    userData = userData && userData.userdata ? userData.userdata : '';
    console.log(userData, "getrealmsession",userData.email)
    // localStorage.setItem("userdata", JSON.stringify(userData));
    return userData;
  } catch (error) {
    console.error('Error', error);
    return "ERROR"
  }
};


	render() {
		return (
			<div className="login-container loginBG">
				<div className="loading" >Loading&#8230;</div>
			</div>
		);
	}
}

export default VerifyEmail;
