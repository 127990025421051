import React from 'react';
// import db, { storageConnetion } from '../../../config/firebase';
import md5 from 'md5';
import { UpdatePassword } from '../../../config/SignupLogin';
import Resizer from 'react-image-file-resizer';
import { toast } from "react-toastify";
import { editOwnerInAws, activityLog } from "../../../services/service";
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD } from "../../../config/siteConfig";
import moment from "moment-timezone";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import firebaseServices from '../../../firebase';

var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

class Profile extends React.Component {
    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.state = {
            first_name: '',
            first_name_error: '',
            last_name: '',
            last_name_error: '',
            profile_pic: '',
            profile_pic_remove: '',
            loader: false,
            showProfile: '',
            pic_ref: '',
            base64file: '',
            profile_pic_avatar: '',
            alias: '',
            email: '',
            showPassword: false,
            currPassword: '',
            old_password: '',
            new_password: '',
            confirm_password: '',
            old_password_error: '',
            new_password_error: '',
            confirm_password_error: '',
            role: '',
            deletePic: false,
            oldFileName: "notexist",
            oldThumbnailName: "notexist",
            file_error: '',
            timezone: ''
        }
    }

    componentDidMount() {
        // this.getAgentDetails();
    }

    getAgentDetails = () => {
        if (this.agent.ownerId && this.agent.agentId) {
            console.log('-------line no 38-------');
            this.setState({
                first_name: this.agent.first_name ? this.agent.first_name : this.agent.agent_name,
                last_name: this.agent.last_name && this.agent.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? this.agent.last_name : '',
                name: this.agent.agent_name,
                alias: this.agent.alias,
                profile_pic: this.agent.profile_img_orig ? this.agent.profile_img_orig : '',
                oldFileName: this.agent.oldFileName ? this.agent.oldFileName : "notexist",
                oldThumbnailName: this.agent.oldThumbnailName ? this.agent.oldThumbnailName : "notexist",
                profile_pic_avatar: this.agent.profile_img_orig ? this.agent.profile_img_orig : '',
                email: this.agent.ownerEmail ? this.agent.ownerEmail : this.agent.email,
                timezone: this.agent.timezone ? this.agent.timezone : (moment.tz.guess() === 'Asia/Calcutta' ? 'Asia/Kolkata' : moment.tz.guess()),
                // deletePic:false,
                loader: false
            })

            // getOwnerDetails(this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire).then((results) {

            // });
            //     firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').doc(this.agent.agentId).get().then((docs) => {
            //         console.log(docs);
            //         if(docs.exists) {
            //             var agentData = docs.data();
            //             this.setState({
            //                 name:agentData.name,
            //                 alias:agentData.alias,
            //                 profile_pic:agentData.profile_img_orig!==undefined ? agentData.profile_img_orig : '',
            //                 pic_ref:agentData.pic_ref,
            //                 profile_pic_avatar:agentData.profile_img,
            //                 email:this.agent.ownerEmail ? this.agent.ownerEmail : this.agent.email,
            //                 currPassword:agentData.password,
            //                 deletePic:false,
            //                 loader:false
            //             })
            //         } else {
            //             this.setState({
            //                 loader:false
            //             })
            //         }
            //     }).catch((err) => {
            //         console.log('line no 63 >>> ', err);
            //         this.setState({
            //             loader:false
            //         })
            //     })
        }
    }

    changehandle = (e) => {
        let target = e.target;
        this.setState({
            [target.name]: target.value
        })
        let errorName = target.name + '_error';
        if (target.value === '' && target.name !== 'alias') {
            this.setState({
                [errorName]: 'This field can not be blank!'
            })
        } else if (target.name === 'new_password' && re.test(target.value) === false) {
            this.setState({
                [errorName]: "Password must contain at least one number, one lowercase, one uppercase letter and consist min of 8 characters"
            })
            // } else if(target.name==='old_password' && this.state.role!=='Owner' && md5(target.value) !== this.state.currPassword) {
            //     this.setState({
            //         [errorName]:'Incorrect old password!'
            //     })
        }
        else {
            this.setState({
                [errorName]: ''
            })
        }
    }

    cancelFormSubmit = (e) => {
        e.preventDefault();
        this.props.hideProfilePage();
    }

    componentDidUpdate(prev_props) {
        if (prev_props.showProfilePage === false && this.props.showProfilePage === true) {
            this.setState({
                loader: true
            })
            this.getAgentDetails();
        }
    }

    deleteProfilePic = () => {
        this.setState({
            profilePic: '',
            deletePic: true,
            base64file: ''
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let validate = this.validateForm();
        console.log(validate);
        if (validate) {
            this.setState({
                loader: true
            })
            if (this.state.alias !== '') {
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').where("alias", "==", this.state.alias).where("deletetime", "==", 0).get().then(async (docs) => {
                    let OldData = [];
                    docs.forEach((data) => {
                        OldData.push(data.id);
                    })
                    if (docs.empty === true || OldData.indexOf(this.agent.agentId) > -1) {
                        if (this.state.showPassword) {
                            let upPwd = await UpdatePassword(this.state.email, this.state.old_password, this.state.new_password);

                            if (upPwd.res.forgotPasswordVerification.status === '401') {
                                this.setState({
                                    old_password_error: 'Incorrect Old Password',
                                    loader: false
                                })
                                return false;
                            }
                        } else if (this.state.showPassword) {
                            let newPassword = md5(this.state.new_password);
                            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').doc(this.agent.agentId).update({ password: newPassword });
                        }
                        const filesData1 = document.getElementById("profile_picture");
                        var filesData = filesData1.files;
                        let file = '';
                        let fileName = ''
                        if (filesData.length > 0) {
                            file = filesData[0];
                            fileName = file.name;
                        }
                        this.saveAgent(file, fileName);
                    } else {
                        this.setState({
                            loader: false,
                            alias_error: 'Alias is not available'
                        })
                    }
                });
            } else {
                const filesData1 = document.getElementById("profile_picture");
                var filesData = filesData1.files;
                let file = '';
                let fileName = ''
                if (filesData.length > 0) {
                    file = filesData[0];
                    fileName = file.name;
                }
                this.saveAgent(file, fileName);
            }
        }
    }

    async imageUploads(file) {
        const ref = firebaseServices.storageConnection;
        let userName = this.state.name.split(' ').join('_');
        let fileExt = (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : 'png';
        const name = +new Date() + "-" + userName + '.' + fileExt;
        const metadata = {
            contentType: file.type
        };
        //var _this = this
        let promise1 = new Promise(async (res, rej) => {
            const task = ref.child('profiles').child(name).put(file, metadata);
            await task
                .then(snapshot => snapshot.ref.getDownloadURL())
                .then(url => {
                    res(url);
                    if (this.state.profile_pic !== '') {
                        ref.child('profiles').child(this.state.pic_ref).delete();
                    }
                })
                .catch(console.error);
        })
        let promise2 = new Promise(async (resolve, reject) => {
            Resizer.imageFileResizer(
                file,
                64,
                64,
                'PNG',
                100,
                0,
                async uri => {
                    const task = ref.child('profiles').child('avatar').child(name).put(uri, metadata);
                    await task
                        .then(snapshot => snapshot.ref.getDownloadURL())
                        .then(url => {
                            resolve(url);
                            if (this.state.profile_pic_avatar !== '') {
                                ref.child('profiles').child('avatar').child(this.state.pic_ref).delete();
                            }

                        })
                        .catch(console.error);
                },
                'blob'
            );
        })

        Promise.all([promise1, promise2]).then(result => {
            this.saveAgent(result[0], result[1], name);
        }).catch(() => {
            this.saveAgent(this.state.profile_pic, this.state.profile_pic_avatar, this.state.pic_ref);
        })
    }

    saveAgent(file, fileName) {
        let name = this.state.first_name + ' ' + this.state.last_name;
        let alias = this.state.alias ? this.state.alias : name;
        editOwnerInAws(alias, this.state.first_name, this.state.last_name, this.state.email, this.state.timezone, file, fileName, this.state.oldFileName, this.state.oldThumbnailName, 0, false, this.state.deletePic).then((docs) => {
            
            if (docs.status === 200) {
                let responseData = docs.response;
                var localStorageValue = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
                if (localStorageValue) {
                    localStorageValue.alias = this.state.alias;
                    localStorageValue.first_name = this.state.first_name;
                    localStorageValue.last_name = this.state.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? this.state.last_name : '';
                    localStorageValue.profile_img = responseData.userdata.pic && responseData.userdata.pic !== 'notexist' ? responseData.userdata.imageurl + '/' + responseData.userdata.pic : '';
                    localStorageValue.profile_img_orig = responseData.userdata.pic && responseData.userdata.pic !== 'notexist' ? responseData.userdata.imageurl + '/' + responseData.userdata.pic : '';
                    localStorageValue.oldThumbnailName = responseData.userdata.thumbnail;
                    localStorageValue.oldFileName = responseData.userdata.pic;
                    localStorageValue.pic_ref = '';
                    localStorageValue.timezone = responseData.time_zone;
                    if (this.state.alias !== '' && this.state.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD) {
                        localStorageValue.name = this.state.alias
                    } else {
                        localStorageValue.name = this.state.last_name && this.state.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? this.state.first_name + ' ' + this.state.last_name : this.state.first_name
                    }
                    localStorageValue.agent_name = this.state.last_name && this.state.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? this.state.first_name + ' ' + this.state.last_name : this.state.first_name;
                    localStorage.setItem('agent', JSON.stringify(localStorageValue));
                }
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').doc(this.agent.agentId).update({
                    name: name,
                    alias: alias,
                    updatedon: new Date().getTime()
                }).then((ref) => {
                    document.getElementById("profile_picture").value = null;
                    this.setState({
                        loader: false,
                        name_error: ''
                    })
                    // this.props.updateProfile();
                    toast.success("Your Profile has been updated successfully");
                    activityLog('Updated data management profile');
                }).catch((error) => {
                    this.setState({
                        loader: false,
                        name_error: ''
                    })
                    console.error("Error adding document: ", error);
                });
            } else {
                this.setState({
                    loader: false
                })
                toast.error("Something went wrong! Please try again after some time");
            }
        });
    }

    validateForm = () => {
        let validForm = true;
        let first_name_error = ''
        let old_password_error = ''
        let new_password_error = ''
        let confirm_password_error = ''
        if (this.state.first_name === '') {
            validForm = false;
            first_name_error = 'This field can not be blank!'
        } else {
            first_name_error = ''
        }
        if (this.state.showPassword && this.state.old_password === '') {
            validForm = false;
            old_password_error = 'This field can not be blank!'
        }
        // else if(this.state.showPassword && this.state.role!=='Owner' && md5(this.state.old_password) !== this.state.currPassword) {
        //     validForm=false;
        //     this.setState({
        //         old_password_error:'Incorrect old password!'
        //     })
        // } 
        else {
            old_password_error = ''
        }
        if (this.state.showPassword && this.state.new_password === '') {
            validForm = false;
            new_password_error = 'This field can not be blank!'
        } else if (this.state.showPassword && re.test(this.state.new_password) === false) {
            validForm = false;
            new_password_error = "Password must contain at least one number, one lowercase, one uppercase letter and consist min of 8 characters"
        } else {
            new_password_error = ''
        }
        if (this.state.showPassword && this.state.confirm_password === '') {
            validForm = false;
            confirm_password_error = 'This field can not be blank!'
        } else if (this.state.showPassword && this.state.confirm_password !== this.state.new_password) {
            validForm = false;
            confirm_password_error = 'Incorrect confirm password'
        } else {
            confirm_password_error = ''
        }
        this.setState({
            first_name_error: first_name_error,
            old_password_error: old_password_error,
            new_password_error: new_password_error,
            confirm_password_error: confirm_password_error,
        })
        return validForm;
    }

    handleFile = async (files) => {
        this.setState({ 'uploadFileError': '', deletePic : false })
        const tempFile = files[0];
        if (tempFile) {
            var fileExtension = tempFile.name.substring(
                tempFile.name.lastIndexOf('.') + 1).toLowerCase();

            if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'svg' || fileExtension === 'jpeg' || fileExtension === 'gif') {
                Resizer.imageFileResizer(
                    tempFile,
                    100,
                    100,
                    'PNG',
                    100,
                    0,
                    uri => {
                        this.setState({
                            base64file: uri,
                            file_error: ''
                        })
                    },
                    'base64'
                );
            } else {
                this.setState({
                    file_error: 'Please upload valid image',
                    base64file: ''
                })
            }
        } else {
            this.setState({
                file_error: '',
                base64file: ''
            })
        }
    }

    render() {
        let profilePic = ''
        if (this.state.profile_pic_avatar !== '' && this.state.profile_pic_avatar !== undefined) {
            profilePic = <img alt="" src={this.state.profile_pic_avatar} />
        }
        return (
            <>
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                {this.agent.roleType === "Owner" && this.props.showProfilePage ? (<div className="rightPanelSection add-agent" >
                    <div className="categoryRightPanel">
                        <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                            <div className="right-panel-header k-flex align-items-center justify-content-center">
                                <h4 className="header-title heding-text text-truncate mr-auto white-text">Update Profile</h4>
                                <ul className="heder-icon">
                                    <li className="nav-item list-inline-item closeing-r">
                                        <span className="heder-icon-img" onClick={this.cancelFormSubmit}>
                                            <img alt="" src={require('../../../assets/img/icon/right-panel-crose.svg')} />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="bodyRightPanel">
                            <div className="rightPanelMain">
                                <div className="agent-form py-5">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-feilds px-5 py-4">
                                            {/* <div className="wrapperInfo">
                                            <label className="mb-1 small text-screen1">First Name</label>
                                            <input type="text" placeholder="Name*" className="w-100" name="first_name" value={this.state.first_name} onChange={this.changehandle} />
                                            <p className="error">{this.state.first_name_error}</p>
                                        </div> */}
                                            <div className="input-group material-input mb-5">
                                                <TextField label="First Name" id="first_name" type="text" className="form-control w-150" name="first_name" value={this.state.first_name} onChange={this.changehandle} variant="outlined" />
                                                <p className="error">{this.state.first_name_error}</p>
                                            </div>
                                        </div>
                                        <div className="form-feilds px-5 py-4">
                                            {/* <div className="wrapperInfo">
                                                <label className="mb-1 small text-screen1">Last Name</label>
                                                <input type="text" placeholder="Name*" className="w-100" name="last_name" value={this.state.last_name} onChange={this.changehandle} />
                                                <p className="error">{this.state.last_name_error}</p>
                                            </div> */}
                                            <div className="input-group material-input">
                                                <TextField label="Last Name" id="last_name" type="text" className="form-control w-150" name="last_name" value={this.state.last_name} onChange={this.changehandle} variant="outlined" />
                                                <p className="error">{this.state.first_name_error}</p>
                                            </div>
                                        </div>
                                        <div className="form-feilds px-5 py-4">
                                            {/* <div className="wrapperInfo">
                                                <label className="mb-1 small text-screen1">Alias</label>
                                                <input type="text" placeholder="Alias" className="w-100" name="alias" value={this.state.alias} onChange={this.changehandle} />
                                                <p className="error">{this.state.alias_error}</p>
                                            </div> */}
                                            <div className="input-group material-input">
                                                <TextField label="Alias" id="alias" type="text" className="form-control w-150" name="alias" value={this.state.alias} onChange={this.changehandle} variant="outlined" />
                                                <p className="error">{this.state.alias_error}</p>
                                            </div>
                                        </div>
                                        <div className="form-feilds px-5 py-4">
                                            {/* <div className="wrapperInfo">
                                                <label className="mb-1 small text-screen1">Profile picture</label>
                                                <span className="agent-rightpanel-feilds position-relative" style={{ height: '40px' }}>
                                                    <input type="file" className="w-100" id="profile_picture" name="profile_picture" onChange={(e) => this.handleFile(e.target.files)} />
                                                    <span className="add-profile-img-icon">
                                                        Add Profile picture
                                                        <img alt="" src={require('../../../assets/img/icon/upload.svg')} />
                                                    </span>
                                                </span>
                                                {((this.state.base64file !== '' || profilePic !== '') && !this.state.deletePic) ? (<div className="editpicture">
                                                    {this.state.base64file === '' && !this.state.deletePic ? (<span className="editpictureInner">{profilePic}
                                                        <span className="clearIMG" onClick={this.deleteProfilePic}>X</span>
                                                    </span>) : null}
                                                    {this.state.base64file !== '' ? (<span className="editpictureInner"><img alt="" src={this.state.base64file} />
                                                        <span className="clearIMG" onClick={this.deleteProfilePic}>X</span>
                                                    </span>) : null}
                                                </div>) : (null)}
                                                {this.state.file_error ? (<p className="error">{this.state.file_error}</p>) : (null)}
                                            </div> */}
                                            <div className="input-group material-input">
                                                <input type="file" accept="image/*" id="profile_picture" name="profile_picture" onChange={(e) => this.handleFile(e.target.files)} style={{ display: "none" }} />

                                                {(profilePic === '' && this.state.base64file === '') || this.state.deletePic ? (<div className="avatar avatar-sm d-xl-inline-block mt-2">
                                                    <span><img src={require('../../../assets/images/avtare-user.svg')} alt="profile pic" /></span>
                                                </div>) : (null)}

                                                {((this.state.base64file !== '' || profilePic !== '') && !this.state.deletePic) ? (<div className="avatar avatar-sm d-xl-inline-block mt-2">
                                                    {this.state.base64file === '' && !this.state.deletePic ? (<span>{profilePic}<img alt="" src={require('../../../assets/img/icon/right-panel-crose.svg')} onClick={this.deleteProfilePic} style={{ cursor: 'pointer', minWidth: 13, position: 'relative', top: -15, left: -11 }} /></span>) : null}
                                                    {this.state.base64file !== '' ? (<span className="editpictureInner"><img alt="" src={this.state.base64file} /><span className="clearIMG" onClick={this.deleteProfilePic}>X</span></span>) : null}
                                                </div>) : (null)}
                                                <Button variant="contained" onClick={() => { document.getElementById("profile_picture").click() }} color="default" startIcon={<CloudUploadIcon />}>Upload Profile Picture</Button>
                                                {this.state.file_error ? (<p className="error">{this.state.file_error}</p>) : (null)}
                                            </div>
                                        </div>
                                        <div className="form-feilds px-5 py-4">
                                            {/* <div className="wrapperInfo">
                                                <label className="mb-1 small text-screen1">Email</label>
                                                <input type="text" placeholder="Email*" className="w-100" name="email" value={this.state.email} readOnly />
                                            </div> */}
                                            <div className="input-group material-input mt-7">
                                                <TextField label="Email" id="email" type="text" className="form-control w-150" name="email" value={this.state.email} disabled variant="outlined" />
                                            </div>
                                        </div>
                                        {/* <div className="form-feilds px-5 py-4">
                                        <div className="wrapperInfo">
                                            <label className="mb-1 small text-screen1">Password &nbsp;&nbsp;&nbsp;{this.state.showPassword ? (<img alt="" src={ require('../../../assets/img/icon/right-panel-crose.svg') } style={{cursor:'pointer'}} onClick={() => this.setState({showPassword:false})}  />) : (<img alt="" src={ require('../../../assets/img/icon/agent_profile.svg') } style={{cursor:'pointer'}} onClick={() => this.setState({showPassword:true})} />)}</label>
                                            {!this.state.showPassword ? (<input type="text" placeholder="Password*" className="w-100" value='***********' style={{marginTop: 20}} readOnly />) : (null)}
                                        </div>
                                    </div> */}
                                        {this.state.showPassword ? (<><div className="form-feilds px-5 py-4">
                                            {/* <div className="wrapperInfo">
                                                <input type="password" placeholder="Old Password" className="w-100" name="old_password" value={this.state.old_password} onChange={this.changehandle} />
                                                <p className="error">{this.state.old_password_error}</p>
                                            </div> */}
                                            <div className="input-group material-input">
                                                <TextField label="Old Password" id="old_password" type="password" className="form-control w-150" name="old_password" value={this.state.old_password} onChange={this.changehandle} variant="outlined" />
                                                <p className="error">{this.state.old_password_error}</p>
                                            </div>
                                        </div>
                                            <div className="form-feilds px-5 py-4">
                                                {/* <div className="wrapperInfo">
                                                    <input type="password" placeholder="New Password" className="w-100" name="new_password" value={this.state.new_password} onChange={this.changehandle} />
                                                    <p className="error">{this.state.new_password_error}</p>
                                                </div> */}
                                                <div className="input-group material-input">
                                                    <TextField label="New Password" id="new_password" type="password" className="form-control w-150" name="new_password" value={this.state.new_password} onChange={this.changehandle} variant="outlined" />
                                                    <p className="error">{this.state.new_password_error}</p>
                                                </div>
                                            </div>
                                            <div className="form-feilds px-5 py-4">
                                                {/* <div className="wrapperInfo">
                                                    <input type="password" placeholder="Confirm Password" className="w-100" name="confirm_password" value={this.state.confirm_password} onChange={this.changehandle} />
                                                    <p className="error">{this.state.confirm_password_error}</p>
                                                </div> */}
                                                <div className="input-group material-input">
                                                    <TextField label="Confirm Password" id="confirm_password" type="password" className="form-control w-150" name="confirm_password" value={this.state.confirm_password} onChange={this.changehandle} variant="outlined" />
                                                    <p className="error">{this.state.confirm_password_error}</p>
                                                </div>
                                            </div></>) : (null)}
                                        <div className="form-feilds px-5 py-4">
                                            <div className="wrapperInfo">
                                                <button className="mr-2 btnBlueSettings">update</button>
                                                <button className="btnWhite" onClick={this.cancelFormSubmit}>Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : (null)}
            </>
        )
    }

}

export default Profile;