import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import InfiniteScroll from "react-infinite-scroller";
import ChatContext from '../../components/ChatContext';
// import db from "../../../../config/firebase";
import firebase1  from 'firebase/compat';
import { ENTER_EMAIL } from '../../../../config/siteConfig';
import {log} from "../../../../config/helper"
import { getTimeFromDate, getDateInUTC, validateEmail, nameIntials, isCallingEnable, removeHtmlTags } from '../../components/Comman'; //, checkIsVisitorsLeft
import { getChatCounter, insertChatCounter, updateChatCounter, deleteChatCounter, getPlanData, insertOrUpdateFilters, getFilters } from '../../components/Dblovefield';
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD, GATEWAY_URL, FUNCTION_API_KEY, DESK_API_URL, commonLoginApi, MAIL_FUNCTION_URL, REACT_APP_REALM_API_KEY, REACT_APP_REALM_API_ID, STORAGE_BUCKET } from '../../../../config/siteConfig';
import { startCall, startVoipCall, endCall, dialExtension, setCurrentChat } from '../../components/Twilio';
//import { ThisMonthInstance } from 'twilio/lib/rest/api/v2010/account/usage/record/thisMonth';
//import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
//import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getCookie } from '../../../../services/service';
import { getOwnerDetails, getAllAwsAgents } from "./../../../../services/service";
import $ from 'jquery';

import TextField from '@material-ui/core/TextField';

/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
/* For Dropdown End */

import { toast } from "react-toastify";
import * as Realm from "realm-web";
import ListComponent from '../leftSection/list';
import firebaseServices from '../../../../firebase';
const realApp = new Realm.App({ id: REACT_APP_REALM_API_ID });
// import createSequence from "../../components/tooltip-sequence"
const NEW_VISITOR_STATUS = "new"; /// Show on Right side
//const TIME_TO_HIDE_VISITOR = 180 * 1000; // In MiliSeconds
const visitor_time_window = 6*60; //in seconds

let loadOngoing = false;
let hidden_visitors = [];

class Livechat extends PureComponent {
    static contextType = ChatContext;  // this.context will work when it defined as is
    onlineAgentsSnapshot = null;
    newVisitorsSnapshot = null;
    servedVisitorSnapshot = null;
    constructor(props, context) {
        super(props);
        var offset = new Date().getTimezoneOffset() * 60000;
        this.inputTimer = "";
        this.online_time=0;
        this.searchText = "";
        this.searchTotal = 0;
        this.groupBy = "";
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.visitorData = [];
        this.countriesArray = [];
        this.pageUrlArray = [];
        this.deviceUsedArray = [];
        this.hasLoadedlazy = false;
        this.hasLoadedPrelazy = false;
        this.setIntval = false;
        this.closeVisitorpanel = context.isMobileDevice ? true : false;
        this.totalStickyNotes = 0;
        this.isStickyNotesCalled = false;
        this.totalDispPremVisitor = -1;
        this.totalDispNormVisitor = -1;
        this.chatOrgArray = '';
        this.chatText = '';
        this.transcriptLink = '';
        this.canCalling = isCallingEnable(this.agent);
        this.state = {
            visitors: [],
            visitors1: [],
            pastVisits: [],
            offset: offset,
            stickyNotes: [],
            departmentList: [],
            onlineAgents: [],
            availableAgents: [],
            filteredAgents: [],
            added_agent: [],
            showVisitorsList: true,
            groupByHtmlData: [],
            countries: [],
            pageUrls: [],
            devicesUsed: [],
            countryWithTotalUser: [],
            pageUrlWithTotalUser: [],
            deviceUsedWithTotalUser: [],
            filteredVisitors: [],
            displayVisitors: [],
            hasMore: true,
            pagesize: 10000,
            totalPage: 0,
            total: 0,
            currentPage: 0,
            filterOptions: { type: "", groupBy: "", searchText: "", countries: [], pageUrls: [], devicesUsed: [], total: 0 },
            optionFilterBy: "none",
            searchVisitorText: "",
            currentVisitor: {},
            open: [],
            idleTime: 0,
            shouldCheckLeftVisitor: true,
            visiterCountryPath: true,
            inactive_check: false,
            doRender: 0,
            refreshVisitors: false,
            transferBtnText: "Transfer",
            addAgentBtnText: "Done",
            checkReload: false,
            groupChatAgents: [],
            showAgents: false,
            seachedAgents: [],
            allOnlineAgents: [],
            allOnlineAgentsIdes: [],
            notesMsgs: {},
            filteredPreVisitors: [],
            displayPreVisitors: [],
            hasPreMore: true,
            totalPrePage: 0,
            currentPrePage: 0,
            servedVisitors: [],
            visitorType: "active",
            setting: {},
            ongoingVisitorsChats: {},
            showDialer: false,
            contactnumber: "",
            whatsappTemplates: [],
            showWhatsappTemplates: [],
            liveChatDocId: "",
            allAwsAgents: [],
            mobShowDialer: false,
            onButton: false,
            filterOptionSelected: "country",
            srchagenttoadd: "",
            agent_department: "",
            srchagenttotransfer: "",
            transferOrAddAgent: "",
            callRender: 0,
            stickyNotesLoader: false,
            statusIDs: [],
            previousTickets: [],
            deskAgents: [],
            deskTeams: [],
            filteredDeskAgents: [],
            filteredDeskTeams: [],
            deskUserId: '',
            deskOrgId: '',
            subjectTicket: 'Conversation with Client',
            descriptionTicket: '',
            assignedTo: '',
            assignedToName: '',
            assignedToEntity: '',
            assignedToStatus: '',
            assignedToRoleType: '',
            statusTicket: '',
            priorityStatus: '',
            subjectTicket_error: '',
            assignedTo_error: '',
            priorityStatus_error: '',
            statusTicket_error: '',
            descriptionTicket_error: '',
            clientEmail_error: '',
            clientName: '',
            showAssignedToMenu: false,
            isAgentTab: true,
            isAutoTicketing: false,
            transcriptURL: '',
            allAgentsRealm: [],
            deskContactId: '',
            clientEmailTicket: '',
            createTicketLoader: ''
        };
        this.getAllOnlineAgents();
        setInterval(() => {
            this.getAllOnlineAgents();
        }, 600*1000);
        // setTimeout(async () => {
        //     let r = await this.getCurrentTimeOnline();
        //     if(r){
        //         this.updateVisitors();
        //         if(loadOngoing){
        //             loadOngoing=false;
        //             this.getOngoingVisitors();
        //         }else{
        //             loadOngoing=true;
        //         }
        //     }
        // }, 100*1000);

        // setTimeout(() => {
        //     this.getAllOnlineAgents();
        // }, 100*1000);

        this.getAllDepartments();
        this.getIdleTime(); // get idle time

        /*checkIsVisitorsLeft(this.agent, 300);
        setInterval(() => {
            checkIsVisitorsLeft(this.agent, 300);
        }, 120000); */ // Interval of 2 minute
    }

    /* Auto fill Filters */

    setFiltersIfSelected = async () => {
        try {
            let sF = await getFilters();
            if (sF) {
                let sttFilterObj = {
                    searchVisitorText: sF.searchText ? sF.searchText : "",
                    optionFilterBy: sF.groupBy ? sF.groupBy : "none",
                    filterOptions: { type: sF.type, groupBy: sF.groupBy, searchText: sF.searchText, countries: sF.countries, pageUrls: sF.pageUrls, devicesUsed: sF.devicesUsed ? sF.devicesUsed : [], total: Date.now() }
                }
                if (sF.groupBy === "country") {
                    document.getElementById("optcountry").checked = true;
                } else if (sF.groupBy === "pageUrl") {
                    document.getElementById("optpageurl").checked = true;
                } else if (sF.groupBy === "deviceUsed") {
                    document.getElementById("optdeviceused").checked = true;
                }
                this.setState(sttFilterObj);
            }
        } catch (err) { }
    }

    ///// Functions from visitors component start ///////////

    centerClientLoading = (visitor) => {
        // console.log("💡🛠 -> file: index.js -> line 161 -> visitor", visitor);

        let key = visitor.id;
        let country = visitor.country;
        let browser = visitor.browser;
        let operating = visitor.operating;

        visitor.agent_read_count = 0;
        this.context.updateGlobalData("chatLoader",false)
        if (this.context.isMobileDevice) {
            try {
                document.getElementById('SideBarIO').classList.remove('IOLP');
                document.getElementById('new-Main-Chat').classList.remove('VRP');
                document.getElementById('new-Main-Chat').classList.remove('VRP2');
                document.getElementById('new-Main-Chat').classList.remove('VRP3');
            } catch (err) { }
        }

        const fromVisitor = visitor.fromVisitor ? visitor.fromVisitor : false;

        if (this.context.liveChatDocId === key) {
            return;
        }

        try {
            setCurrentChat(null);
        } catch (errrr) { }

        var arrayToSet = [];

        if (visitor.client_email === "") {
            visitor.client_email = visitor.visitor_email;
        }

        if (!validateEmail(visitor.client_email)) {
            visitor.client_email = ENTER_EMAIL;
        }

        if (!validateEmail(visitor.visitor_email)) {
            visitor.visitor_email = ENTER_EMAIL;
        }

        var Obj1 = {
            key: "currentVisitor",
            value: visitor
        }

        var Obj2 = {
            key: "midHeadercountry",
            value: country
        }

        var Obj3 = {
            key: "midHeaderbrowser",
            value: browser
        }

        var Obj4 = {
            key: "midHeaderoperating",
            value: operating
        }

        var Obj5 = null;
        if (fromVisitor === "served") {
            Obj5 = {
                key: "chatPickStatusTransaction",
                value: { key: key, status: "serving", Text: "Join Chat", msgToShow: null }
            }
        } else {
            if (visitor.agent_end === "VISITOR_END_CHAT") {
                Obj5 = {
                    key: "chatPickStatusTransaction",
                    value: { key: key, status: "close", Text: "Close window", msgToShow: visitor.message_to_show }
                }
            } else {
                Obj5 = {
                    key: "chatPickStatusTransaction",
                    value: { key: key, status: "start", Text: "Start Chat", msgToShow: null }
                }
            }
        }

        var Obj6 = {
            key: "showMidHeader",
            value: true
        }

        var Obj7 = {
            key: "liveChatDocId",
            value: key
        }

        var Obj8 = {
            key: "pickedFromVisitorId",
            value: key
        }

        arrayToSet.push(Obj1);
        arrayToSet.push(Obj2);
        arrayToSet.push(Obj3);
        arrayToSet.push(Obj4);
        arrayToSet.push(Obj5);
        arrayToSet.push(Obj6);
        arrayToSet.push(Obj7);
        arrayToSet.push(Obj8);
        this.context.updateGlobalData("array", arrayToSet);

        // Clearbit Enrichement API is called HERE        
        if (this.agent.revealDataEnabled) {
            let enrichmentData = {};
            let mailId = (visitor.visitor_email !== 'noreply@appypie.com' && visitor.visitor_email !== '') ? visitor.visitor_email : visitor.client_email;
            try {
                if (mailId !== 'noreply@appypie.com' && mailId !== '') {
                    firebaseServices.db.collection('enrichmentUsers').doc(mailId).get()
                        .then((doc) => {
                            if (doc.exists) {
                                enrichmentData = doc.data();
                                enrichmentData.visitorId = visitor.id;
                                // console.log("💡🛠 -> file: index.js -> line 278 -> enrichmentData from DB", enrichmentData);
                                this.context.updateGlobalData("enrichmentData", enrichmentData)
                            }
                            else {
                                this.context.updateGlobalData("enrichmentData", "");
                            }
                        })
                        .catch((err) => {
                            console.log('ERROR at clearbit enrichmentData DB: ' + err.message);
                            this.context.updateGlobalData("enrichmentData", "");
                        });
                }
            } catch (err) {
                console.log('ERROR at clearbit enrichmentData: ' + err.message);
                this.context.updateGlobalData("enrichmentData", "");
            }
            // Clearbit Enrichement API is called ENDS HERE

            // if (visitorType === 'Premium') {
            try {
                firebaseServices.db.collection('premiumUsers').doc(visitor.IPv4).get()
                    .then((doc) => {
                        let revealData = "";
                        if (doc.exists) {
                            revealData = doc.data();
                            revealData.visitorId = visitor.id;
                        }
                        // console.log("💡🛠 -> file: index.js -> line 284 -> revealData", revealData);
                        this.context.updateGlobalData("revealData", revealData)
                    }).catch((err) => {
                        console.log('ERROR at clearbit revealData: ' + err.message);
                        this.context.updateGlobalData("revealData", "");
                    });
            } catch (err) {
                console.log('ERROR at clearbit revealData: ' + err.message);
                this.context.updateGlobalData("revealData", "")
            }
        }
    }

    hideVisitorsList = () => {
        this.closeVisitorpanel = true;
        this.context.updateGlobalData("rightSectionItemToShow", "hideall");
        var element = document.getElementById('visitorGroupRightIcon');
        if(element){
            element.classList.remove("active");
            document.getElementById('new-Main-Chat').classList.remove("VRP");
        }
        
    }

    showFilterGroupList = () => {
        this.context.updateGlobalData("rightSectionItemToShow", "filterBy");
    }

    createFiltersList = () => {
        var ordBy = [];
        if (this.state.optionFilterBy !== "none") {
            ordBy.push(this.state.optionFilterBy);
        }
        var allFilters = [...ordBy, ...this.state.filterOptions.countries, ...this.state.filterOptions.pageUrls, ...this.state.filterOptions.devicesUsed];
        if (allFilters.length <= 2) {
            return allFilters.map((countCode, idx) =>
                <li key={idx}>
                    <span title={countCode === "pageUrl" ? "Page URL" : countCode === "deviceUsed" ? "Device Type" : countCode} className="name-filt-title">{countCode === "pageUrl" ? "Page URL" : countCode === "deviceUsed" ? "Device Type" : countCode}</span>
                    <span onClick={() => this.removeSingleFilter(countCode)} className="pl-2 cursor-pointer"><img alt="" src={require('../../../../assets/images/livechat/visiter-tag-crose.svg')} /></span>
                </li>)
        } else {
            let array1 = allFilters.slice(0, 2);
            let array2 = allFilters.slice(2, allFilters.length);
            return (<>
                {array1.map((countCode, idx) =>
                    <li key={idx}>
                        <span title={countCode === "pageUrl" ? "Page URL" : countCode === "deviceUsed" ? "Device Type" : countCode} className="name-filt-title">{countCode === "pageUrl" ? "Page URL" : countCode === "deviceUsed" ? "Device Type" : countCode}</span>
                        <span onClick={() => this.removeSingleFilter(countCode)} className="pl-2 cursor-pointer"><img alt="" src={require('../../../../assets/images/livechat/visiter-tag-crose.svg')} /></span>
                    </li>)}

                <li key="2" className="position-relative">
                    +{allFilters.length - 2}
                    <div className="visitore-list">
                        {array2.map((countCode, idx) =>
                            <p title={countCode === "pageUrl" ? "Page URL" : countCode === "deviceUsed" ? "Device Type" : countCode} onClick={() => this.removeSingleFilter(countCode)} key={idx} className="small mb-0 text-screen1 name-chat-header">{countCode === "pageUrl" ? "Page URL" : countCode === "deviceUsed" ? "Device Type" : countCode}</p>)}
                    </div>
                </li>
            </>);
        }
    }

    getCurrentTimeOnline = async () =>{
        return new Promise( async (Resolve,Reject) => {
            try {
                // let r = await fetch('https://worldtimeapi.org/api/timezone/Asia/Kolkata')
                // let data = await r.json();
                // this.online_time = data.unixtime;
                const serverTimestamp3 = await getDateInUTC();
                // console.log(serverTimestamp3,"serverTimestamp3");
                this.online_time = serverTimestamp3/1000
                Resolve(true);   
            } catch (error) {
                Resolve(false);
            }
        });
    }

    removeSingleFilter = async (code) => {
        var isFind = false;
        var fname = "";
        let tmpfilterOptions = this.state.filterOptions;
        //this.setState({ callRender: Date.now(),  filterOptions: tmpFilterOptions });

        if (this.state.optionFilterBy !== "none") {
            if (code === this.state.optionFilterBy) {
                isFind = true;
                fname = "group";
            }
            if (isFind === false) {
                if ((this.state.filterOptions.countries).indexOf(code) !== -1) {
                    isFind = true;
                    fname = "country";
                }
            }
            if (isFind === false) {
                if ((this.state.filterOptions.pageUrls).indexOf(code) !== -1) {
                    isFind = true;
                    fname = "pageurl";
                }
            }
            if (isFind === false) {
                if ((this.state.filterOptions.devicesUsed).indexOf(code) !== -1) {
                    isFind = true;
                    fname = "deviceUsed";
                }
            }
            if (isFind === true && fname !== "") {
                if (fname === "group") {
                    this.resetFilterOrderByOption();
                    /*this.setState({optionFilterBy : "none"}, () => {
                        this.mapCountryUrlsDevicesList();
                    });*/
                } else if (fname === "country") {
                    var countrys = this.state.filterOptions.countries;
                    var index = countrys.indexOf(code);
                    countrys.splice(index, 1);
                    var allFilters = [...countrys, ...this.state.filterOptions.pageUrls, ...this.state.filterOptions.devicesUsed];
                    if (allFilters.length > 0) {
                        const serverTimestamp = Date.now();
                        tmpfilterOptions.type = "filters";
                        tmpfilterOptions.countries = countrys;
                        tmpfilterOptions.total = serverTimestamp;
                        try {
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: tmpfilterOptions }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                        /* this.setState({ filterOptions: { type: "filters", groupBy: "", searchText: "", countries: countrys, pageUrls: this.state.filterOptions.pageUrls, total: serverTimestamp } }, () => {
                            this.mapCountryUrlsDevicesList();
                        }); */
                    } else {
                        tmpfilterOptions.countries = [];
                        tmpfilterOptions.total = 0;
                        try {
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: tmpfilterOptions }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                        /* this.setState({ filterOptions: { type: "", groupBy: "", searchText: "", countries: [], pageUrls: [], total: 0 } }, () => {
                            this.mapCountryUrlsDevicesList();
                        }); */
                    }
                } else if (fname === "pageurl") {
                    var countrys2 = this.state.filterOptions.pageUrls;
                    var countrys2index = countrys2.indexOf(code);
                    countrys2.splice(countrys2index, 1);
                    var allFilters2 = [...this.state.filterOptions.countries, ...countrys2, ...this.state.filterOptions.devicesUsed];
                    if (allFilters2.length > 0) {
                        const serverTimestamp = Date.now();
                        tmpfilterOptions.type = "filters";
                        tmpfilterOptions.pageUrls = countrys2;
                        tmpfilterOptions.total = serverTimestamp;
                        try {
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: tmpfilterOptions }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                        /* this.setState({ filterOptions: { type: "filters", groupBy: "", searchText: "", countries: this.state.filterOptions.countries, pageUrls: countrys2, total: serverTimestamp } }, () => {
                            this.mapCountryUrlsDevicesList();
                        }); */
                    } else {
                        tmpfilterOptions.pageUrls = [];
                        tmpfilterOptions.total = 0;
                        try {
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: tmpfilterOptions }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                        /* this.setState({ filterOptions: { type: "", groupBy: "", searchText: "", countries: [], pageUrls: [], total: 0 } }, () => {
                            this.mapCountryUrlsDevicesList();
                        }); */
                    }
                } else if (fname === "deviceUsed") {
                    var countrys3 = this.state.filterOptions.devicesUsed;
                    var countrys3index = countrys3.indexOf(code);
                    countrys3.splice(countrys3index, 1);
                    var allFilters3 = [...this.state.filterOptions.countries, ...this.state.filterOptions.pageUrls, ...countrys3];
                    if (allFilters3.length > 0) {
                        const serverTimestamp = Date.now();
                        tmpfilterOptions.type = "filters";
                        tmpfilterOptions.devicesUsed = countrys3;
                        tmpfilterOptions.total = serverTimestamp;
                        try {
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: tmpfilterOptions }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                        /* this.setState({ filterOptions: { type: "filters", groupBy: "", searchText: "", countries: this.state.filterOptions.countries, pageUrls: countrys3, total: serverTimestamp } }, () => {
                            this.mapCountryUrlsDevicesList();
                        }); */
                    } else {
                        tmpfilterOptions.devicesUsed = [];
                        tmpfilterOptions.total = 0;
                        try {
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: tmpfilterOptions }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                        /* this.setState({ filterOptions: { type: "", groupBy: "", searchText: "", countries: [], pageUrls: [], total: 0 } }, () => {
                            this.mapCountryUrlsDevicesList();
                        }); */
                    }
                }
            }
        } else {
            if ((this.state.filterOptions.countries).indexOf(code) !== -1) {
                isFind = true;
                fname = "country";
            }

            if (isFind === false) {
                if ((this.state.filterOptions.pageUrls).indexOf(code) !== -1) {
                    isFind = true;
                    fname = "pageurl";
                }
            }

            if (isFind === false) {
                if ((this.state.filterOptions.devicesUsed).indexOf(code) !== -1) {
                    isFind = true;
                    fname = "deviceUsed";
                }
            }

            if (isFind === true && fname !== "") {
                if (fname === "country") {
                    var countrys4 = this.state.filterOptions.countries;
                    var countrys4index = countrys4.indexOf(code);
                    countrys4.splice(countrys4index, 1);
                    var allFilters4 = [...countrys4, ...this.state.filterOptions.pageUrls, ...this.state.filterOptions.devicesUsed];
                    if (allFilters4.length > 0) {
                        const serverTimestamp = Date.now();
                        tmpfilterOptions.type = "filters";
                        tmpfilterOptions.countries = countrys4;
                        tmpfilterOptions.total = serverTimestamp;
                        try {
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: tmpfilterOptions }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                        /* this.setState({ filterOptions: { type: "filters", groupBy: "", searchText: "", countries: countrys4, pageUrls: this.state.filterOptions.pageUrls, total: serverTimestamp } }, () => {
                            this.mapCountryUrlsDevicesList();
                        }); */
                    } else {
                        tmpfilterOptions.countries = [];
                        tmpfilterOptions.total = 0;
                        try {
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: tmpfilterOptions }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                        /* this.setState({ filterOptions: { type: "", groupBy: "", searchText: "", countries: [], pageUrls: [], total: 0 } }, () => {
                            this.mapCountryUrlsDevicesList();
                        }); */
                    }
                } else if (fname === "pageurl") {
                    var countrys5 = this.state.filterOptions.pageUrls;
                    var countrys5index = countrys5.indexOf(code);
                    countrys5.splice(countrys5index, 1);
                    var allFilters5 = [...this.state.filterOptions.countries, ...countrys5, ...this.state.filterOptions.devicesUsed];
                    if (allFilters5.length > 0) {
                        const serverTimestamp = Date.now();
                        tmpfilterOptions.type = "filters";
                        tmpfilterOptions.pageUrls = countrys5;
                        tmpfilterOptions.total = serverTimestamp;
                        try {
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: tmpfilterOptions }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                        /* this.setState({ filterOptions: { type: "filters", groupBy: "", searchText: "", countries: this.state.filterOptions.countries, pageUrls: countrys5, total: serverTimestamp } }, () => {
                            this.mapCountryUrlsDevicesList();
                        }); */
                    } else {
                        tmpfilterOptions.pageUrls = [];
                        tmpfilterOptions.total = 0;
                        try {
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: tmpfilterOptions }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                        /* this.setState({ filterOptions: { type: "", groupBy: "", searchText: "", countries: [], pageUrls: [], total: 0 } }, () => {
                            this.mapCountryUrlsDevicesList();
                        }); */
                    }
                } else if (fname === "deviceUsed") {
                    var countrys6 = this.state.filterOptions.devicesUsed;
                    var countrys6index = countrys6.indexOf(code);
                    countrys6.splice(countrys6index, 1);
                    var allFilters6 = [...this.state.filterOptions.countries, ...this.state.filterOptions.pageUrls, ...countrys6];
                    if (allFilters6.length > 0) {
                        const serverTimestamp = Date.now();
                        tmpfilterOptions.type = "filters";
                        tmpfilterOptions.devicesUsed = countrys6;
                        tmpfilterOptions.total = serverTimestamp;
                        try {
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: tmpfilterOptions }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                        /* this.setState({ filterOptions: { type: "filters", groupBy: "", searchText: "", countries: this.state.filterOptions.countries, pageUrls: countrys6, total: serverTimestamp } }, () => {
                            this.mapCountryUrlsDevicesList();
                        }); */
                    } else {
                        tmpfilterOptions.devicesUsed = [];
                        tmpfilterOptions.total = 0;
                        try {
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: tmpfilterOptions }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                        /* this.setState({ filterOptions: { type: "", groupBy: "", searchText: "", countries: [], pageUrls: [], total: 0 } }, () => {
                            this.mapCountryUrlsDevicesList();
                        }); */
                    }
                }
            }
        }
    }

    showFilterList = () => {
        this.context.updateGlobalData("rightSectionItemToShow", "filterList");
    }

    handleSearchChange = (e) => {
        this.setState({ searchVisitorText: e.target.value });
    }

    searchVisitors = (e) => {
        if ((e && e.keyCode) || e === false) {
            var searchText = '';
            var typep = "";
            if (e === false) {
                searchText = document.getElementById("searchvisitor").value;
                searchText = searchText.trim();
                typep = "text";
                if (this.state.optionFilterBy === "none") {
                    try {
                        let tmpfilterOptions = this.state.filterOptions;
                        tmpfilterOptions.type = typep;
                        tmpfilterOptions.searchText = searchText;
                        tmpfilterOptions.groupBy = "";
                        tmpfilterOptions.total = Date.now();
                        insertOrUpdateFilters(tmpfilterOptions);
                    } catch (errr) { }
                    this.setState({ filterOptions: { type: typep, groupBy: "", searchText: searchText, countries: [], pageUrls: [], devicesUsed: [], total: 0 } }, () => {
                        this.mapCountryUrlsDevicesList();
                    });
                } else {
                    try {
                        let tmpfilterOptions = this.state.filterOptions;
                        tmpfilterOptions.type = typep;
                        tmpfilterOptions.searchText = searchText;
                        tmpfilterOptions.groupBy = this.state.optionFilterBy;
                        tmpfilterOptions.total = Date.now();
                        insertOrUpdateFilters(tmpfilterOptions);
                    } catch (errr) { }
                    this.setState({ filterOptions: { type: typep, groupBy: this.state.optionFilterBy, searchText: searchText, countries: [], pageUrls: [], devicesUsed: [], total: 0 } }, () => {
                        this.mapCountryUrlsDevicesList();
                    });
                }
            } else {
                e.persist();
                e.preventDefault();
                searchText = this.state.searchVisitorText;
                var tmpSrchText = this.state.filterOptions.searchText ? this.state.filterOptions.searchText : "";
                if (searchText === null || searchText === "") {
                    searchText = "";
                } else {
                    typep = "text";
                }
                if (e.keyCode === 13 || (!searchText && tmpSrchText !== "")) {
                    if (this.state.optionFilterBy === "none") {
                        try {
                            let tmpfilterOptions = this.state.filterOptions;
                            tmpfilterOptions.type = typep;
                            tmpfilterOptions.searchText = searchText;
                            tmpfilterOptions.groupBy = "";
                            tmpfilterOptions.total = Date.now();
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: { type: typep, groupBy: "", searchText: searchText, countries: [], pageUrls: [], devicesUsed: [], total: 0 } }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                    } else {
                        try {
                            let tmpfilterOptions = this.state.filterOptions;
                            tmpfilterOptions.type = typep;
                            tmpfilterOptions.searchText = searchText;
                            tmpfilterOptions.groupBy = this.state.optionFilterBy;
                            tmpfilterOptions.total = Date.now();
                            insertOrUpdateFilters(tmpfilterOptions);
                        } catch (errr) { }
                        this.setState({ filterOptions: { type: typep, groupBy: this.state.optionFilterBy, searchText: searchText, countries: [], pageUrls: [], devicesUsed: [], total: 0 } }, () => {
                            this.mapCountryUrlsDevicesList();
                        });
                    }
                }
            }
        }
    }

    /* For Lazy Loader Start */

    loadMoreVisitors = (fromScroll) => {
        // console.log("load more visitors")
        if (fromScroll) {
            if (this.state.currentPage + 1 < this.state.totalPage) {
                let curpage = this.state.currentPage < this.state.totalPage ? this.state.currentPage + 1 : this.state.currentPage;
                let visitors = this.state.filteredVisitors.slice(0, (curpage + 1) * this.state.pagesize);
                this.setState({ displayVisitors: visitors, currentPage: curpage, hasMore: true });
            } else {
                if (this.state.currentPage + 1 === this.state.totalPage && this.state.hasMore === true) {
                    this.setState({ hasMore: false });
                }
            }
        } else {
            var totalPage = Math.ceil((this.state.filteredVisitors).length / this.state.pagesize);
            if (totalPage === 0 || totalPage === 1) {
                this.setState({ displayVisitors: this.state.filteredVisitors, hasMore: false });
            } else if (this.state.currentPage + 1 < totalPage) {
                let curpage = this.state.currentPage < totalPage ? this.state.currentPage + 1 : this.state.currentPage;
                let visitors = this.state.filteredVisitors.slice(0, (curpage + 1) * this.state.pagesize);
                this.setState({ displayVisitors: visitors, totalPage: totalPage, hasMore: true });
            } else if (this.state.currentPage + 1 === totalPage) {
                this.setState({ displayVisitors: this.state.filteredVisitors, totalPage: totalPage });
            } else if (this.state.currentPage === totalPage) {
                var curpage = this.state.currentPage - 1;
                this.setState({ displayVisitors: this.state.filteredVisitors, currentPage: curpage, totalPage: totalPage });
            }
        }
    }

    /* For Premium visito   rs Start */

    loadMorePreVisitors = (fromScroll) => {
        if (fromScroll) {
            if (this.state.currentPrePage + 1 < this.state.totalPrePage) {
                let curpage = this.state.currentPrePage < this.state.totalPrePage ? this.state.currentPrePage + 1 : this.state.currentPrePage;
                let visitors = this.state.filteredPreVisitors.slice(0, (curpage + 1) * this.state.pagesize);
                this.setState({ displayPreVisitors: visitors, currentPrePage: curpage, hasPreMore: true });
            } else {
                if (this.state.currentPrePage + 1 === this.state.totalPrePage && this.state.hasPreMore === true) {
                    this.setState({ hasPreMore: false });
                }
            }
        } else {
            var totalPrePage = Math.ceil((this.state.filteredPreVisitors).length / this.state.pagesize);
            if (totalPrePage === 0 || totalPrePage === 1) {
                this.setState({ displayPreVisitors: this.state.filteredPreVisitors, hasPreMore: false });
            } else if (this.state.currentPrePage + 1 < totalPrePage) {
                let curpage = this.state.currentPrePage < totalPrePage ? this.state.currentPrePage + 1 : this.state.currentPrePage;
                let visitors = this.state.filteredPreVisitors.slice(0, (curpage + 1) * this.state.pagesize);
                this.setState({ displayPreVisitors: visitors, totalPrePage: totalPrePage, hasPreMore: true });
            } else if (this.state.currentPrePage + 1 === totalPrePage) {
                this.setState({ displayPreVisitors: this.state.filteredPreVisitors, totalPrePage: totalPrePage });
            } else if (this.state.currentPrePage === totalPrePage) {
                var curpage = this.state.currentPrePage - 1;
                this.setState({ displayPreVisitors: this.state.filteredPreVisitors, currentPrePage: curpage, totalPrePage: totalPrePage });
            }
        }
    }

    /* For Premium visitors End */

    /* For Lazy Loader End */

    ///// Functions from visitors component End ///////////

    ////////// Private function start //////////

    /* Transfor agents functions start */

    getAllDepartments = () => {
        const departmentList = [];
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('departments').get().then(querySnapshot => {
            querySnapshot.forEach((doc) => {
                const { name } = doc.data();
                departmentList.push({
                    key: doc.id,
                    doc,
                    name
                });
            });
            this.setState({ departmentList });
        });
    }

    getAllOnlineAgents = async () => {

        this.onlineAgentsSnapshot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').where("online_status", "==", 1).where("deletetime", "==", 0).get().then(async (querySnapshot) => {
            console.log("getAllOnlineAgents")
            let ownerDetails = await getOwnerDetails(this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire);
            // let ownerDetails = await getOwnerAfterLogin();
            let ownerDetailsData = {};
            let profile_img = '';
            if (ownerDetails.status === 200) {
                ownerDetailsData = ownerDetails.data;
            }
            /* updated by dharmesh for getting agent details from AWS */
            let getAgentListInAws = [];
            if (this.state.allAwsAgents.length > 0) {
                getAgentListInAws = this.state.allAwsAgents;
            } else {
                getAgentListInAws = await getAllAwsAgents();
                this.setState({
                    allAwsAgents: getAgentListInAws
                })
            }

            var onlineAgents = [];
            var allOnlineAgents = [];
            var allOnlineAgentsIdes = [];
            querySnapshot.forEach((doc) => {
                let resultData = [];
                let currenTime = new Date().getTime();
                if (doc.data().roleType === 'Owner') {
                    let ownerData = doc.data();
                    if (ownerDetails.status === 200) {
                        ownerData.first_name = ownerDetailsData.first_name ? ownerDetailsData.first_name : '';
                        ownerData.last_name = ownerDetailsData.last_name && ownerDetailsData.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? ownerDetailsData.last_name : '';
                        ownerData.pic = ownerDetailsData.pic ? ownerDetailsData.pic : '';
                        ownerData.thumbnail = ownerDetailsData.thumbnail ? ownerDetailsData.thumbnail : '';
                        ownerData.imageurl = ownerDetailsData.imageurl ? ownerDetailsData.imageurl : '';
                        ownerData.timezone = ownerDetailsData.time_zone ? ownerDetailsData.time_zone : '';
                        ownerData.email = ownerDetailsData.email ? ownerDetailsData.email : '';
                        ownerData.alias = ownerDetailsData.alias && ownerDetailsData.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? ownerDetailsData.alias : '';
                        ownerData.profile_thumbnail = ownerDetailsData.thumbnail && ownerDetailsData.thumbnail !== 'notexist' ? ownerDetailsData.imageurl + '/' + ownerDetailsData.thumbnail + '?v=' + currenTime : '';
                        ownerData.profile_img = ownerDetailsData.thumbnail && ownerDetailsData.thumbnail !== 'notexist' ? ownerDetailsData.imageurl + '/' + ownerDetailsData.thumbnail + '?v=' + currenTime : '';
                        profile_img = ownerDetailsData.thumbnail && ownerDetailsData.thumbnail !== 'notexist' ? ownerDetailsData.imageurl + '/' + ownerDetailsData.thumbnail + '?v=' + currenTime : ''
                    }
                    resultData[0] = ownerData;
                    resultData[0]['id'] = doc.id;
                } else {
                    resultData = getAgentListInAws.filter(obj => {
                        return obj.subid === doc.id
                    });
                    if (resultData.length > 0) {
                        if (resultData[0].thumbnail && resultData[0].thumbnail !== '' && resultData[0].thumbnail !== 'notexist') {
                            resultData[0].profile_thumbnail = resultData[0].imageurl + '/' + resultData[0].thumbnail;
                            let currentTime = new Date().getTime();
                            profile_img = resultData[0].imageurl + '/' + resultData[0].thumbnail + '?v=' + currentTime;
                        } else {
                            resultData[0].profile_thumbnail = '';
                            profile_img = '';
                        }
                    }
                }
                // let profile_img = resultData.length > 0 ? resultData[0].profile_thumbnail : '';
                let email = resultData.length > 0 ? resultData[0].email : '';
                let name = resultData.length > 0 ? (resultData[0].last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? resultData[0].first_name + ' ' + resultData[0].last_name : resultData[0].first_name) : doc.data().name;
                let alias = resultData.length > 0 && resultData[0].alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? resultData[0].alias : '';
                const {
                    // name,
                    // alias,
                    // mobile,
                    role,
                    // email,
                    // profile_img,
                    departments,
                    last_activity_time
                } = doc.data();

                let tmpAlias = alias.trim();
                if (tmpAlias === "") {
                    tmpAlias = name;
                }

                var agent_short = nameIntials(tmpAlias);
                var name_short = nameIntials(name);
                let mobile = doc.data().mobile ? doc.data().mobile : ''
                let agt = {
                    key: doc.id,
                    name,
                    alias,
                    mobile,
                    role,
                    email,
                    departments,
                    profile_img,
                    agent_short,
                    last_activity_time,
                };

                agt.agent_name = agt.name;
                agt.alias = tmpAlias;
                agt.name = tmpAlias;
                agt.name_short = name_short;
                agt.phone_number = doc.data().phone_number ? doc.data().phone_number : false;
                agt.calling_status = doc.data().calling_status ? doc.data().calling_status : false;
                allOnlineAgents.push(agt);
                allOnlineAgentsIdes.push(doc.id);
                if (doc.id !== this.agent.agentId) {
                    onlineAgents.push(agt);
                }
                if (doc.id === this.agent.agentId) {
                    this.context.updateGlobalData("userProfileImage", profile_img);
                }
            });
            this.setState({ onlineAgents: onlineAgents, filteredAgents: onlineAgents, allOnlineAgents: allOnlineAgents, allOnlineAgentsIdes: allOnlineAgentsIdes }, () => {
                this.searchAgentToTransfer();
                this.searchAgent();
            });
        }).catch(err=>{
            console.log(err,"fetching accoutn details  -----kd-----");
        })
    }

    transferAgentList = (agent) => {
        var added_agent = this.state.added_agent;
        if (added_agent.length === 0) {
            added_agent.push({ key: agent.key, profile_img: agent.profile_img, name: agent.name, agent_short: agent.agent_short, agent_name: agent.agent_name, name_short: agent.name_short, email: agent.email, calling_status: agent.calling_status });
            this.setState({ added_agent: added_agent }, () => {
                this.searchAgentToTransfer();
            });
        } else {
            console.log("Chat cannot be transfered more than one, please remove already added Agent");
        }
    }

    /* Transfor agents functions End */


    mapCountryUrlsDevicesList = async () => {
        // console.log("mapCountryUrlsDevicesList");

        var allCountries = this.state.countries ? this.state.countries : [];
        var allPageUrls = this.state.pageUrls ? this.state.pageUrls : [];
        var allDevicesUsed = this.state.devicesUsed ? this.state.devicesUsed : [];
        var filteredVisitors = [];

        var visitorType = this.state.visitorType ? this.state.visitorType : "active";
        var allVisitors = [];
        if (visitorType === "served") {
            allVisitors = this.state.servedVisitors;
        } else {
            allVisitors = this.state.visitors;
        }

        /* For Premium and Normal Visitor Start */
        var filteredPreVisitors = [];
        var normalOrPremiumVtrs = allVisitors.reduce((all, item, index) => {
            all[item.isPremiumNormal].push(item);
            return all;
        }, { premium: [], normal: [] });

        var normalVisitors = normalOrPremiumVtrs.normal ? normalOrPremiumVtrs.normal : [];
        var premiumVisitors = normalOrPremiumVtrs.premium ? normalOrPremiumVtrs.premium : [];

        normalVisitors.sort(function (a, b) { return b.addedon - a.addedon; });
        premiumVisitors.sort(function (a, b) { return b.addedon - a.addedon; });
        /* For Premium and Normal Visitor End */

        //// allVisitors.sort(function (a, b) { return b.addedon - a.addedon; });
        var countriesMap = allCountries.reduce(function (prev, cur) {
            const spltCur = cur.split("-$$$-");
            const tmpCur = spltCur[0];
            prev[tmpCur] = (prev[tmpCur] || 0) + 1;
            return prev;
        }, {});

        var pageUrlsMap = allPageUrls.reduce(function (prev, cur) {
            const spltCur2 = cur.split("-$$$-");
            const tmpCur2 = spltCur2[0];
            prev[tmpCur2] = (prev[tmpCur2] || 0) + 1;
            return prev;
        }, {});

        var devicesUsedMap = allDevicesUsed.reduce(function (prev, cur) {
            const spltCur2 = cur.split("-$$$-");
            const tmpCur2 = spltCur2[0] === "undefined" || spltCur2[0] === "" ? 'Others' : spltCur2[0];
            prev[tmpCur2] = (prev[tmpCur2] || 0) + 1;
            return prev;
        }, {});

        /* Search Start */
        const searchType = this.state.filterOptions.type;
        const groupby = this.state.optionFilterBy;

        var groupByHtmlData = [];
        this.state.groupByHtmlData = [];
        var visitorGroupData = [];
        var countryC = [];
        var fildname = "";
        var tempData = [];

        if (searchType === "text") {
            let textToSearch = this.state.filterOptions.searchText;
            textToSearch = textToSearch.replace(/\\/g, "/");

            filteredVisitors = normalVisitors.filter(data => {
                if (new RegExp(textToSearch, "gi").test(data["geoDetails"]["country_name"])) {
                    return true;
                }
                if (new RegExp(textToSearch, "gi").test(data.current_pageUrl)) {
                    return true;
                }
                if (new RegExp(textToSearch, "gi").test(data["country"])) {
                    return true;
                }
                if (new RegExp(textToSearch, "gi").test(data["deviceUsed"])) {
                    return true;
                }
                return false;
            });

            /* For Premium Visitors Start */

            filteredPreVisitors = premiumVisitors.filter(data => {
                if (new RegExp(textToSearch, "gi").test(data["geoDetails"]["country_name"])) {
                    return true;
                }
                if (new RegExp(textToSearch, "gi").test(data.current_pageUrl)) {
                    return true;
                }
                if (new RegExp(textToSearch, "gi").test(data["country"])) {
                    return true;
                }
                if (new RegExp(textToSearch, "gi").test(data["deviceUsed"])) {
                    return true;
                }
                return false;
            });

            /* For Premium Visitors End */

            if (groupby !== "none") {
                //var groupByHtmlData = [];
                //this.state.groupByHtmlData = [];
                //var visitorGroupData = [];
                //var countryC = [];
                //var fildname = "";

                /* For Premium Visitors Start */
                filteredPreVisitors.forEach((data) => {
                    if (groupby === "country") {
                        //fildname = data.country;
                        fildname = data.geoDetails.country_name;
                    } else if (groupby === "deviceUsed") {
                        fildname = data.deviceUsed ? data.deviceUsed : 'Others';
                    } else {
                        //fildname = data.PageUrl_a;
                        fildname = data.current_pageUrl;
                    }
                    if (countryC[fildname]) {
                        visitorGroupData[fildname].push(data);
                        countryC[fildname] += 1;
                    } else {
                        visitorGroupData[fildname] = [];
                        visitorGroupData[fildname].push(data);
                        countryC[fildname] = 1;
                    }
                });
                /* For Premium Visitors End */

                filteredVisitors.forEach((data) => {
                    if (groupby === "country") {
                        //fildname = data.country;
                        fildname = data.geoDetails.country_name;
                    } else if (groupby === "deviceUsed") {
                        fildname = data.deviceUsed ? data.deviceUsed : 'Others';
                    } else {
                        //fildname = data.PageUrl_a;
                        fildname = data.current_pageUrl;
                    }
                    if (countryC[fildname]) {
                        visitorGroupData[fildname].push(data);
                        countryC[fildname] += 1;
                    } else {
                        visitorGroupData[fildname] = [];
                        visitorGroupData[fildname].push(data);
                        countryC[fildname] = 1;
                    }
                });
                //var countryName = "";
                for (const key in visitorGroupData) {
                    tempData = [];
                    visitorGroupData[key].forEach((visitor, index) => {
                        if (index === 0) {
                            //countryName = visitor.country;
                        }
                        tempData.push(
                            <li key={key + index} onClick={() => this.centerClientLoading(visitor)} className="px-4">
                                <div className="listing-right-panel-information py-4">
                                    <div className="k-flex align-items-center">

                                        {visitor.client_company_name && visitor.client_company_name !== 'Appy Pie' ? (<h6 className="visitore_heding_size text-heading-screen1 mb-0 mr-auto">
                                            <span className="premium pakage-icon">&nbsp;</span>
                                            {visitor.client_company_name}
                                        </h6>) : (<h6 className="visitore_heding_size text-heading-screen1 mb-0 mr-auto">
                                            {visitor.client_company_name === 'Appy Pie' ? visitor.client_company_name : visitor.client_name}
                                        </h6>)}

                                        <ul className="maps-all pl-2">
                                            <li className={"flag-icon-" + visitor.country.toLowerCase().replace(/ /gi, "-") + " flag all-flas"}>
                                                <span className="titleShowHover"><h6><strong>Location:</strong> {(typeof (visitor.geoDetails.country_name) !== 'undefined') ? visitor.geoDetails.country_name : ""}</h6> <h6><strong>IP:</strong> {(typeof visitor.geoDetails.country_ip !== 'undefined') ? visitor.geoDetails.country_ip : ""}</h6></span>
                                            </li>
                                            <li className={visitor.operating.toLowerCase().replace(" ", "-") + " os all-flas"}>
                                                <span className="titleShowHover"><h6><strong>OS:</strong> {visitor.operating} </h6></span>
                                            </li>
                                            <li className={visitor.browser.toLowerCase().replace(" ", "-") + " browser all-flas"}>
                                                <span className="titleShowHover"><h6><strong>Browser:</strong> {visitor.browser} </h6></span>
                                            </li>
                                        </ul>
                                    </div>
                                    <p title={visitor.current_pageUrl} className="small mb-0 text-screen1 name-chat-header">{visitor.current_pageUrl}</p>
                                </div>
                            </li>
                        );
                    });
                    groupByHtmlData.push(<div key={key} className="visitore-countrey bg-white mt-4">
                        <div onClick={this.toggle.bind(this, key)} className="country-names px-4 py-3 k-flex align-items-center justify-content-center">
                            <h6 className="mr-auto name-chat-header">{groupby === "country" ? key + " (" + countryC[key] + ")" : key}</h6>
                            {(this.state.open.indexOf(key) === -1) ? (<img alt="" src={require('../../../../assets/images/livechat/down.svg')} />) : (<img alt="" src={require('../../../../assets/images/livechat/left.svg')} />)}
                        </div>
                        <ul className={"list-group list-group-flush list-none scrollbar fix-hight collapse" + ((this.state.open.indexOf(key) === -1) ? ' in' : '')}>
                            {tempData}
                        </ul></div>);
                    //countryName = "";
                }
                groupByHtmlData.sort(function (a, b) {
                    return a.key === b.key ? 0 : a.key < b.key ? -1 : 1;
                });
                this.setState({ groupByHtmlData });
            }
        } else if (searchType === "filters") {

            const countries = this.state.filterOptions.countries;
            const pageUrls = this.state.filterOptions.pageUrls;
            const devicesUsed = this.state.filterOptions.devicesUsed;

            if (countries.length > 0 && pageUrls.length > 0 && devicesUsed.length > 0) { // all 3 conditions true

                /* For Premium Visitors Start */
                filteredPreVisitors = premiumVisitors.filter(data => {
                    let checkCntryExist = false;
                    let checkUrlExist = false;
                    let checkDeviceExist = false;
                    if (countries.length > 0) {
                        checkCntryExist = countries.indexOf(data["geoDetails"]["country_name"]) !== -1 ? true : false;
                    }
                    if (pageUrls.length > 0) {
                        checkUrlExist = pageUrls.indexOf(data["current_pageUrl"]) !== -1 ? true : false;
                    }
                    if (devicesUsed.length > 0) {
                        checkDeviceExist = devicesUsed.indexOf(data["deviceUsed"]) !== -1 ? true : false;
                    }
                    return (checkCntryExist && checkUrlExist && checkDeviceExist) ? true : false;
                });
                /* For Premium Visitors End */

                filteredVisitors = normalVisitors.filter(data => {
                    let checkCntryExist = false;
                    let checkUrlExist = false;
                    let checkDeviceExist = false;
                    if (countries.length > 0) {
                        checkCntryExist = countries.indexOf(data["geoDetails"]["country_name"]) !== -1 ? true : false;
                    }
                    if (pageUrls.length > 0) {
                        checkUrlExist = pageUrls.indexOf(data["current_pageUrl"]) !== -1 ? true : false;
                    }
                    if (devicesUsed.length > 0) {
                        checkDeviceExist = devicesUsed.indexOf(data["deviceUsed"]) !== -1 ? true : false;
                    }
                    return (checkCntryExist && checkUrlExist && checkDeviceExist) ? true : false;
                });
            } else if (countries.length > 0 && pageUrls.length > 0) { // 2 conditions true

                /* For Premium Visitors Start */
                filteredPreVisitors = premiumVisitors.filter(data => {
                    let checkCntryExist = false;
                    let checkUrlExist = false;
                    if (countries.length > 0) {
                        checkCntryExist = countries.indexOf(data["geoDetails"]["country_name"]) !== -1 ? true : false;
                    }
                    if (pageUrls.length > 0) {
                        checkUrlExist = pageUrls.indexOf(data["current_pageUrl"]) !== -1 ? true : false;
                    }
                    return (checkCntryExist && checkUrlExist) ? true : false;
                });
                /* For Premium Visitors End */

                filteredVisitors = normalVisitors.filter(data => {
                    let checkCntryExist = false;
                    let checkUrlExist = false;
                    if (countries.length > 0) {
                        checkCntryExist = countries.indexOf(data["geoDetails"]["country_name"]) !== -1 ? true : false;
                    }
                    if (pageUrls.length > 0) {
                        checkUrlExist = pageUrls.indexOf(data["current_pageUrl"]) !== -1 ? true : false;
                    }
                    return (checkCntryExist && checkUrlExist) ? true : false;
                });
            } else if (countries.length > 0 && devicesUsed.length > 0) { // 2 conditions true

                /* For Premium Visitors Start */
                filteredPreVisitors = premiumVisitors.filter(data => {
                    let checkCntryExist = false;
                    let checkDeviceExist = false;
                    if (countries.length > 0) {
                        checkCntryExist = countries.indexOf(data["geoDetails"]["country_name"]) !== -1 ? true : false;
                    }
                    if (devicesUsed.length > 0) {
                        checkDeviceExist = devicesUsed.indexOf(data["deviceUsed"]) !== -1 ? true : false;
                    }
                    return (checkCntryExist && checkDeviceExist) ? true : false;
                });
                /* For Premium Visitors End */

                filteredVisitors = normalVisitors.filter(data => {
                    let checkCntryExist = false;
                    let checkDeviceExist = false;
                    if (countries.length > 0) {
                        checkCntryExist = countries.indexOf(data["geoDetails"]["country_name"]) !== -1 ? true : false;
                    }
                    if (devicesUsed.length > 0) {
                        checkDeviceExist = devicesUsed.indexOf(data["deviceUsed"]) !== -1 ? true : false;
                    }
                    return (checkCntryExist && checkDeviceExist) ? true : false;
                });
            } else if (pageUrls.length > 0 && devicesUsed.length > 0) { // 2 conditions true

                /* For Premium Visitors Start */
                filteredPreVisitors = premiumVisitors.filter(data => {
                    let checkUrlExist = false;
                    let checkDeviceExist = false;
                    if (pageUrls.length > 0) {
                        checkUrlExist = pageUrls.indexOf(data["current_pageUrl"]) !== -1 ? true : false;
                    }
                    if (devicesUsed.length > 0) {
                        checkDeviceExist = devicesUsed.indexOf(data["deviceUsed"]) !== -1 ? true : false;
                    }
                    return (checkUrlExist && checkDeviceExist) ? true : false;
                });
                /* For Premium Visitors End */

                filteredVisitors = normalVisitors.filter(data => {
                    let checkUrlExist = false;
                    let checkDeviceExist = false;
                    if (pageUrls.length > 0) {
                        checkUrlExist = pageUrls.indexOf(data["current_pageUrl"]) !== -1 ? true : false;
                    }
                    if (devicesUsed.length > 0) {
                        checkDeviceExist = devicesUsed.indexOf(data["deviceUsed"]) !== -1 ? true : false;
                    }
                    return (checkUrlExist && checkDeviceExist) ? true : false;
                });
            } else if (countries.length > 0) {
                /* For Premium Visitors Start */
                filteredPreVisitors = premiumVisitors.filter(data => {
                    if (countries.length > 0) {
                        return countries.indexOf(data["geoDetails"]["country_name"]) !== -1 ? true : false;
                    }
                    else {
                        return false;
                    }
                });
                /* For Premium Visitors End */
                filteredVisitors = normalVisitors.filter(data => {
                    if (countries.length > 0) {
                        return countries.indexOf(data["geoDetails"]["country_name"]) !== -1 ? true : false;
                    }
                    else {
                        return false;
                    }
                });
            } else if (pageUrls.length > 0) {
                /* For Premium Visitors Start */
                filteredPreVisitors = premiumVisitors.filter(data => {
                    if (pageUrls.length > 0) {
                        return pageUrls.indexOf(data["current_pageUrl"]) !== -1 ? true : false;
                    }
                    else {
                        return false;
                    }
                });
                /* For Premium Visitors End */
                filteredVisitors = normalVisitors.filter(data => {
                    if (pageUrls.length > 0) {
                        return pageUrls.indexOf(data["current_pageUrl"]) !== -1 ? true : false;
                    }
                    else {
                        return false;
                    }
                });
            } else if (devicesUsed.length > 0) {
                /* For Premium Visitors Start */
                filteredPreVisitors = premiumVisitors.filter(data => {
                    if (devicesUsed.length > 0) {
                        return devicesUsed.indexOf(data["deviceUsed"]) !== -1 ? true : false;
                    }
                });
                /* For Premium Visitors End */
                filteredVisitors = normalVisitors.filter(data => {
                    if (devicesUsed.length > 0) {
                        return devicesUsed.indexOf(data["deviceUsed"]) !== -1 ? true : false;
                    }
                });
            } else {
                filteredVisitors = normalVisitors;
                filteredPreVisitors = premiumVisitors;
            }

            if (groupby !== "none") {
                //var groupByHtmlData = [];
                //this.state.groupByHtmlData = [];
                //var visitorGroupData = [];
                //var countryC = [];
                //var fildname = "";

                /* For Premium Visitors Start */
                filteredPreVisitors.forEach((data) => {
                    if (groupby === "country") {
                        fildname = data.geoDetails.country_name;
                    } else if (groupby === "deviceUsed") {
                        fildname = data.deviceUsed ? data.deviceUsed : 'Others';
                    } else {
                        fildname = data.current_pageUrl;
                    }
                    if (countryC[fildname]) {
                        visitorGroupData[fildname].push(data);
                        countryC[fildname] += 1;
                    } else {
                        visitorGroupData[fildname] = [];
                        visitorGroupData[fildname].push(data);
                        countryC[fildname] = 1;
                    }
                });
                /* For Premium Visitors End */

                filteredVisitors.forEach((data) => {
                    if (groupby === "country") {
                        //fildname = data.country;
                        fildname = data.geoDetails.country_name;
                    } else if (groupby === "deviceUsed") {
                        fildname = data.deviceUsed ? data.deviceUsed : 'Others';
                    } else {
                        //fildname = data.PageUrl_a;
                        fildname = data.current_pageUrl;
                    }
                    if (countryC[fildname]) {
                        visitorGroupData[fildname].push(data);
                        countryC[fildname] += 1;
                    } else {
                        visitorGroupData[fildname] = [];
                        visitorGroupData[fildname].push(data);
                        countryC[fildname] = 1;
                    }
                });
                //var countryName = "";
                for (const key in visitorGroupData) {
                    tempData = [];
                    visitorGroupData[key].forEach((visitor, index) => {
                        if (index === 0) {
                            //countryName = visitor.country;
                        }
                        tempData.push(
                            <li key={key + index} onClick={() => this.centerClientLoading(visitor)} className="px-4">
                                <div className="listing-right-panel-information py-4">
                                    <div className="k-flex align-items-center">
                                        {visitor.client_company_name && visitor.client_company_name !== 'Appy Pie' ? (<h6 className="visitore_heding_size text-heading-screen1 mb-0 mr-auto">
                                            <span className="premium pakage-icon">&nbsp;</span>
                                            {visitor.client_company_name}
                                        </h6>) : (<h6 className="visitore_heding_size text-heading-screen1 mb-0 mr-auto">
                                            {visitor.client_company_name === 'Appy Pie' ? visitor.client_company_name : visitor.client_name}
                                        </h6>)}
                                        <ul className="maps-all pl-2">
                                            <li className={"flag-icon-" + visitor.country.toLowerCase().replace(/ /gi, "-") + " flag all-flas"}>
                                                <span className="titleShowHover"><h6><strong>Location:</strong> {(typeof (visitor.geoDetails.country_name) !== 'undefined') ? visitor.geoDetails.country_name : ""}</h6> <h6><strong>IP:</strong> {(typeof visitor.geoDetails.country_ip !== 'undefined') ? visitor.geoDetails.country_ip : ""}</h6></span>
                                            </li>
                                            <li className={visitor.operating.toLowerCase().replace(" ", "-") + " os all-flas"}>
                                                <span className="titleShowHover"><h6><strong>OS:</strong> {visitor.operating} </h6></span>
                                            </li>
                                            <li className={visitor.browser.toLowerCase().replace(" ", "-") + " browser all-flas"}>
                                                <span className="titleShowHover"><h6><strong>Browser:</strong> {visitor.browser} </h6></span>
                                            </li>
                                        </ul>
                                    </div>
                                    <p title={visitor.current_pageUrl} className="small mb-0 text-screen1 name-chat-header">{visitor.current_pageUrl}</p>
                                </div>
                            </li>
                        );
                    });
                    groupByHtmlData.push(<div key={key} className="visitore-countrey bg-white mt-4">
                        <div onClick={this.toggle.bind(this, key)} className="country-names px-4 py-3 k-flex align-items-center justify-content-center">
                            <h6 className="mr-auto name-chat-header">{groupby === "country" ? key + " (" + countryC[key] + ")" : key}</h6>
                            {(this.state.open.indexOf(key) === -1) ? (<img alt="" src={require('../../../../assets/images/livechat/down.svg')} />) : (<img alt="" src={require('../../../../assets/images/livechat/left.svg')} />)}
                        </div>
                        <ul className={"list-group list-group-flush list-none scrollbar fix-hight collapse" + ((this.state.open.indexOf(key) === -1) ? ' in' : '')}>
                            {tempData}
                        </ul></div>);
                    //countryName = "";
                }
                groupByHtmlData.sort(function (a, b) {
                    return a.key === b.key ? 0 : a.key < b.key ? -1 : 1;
                });
                this.setState({ groupByHtmlData });
            }

        } else if (groupby !== "none") {

            //var groupByHtmlData = [];
            //this.state.groupByHtmlData = [];
            //var visitorGroupData = [];
            //var countryC = [];
            //var fildname = "";

            /* For Premium Visitors Start */

            premiumVisitors.forEach((data) => {
                if (groupby === "country") {
                    fildname = data.geoDetails.country_name;
                } else if (groupby === "deviceUsed") {
                    fildname = data.deviceUsed ? data.deviceUsed : 'Others';
                } else {
                    fildname = data.current_pageUrl;
                }
                if (countryC[fildname]) {
                    visitorGroupData[fildname].push(data);
                    countryC[fildname] += 1;
                } else {
                    visitorGroupData[fildname] = [];
                    visitorGroupData[fildname].push(data);
                    countryC[fildname] = 1;
                }
            });

            /* For Premium Visitors End */

            normalVisitors.forEach((data) => {
                if (groupby === "country") {
                    //fildname = data.country;
                    fildname = data.geoDetails.country_name;
                } else if (groupby === "deviceUsed") {
                    fildname = data.deviceUsed ? data.deviceUsed : 'Others';
                } else {
                    //fildname = data.PageUrl_a;
                    fildname = data.current_pageUrl;
                }
                if (countryC[fildname]) {
                    visitorGroupData[fildname].push(data);
                    countryC[fildname] += 1;
                } else {
                    visitorGroupData[fildname] = [];
                    visitorGroupData[fildname].push(data);
                    countryC[fildname] = 1;
                }
            });
            // var countryName = "";
            for (const key in visitorGroupData) {
                tempData = [];
                visitorGroupData[key].forEach((visitor, index) => {
                    if (index === 0) {
                        // countryName = visitor.country;
                    }
                    tempData.push(
                        <li key={key + index} onClick={() => this.centerClientLoading(visitor)} className="px-4">
                            <div className="listing-right-panel-information py-4">
                                <div className="k-flex align-items-center">
                                    {visitor.client_company_name && visitor.client_company_name !== 'Appy Pie' ? (<h6 className="visitore_heding_size text-heading-screen1 mb-0 mr-auto">
                                        <span className="premium pakage-icon">&nbsp;</span>
                                        {visitor.client_company_name}
                                    </h6>) : (<h6 className="visitore_heding_size text-heading-screen1 mb-0 mr-auto">
                                        {visitor.client_company_name === 'Appy Pie' ? visitor.client_company_name : visitor.client_name}
                                    </h6>)}
                                    <ul className="maps-all pl-2">
                                        <li className={"flag-icon-" + visitor.country.toLowerCase().replace(/ /gi, "-") + " flag all-flas"}>
                                            <span className="titleShowHover"><h6><strong>Location:</strong> {(typeof (visitor.geoDetails.country_name) !== 'undefined') ? visitor.geoDetails.country_name : ""}</h6> <h6><strong>IP:</strong> {(typeof visitor.geoDetails.country_ip !== 'undefined') ? visitor.geoDetails.country_ip : ""}</h6></span>
                                        </li>
                                        <li className={visitor.operating.toLowerCase().replace(" ", "-") + " os all-flas"}>
                                            <span className="titleShowHover"><h6><strong>OS:</strong> {visitor.operating} </h6></span>
                                        </li>
                                        <li className={visitor.browser.toLowerCase().replace(" ", "-") + " browser all-flas"}>
                                            <span className="titleShowHover"><h6><strong>Browser:</strong> {visitor.browser} </h6></span>
                                        </li>
                                    </ul>
                                </div>
                                <p title={visitor.current_pageUrl} className="small mb-0 text-screen1 name-chat-header">{visitor.current_pageUrl}</p>
                            </div>
                        </li>
                    );
                });
                groupByHtmlData.push(<div key={key} className="visitore-countrey bg-white mt-4">
                    <div onClick={this.toggle.bind(this, key)} className="country-names px-4 py-3 k-flex align-items-center justify-content-center cursor-pointer">
                        <h6 className="mr-auto name-chat-header">{groupby === "country" ? key + " (" + countryC[key] + ")" : key}</h6>
                        {(this.state.open.indexOf(key) === -1) ? (<img alt="" src={require('../../../../assets/images/livechat/down.svg')} />) : (<img alt="" src={require('../../../../assets/images/livechat/left.svg')} />)}
                    </div>
                    <ul className={"list-group list-group-flush list-none scrollbar fix-hight collapse" + ((this.state.open.indexOf(key) === -1) ? ' in' : '')}>
                        {tempData}
                    </ul></div>);
                // countryName = "";
            }
            groupByHtmlData.sort(function (a, b) {
                return a.key === b.key ? 0 : a.key < b.key ? -1 : 1;
            });
            this.setState({ groupByHtmlData });
            filteredVisitors = normalVisitors;
            filteredPreVisitors = premiumVisitors;
        } else {
            // filteredVisitors = allVisitors;
            filteredVisitors = normalVisitors;
            filteredPreVisitors = premiumVisitors;
        }

        if (searchType === "text" || searchType === "filters") {
            var totalPage = Math.ceil((filteredVisitors).length / this.state.pagesize);
            var totalPrePage = Math.ceil((filteredPreVisitors).length / this.state.pagesize);
            this.setState({ filteredVisitors: filteredVisitors, filteredPreVisitors: filteredPreVisitors, currentPrePage: 0, totalPrePage: totalPrePage, currentPage: 0, totalPage: totalPage, countryWithTotalUser: countriesMap, pageUrlWithTotalUser: pageUrlsMap, deviceUsedWithTotalUser: devicesUsedMap }, () => {
                this.loadMoreVisitors(false);
                this.loadMorePreVisitors(false);
            });
        } else {
            this.setState({ filteredVisitors: filteredVisitors, filteredPreVisitors: filteredPreVisitors, countryWithTotalUser: countriesMap, pageUrlWithTotalUser: pageUrlsMap, deviceUsedWithTotalUser: devicesUsedMap }, () => {
                this.loadMoreVisitors(false);
                this.loadMorePreVisitors(false);
            });
        }
        /* Search End */
    }

    setActiveClass = (LiId, remClsId) => {
        document.getElementById(remClsId[0]).classList.remove("active");
        document.getElementById(remClsId[1]).classList.remove("active");
        document.getElementById(LiId).classList.add("active");

        if (LiId === "countryLi") {
            this.setState({ filterOptionSelected: "country" });
        } else if (LiId === "pageurlLi") {
            this.setState({ filterOptionSelected: "pageurl" });
        } else {
            this.setState({ filterOptionSelected: "deviceUsed" });
        }
    }

    setFilterData = () => {
        const countArr = [];
        const urlArr = [];
        const deviceUsedArray = [];
        var ele = document.getElementsByName('filter_by_url');
        var ele2 = document.getElementsByName('filter_by_country');
        var ele3 = document.getElementsByName('filter_by_deviceUsed');
        for (let i = 0; i < ele.length; i++) {
            if (ele[i].checked) {
                urlArr.push(ele[i].value);
            }
        }
        for (let i = 0; i < ele2.length; i++) {
            if (ele2[i].checked) {
                countArr.push(ele2[i].value);
            }
        }
        for (let i = 0; i < ele3.length; i++) {
            if (ele3[i].checked) {
                deviceUsedArray.push(ele3[i].value);
            }
        }

        let tmpFilterOptions = this.state.filterOptions;
        const serverTimestamp = Date.now();
        tmpFilterOptions.countries = countArr;
        tmpFilterOptions.pageUrls = urlArr;
        tmpFilterOptions.devicesUsed = deviceUsedArray;
        tmpFilterOptions.total = serverTimestamp;
        tmpFilterOptions.type = "filters";
        try {
            insertOrUpdateFilters(tmpFilterOptions);
        } catch (errr) { }
        this.setState({ callRender: Date.now(), filterOptions: tmpFilterOptions }, () => {
            this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
            this.mapCountryUrlsDevicesList();
        });
        /* this.setState({ filterOptions: { type: "filters", groupBy: "", searchText: "", countries: countArr, pageUrls: urlArr, total: serverTimestamp } }, () => {
            this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
            this.mapCountryUrlsDevicesList();
        }); */
    }

    resetFilterData = () => {
        var ele = document.getElementsByName('filter_by_url');
        var ele2 = document.getElementsByName('filter_by_country');
        var ele3 = document.getElementsByName('filter_by_deviceUsed');
        for (let i = 0; i < ele.length; i++) {
            if (ele[i].checked) {
                ele[i].checked = false;
            }
        }
        for (let i = 0; i < ele2.length; i++) {
            if (ele2[i].checked) {
                ele2[i].checked = false;
            }
        }
        for (let i = 0; i < ele3.length; i++) {
            if (ele3[i].checked) {
                ele3[i].checked = false;
            }
        }

        try {
            insertOrUpdateFilters({ "type": "", "groupBy": "", "searchText": "", "countries": [], "pageUrls": [], "devicesUsed": [], "total": 0 });
        } catch (errr) { }

        this.setState({ filterOptions: { "type": "", "groupBy": "", "searchText": "", "countries": [], "pageUrls": [], "devicesUsed": [], "total": 0 } }, () => {
            this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
            this.mapCountryUrlsDevicesList();
        });
    }

    ////// For removing left visitors start ///////

    /*checkIsLeftVisitors = async (visitors) => {
        var serverTimestamp = await getDateInUTC();
        for(const index in visitors.docs) {
            if(index > 0) {
                serverTimestamp += (index * 300);
            }
            const visitor = visitors.docs[index];
            let vist = visitor.data();
            const chatId = visitor.id;
            const status = vist.status;
            const visitor_status = vist.visitor_status;
            const agent_end = vist.agent_end;
            const last_seen = vist.visitor_last_seen;
            if (visitor_status === "new" && status === 1 && agent_end === 'NEW_CHAT') {
                const last_seen_then = serverTimestamp - last_seen;
                if (last_seen_then >= TIME_TO_HIDE_VISITOR) {
                    firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatId).update({incoming:10, updatedon: serverTimestamp, client_status : "offline" }).then((res) => {
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatId).update({ visitor_status: "left", message_to_show: 'Visitor left the site.' });
                    });
                }
            }
        }
    }

    checkIsLeftVisitorsInSnapshot = async (visitors) => {
        var serverTimestamp = await getDateInUTC();
        for(const index in visitors.docs) {
            if(index > 0) {
                serverTimestamp += (index * 300);
            }
            const visitor = visitors.docs[index];
            let vist = visitor.data();
            const chatId = visitor.id;
            const status = vist.status;
            const visitor_status = vist.visitor_status;
            const agent_end = vist.agent_end;
            const last_seen = vist.visitor_last_seen;
            if (visitor_status === "new" && status === 1 && agent_end === 'NEW_CHAT') {
                const last_seen_then = serverTimestamp - last_seen;
                if (last_seen_then >= TIME_TO_HIDE_VISITOR) {
                    firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatId).update({incoming:10, updatedon: serverTimestamp, client_status : "offline" }).then((res) => {
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatId).update({ visitor_status: "left", message_to_show: 'Visitor left the site.' });
                    });
                }
            }
        }
        setTimeout(() => {
            this.setState({shouldCheckLeftVisitor : true });
        }, 2000);
    } */

    ////// For removing left visitors End ////////

    reloadVisitors = () => {
        this.setState({ refreshVisitors: true });
        this.updateVisitors();
        return
        this.setState({ refreshVisitors: true });
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').where("visitor_status", "==", NEW_VISITOR_STATUS).orderBy('addedon', 'desc').get().then(async (visitors) => {
            this.visitorData = [];
            this.countriesArray = [];
            this.pageUrlArray = [];
            this.deviceUsedArray = [];
            for (const index in visitors.docs) {
                const visitor = visitors.docs[index];
                var vist = visitor.data();
                if (vist.incoming === 0) {
                    vist.id = visitor.id;
                    vist.client_short = nameIntials(vist.client_name);
                    var client_short_name = vist.client_name;
                    if (client_short_name.length > 20) {
                        client_short_name = client_short_name.substring(0, 20) + '...';
                    }
                    vist.client_short_name = client_short_name;
                    vist.timevalue = getTimeFromDate(vist.updatedon);

                    const premiumuser = vist.client_company_name && vist.client_company_name !== 'Appy Pie' ? vist.client_company_name : "";
                    if (premiumuser) {
                        //vist.addedon = eval(vist.addedon) + 259200 * 1000;
                        vist.isPremiumNormal = "premium";
                    } else {
                        vist.isPremiumNormal = "normal";
                    }
                    /* for sorting get all countries and url Start */

                    this.countriesArray.push(vist.geoDetails.country_name + "-$$$-" + vist.id);
                    if (vist.current_pageUrl) {
                        this.pageUrlArray.push(vist.current_pageUrl + "-$$$-" + vist.id);
                    } else {
                        this.pageUrlArray.push(vist.PageUrl_a[0] + "-$$$-" + vist.id);
                    }
                    if (!vist.deviceUsed) {
                        this.deviceUsedArray.push("Others-$$$-" + vist.id);
                    }
                    else {
                        this.deviceUsedArray.push(vist.deviceUsed + "-$$$-" + vist.id);
                    }

                    this.visitorData.push(vist);
                } else {
                    //console.log("ID : " + visitor.id + ", visitor_status : " + vist.visitor_status + ", incoming : " + vist.incoming + ", UpdatedOn : " + moment(vist.updatedon).format("YYYY-MM-DD HH:mm:ss"));
                    //firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(visitor.id).update({ visitor_status: "left", message_to_show: 'Visitor left the site.' }).then(() => { console.log("Complete - " + visitor.id) });
                }
            }

            this.setState({ visitors: this.visitorData, countries: this.countriesArray, pageUrls: this.pageUrlArray, devicesUsed: this.deviceUsedArray, refreshVisitors: false }, () => {
                this.mapCountryUrlsDevicesList();
            });

        });
    }
    updateVisitors = async () => {
        // console.log("updating_visitors")

        // this.newVisitorsSnapshot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').where("visitor_status", "==", NEW_VISITOR_STATUS).orderBy('updatedon', 'desc').limit(500).get().then(async (visitors) => {
            const currentTime = new Date();
            const fifteenMinutesAgo = currentTime.getTime() - (6* 60*  1000); // Subtract 15 minutes
            const firestoreTimestamp = firebaseServices.fb.firestore.Timestamp.fromDate(new Date(fifteenMinutesAgo));
    
            // Example Firestore query
            this.newVisitorsSnapshot = firebaseServices.db
              .collection('users')
              .doc(this.agent.ownerId)
              .collection('visitors')
              .where("visitor_status", "==", NEW_VISITOR_STATUS)
              .where("last_seen1", ">=", firestoreTimestamp)
              .orderBy('last_seen1', 'desc')
              .limit(250)
            .get().then(async (visitors) => {
            if(visitors.docs.length > 0 && this.agent && this.agent.agentId){
                log("Line 1808", "src/modules/livechat/view/rightSection/index.js", "updating_visitors", this.agent.agent_name+":"+this.agent.agentId,visitors.docs.length)
            }
            else{
                log("Line 1808", "src/modules/livechat/view/rightSection/index.js", "updating_visitors","updating_visitors")
            }
            //console.log("================== visitors.size =====================");
            // console.log("visitors.size");

            this.visitorData = [];
            this.countriesArray = [];
            this.pageUrlArray = [];
            this.deviceUsedArray = [];

            visitors.docChanges().forEach((visitor) => {
                // console.log("first_time",visitor.type,visitor.doc.id)
                var vistSnap = visitor.doc.data();
                vistSnap.id = visitor.doc.id;

                let last_seen1 = vistSnap.last_seen1?.seconds;
                if(last_seen1){
                    let away_time = this.online_time - last_seen1;
                    if(away_time > visitor_time_window){
                        return
                    }
                }else{
                    return
                }


                vistSnap.client_short = nameIntials(vistSnap.client_name);
                var client_short_name = vistSnap.client_name;
                if (client_short_name.length > 20) {
                    client_short_name = client_short_name.substring(0, 20) + '...';
                }
                vistSnap.client_short_name = client_short_name;
                vistSnap.timevalue = getTimeFromDate(vistSnap.updatedon);

                if (!vistSnap.country) {
                    vistSnap.country = "Country not detected";
                }

                if (!vistSnap.operating) {
                    vistSnap.operating = "OS Default";
                }

                if (!vistSnap.browser) {
                    vistSnap.browser = "Browser Default";
                }

                if (!vistSnap.deviceUsed) {
                    vistSnap.deviceUsed = "Others";
                }

                const premiumuser = vistSnap.client_company_name && vistSnap.client_company_name !== 'Appy Pie' ? vistSnap.client_company_name : "";
                if (premiumuser) {
                    //vistSnap.addedon = eval(vistSnap.addedon) + 259200 * 1000;
                    vistSnap.isPremiumNormal = "premium";
                } else {
                    vistSnap.isPremiumNormal = "normal";
                }

                if (visitor.type === "added") {
                    if (vistSnap.incoming === 0) {
                        const index = this.visitorData.findIndex(x => x.id === vistSnap.id);
                        if (index === -1) {
                            this.countriesArray.push(vistSnap.geoDetails.country_name + "-$$$-" + vistSnap.id);
                            if (vistSnap.current_pageUrl) {
                                this.pageUrlArray.push(vistSnap.current_pageUrl + "-$$$-" + vistSnap.id);
                            } else {
                                this.pageUrlArray.push(vistSnap.PageUrl_a[0] + "-$$$-" + vistSnap.id);
                            }
                            if (!vistSnap.deviceUsed) {
                                this.deviceUsedArray.push("Others-$$$-" + vistSnap.id);
                            }
                            else {
                                this.deviceUsedArray.push(vistSnap.deviceUsed + "-$$$-" + vistSnap.id);
                            }
                            this.visitorData.splice(0, 0, vistSnap); // Insert the new one 
                        } else {
                            this.visitorData.splice(index, 1, vistSnap); // replace value of an index
                        }
                    } else if (vistSnap.visitor_status === "left" || vistSnap.visitor_status === "incoming" || vistSnap.visitor_status === "ongoing" || vistSnap.incoming === 1 || vistSnap.incoming === 2 || vistSnap.incoming === 3 || vistSnap.incoming === 10) {
                        const index22 = this.countriesArray.findIndex(x => x === vistSnap.geoDetails.country_name + "-$$$-" + vistSnap.id);
                        if (index22 !== -1) {
                            this.countriesArray.splice(index22, 1);
                        }
                        if (vistSnap.current_pageUrl) {
                            const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.current_pageUrl + "-$$$-" + vistSnap.id);
                            if (index23 !== -1) {
                                this.pageUrlArray.splice(index23, 1);
                            }
                        } else {
                            const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.PageUrl_a[0] + "-$$$-" + vistSnap.id);
                            if (index23 !== -1) {
                                this.pageUrlArray.splice(index23, 1);
                            }
                        }
                        const index24 = this.deviceUsedArray.findIndex(x => x === vistSnap.deviceUsed + "-$$$-" + vistSnap.id);
                        if (index24 !== -1) {
                            this.deviceUsedArray.splice(index24, 1);
                        }
                        const index = this.visitorData.findIndex(x => x.id === vistSnap.id);
                        if (index !== -1) {
                            this.visitorData.splice(index, 1);
                        }
                    }
                } else if (visitor.type === "modified") {
                    if (vistSnap.incoming === 0) {
                        const index = this.visitorData.findIndex(x => x.id === vistSnap.id);
                        if (index !== -1) {
                            this.visitorData.splice(index, 1, vistSnap); // replace value of an index
                        } else {
                            this.countriesArray.push(vistSnap.geoDetails.country_name + "-$$$-" + vistSnap.id);
                            if (vistSnap.current_pageUrl) {
                                this.pageUrlArray.push(vistSnap.current_pageUrl + "-$$$-" + vistSnap.id);
                            } else {
                                this.pageUrlArray.push(vistSnap.PageUrl_a[0] + "-$$$-" + vistSnap.id);
                            }
                            if (!vistSnap.deviceUsed) {
                                this.deviceUsedArray.push("Others-$$$-" + vistSnap.id);
                            }
                            else {
                                this.deviceUsedArray.push(vistSnap.deviceUsed + "-$$$-" + vistSnap.id);
                            }
                            this.visitorData.splice(0, 0, vistSnap); // Insert the new one 
                        }
                    } else if (vistSnap.visitor_status === "left" || vistSnap.visitor_status === "incoming" || vistSnap.visitor_status === "ongoing" || vistSnap.incoming === 1 || vistSnap.incoming === 2 || vistSnap.incoming === 3 || vistSnap.incoming === 10) {
                        const index22 = this.countriesArray.findIndex(x => x === vistSnap.geoDetails.country_name + "-$$$-" + vistSnap.id);
                        if (index22 !== -1) {
                            this.countriesArray.splice(index22, 1);
                        }
                        if (vistSnap.current_pageUrl) {
                            const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.current_pageUrl + "-$$$-" + vistSnap.id);
                            if (index23 !== -1) {
                                this.pageUrlArray.splice(index23, 1);
                            }
                        } else {
                            const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.PageUrl_a[0] + "-$$$-" + vistSnap.id);
                            if (index23 !== -1) {
                                this.pageUrlArray.splice(index23, 1);
                            }
                        }
                        const index24 = this.deviceUsedArray.findIndex(x => x === vistSnap.deviceUsed + "-$$$-" + vistSnap.id);
                        if (index24 !== -1) {
                            this.deviceUsedArray.splice(index24, 1);
                        }
                        const index = this.visitorData.findIndex(x => x.id === vistSnap.id);
                        if (index !== -1) {
                            this.visitorData.splice(index, 1);
                        }
                    }
                } else if (visitor.type === "removed") {
                    if (vistSnap.visitor_status === "left" || vistSnap.visitor_status === "incoming" || vistSnap.visitor_status === "ongoing" || vistSnap.incoming === 1 || vistSnap.incoming === 2 || vistSnap.incoming === 3 || vistSnap.incoming === 10) {
                        const index22 = this.countriesArray.findIndex(x => x === vistSnap.geoDetails.country_name + "-$$$-" + vistSnap.id);
                        if (index22 !== -1) {
                            this.countriesArray.splice(index22, 1);
                        }
                        if (vistSnap.current_pageUrl) {
                            const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.current_pageUrl + "-$$$-" + vistSnap.id);
                            if (index23 !== -1) {
                                this.pageUrlArray.splice(index23, 1);
                            }
                        } else {
                            const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.PageUrl_a[0] + "-$$$-" + vistSnap.id);
                            if (index23 !== -1) {
                                this.pageUrlArray.splice(index23, 1);
                            }
                        }
                        const index24 = this.deviceUsedArray.findIndex(x => x === vistSnap.deviceUsed + "-$$$-" + vistSnap.id);
                        if (index24 !== -1) {
                            this.deviceUsedArray.splice(index24, 1);
                        }
                        const index = this.visitorData.findIndex(x => x.id === vistSnap.id);
                        if (index !== -1) {
                            this.visitorData.splice(index, 1);
                        }
                    } else {
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(vistSnap.id).get().then((doc) => {
                            if (doc.exists) {
                                var _data = doc.data();
                                if (_data.visitor_status === "left" || _data.visitor_status === "incoming" || _data.visitor_status === "ongoing" || _data.incoming === 1 || _data.incoming === 2 || _data.incoming === 3 || _data.incoming === 10) {
                                    const index = this.visitorData.findIndex(x => x.id === vistSnap.id);
                                    if (index !== -1) {
                                        const index22 = this.countriesArray.findIndex(x => x === vistSnap.geoDetails.country_name + "-$$$-" + vistSnap.id);
                                        if (index22 !== -1) {
                                            this.countriesArray.splice(index22, 1);
                                        }
                                        if (vistSnap.current_pageUrl) {
                                            const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.current_pageUrl + "-$$$-" + vistSnap.id);
                                            if (index23 !== -1) {
                                                this.pageUrlArray.splice(index23, 1);
                                            }
                                        } else {
                                            const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.PageUrl_a[0] + "-$$$-" + vistSnap.id);
                                            if (index23 !== -1) {
                                                this.pageUrlArray.splice(index23, 1);
                                            }
                                        }
                                        const index24 = this.deviceUsedArray.findIndex(x => x === vistSnap.deviceUsed + "-$$$-" + vistSnap.id);
                                        if (index24 !== -1) {
                                            this.deviceUsedArray.splice(index24, 1);
                                        }
                                        this.visitorData.splice(index, 1); // remove from array of an index
                                    }
                                    this.setState({ visitors: this.visitorData, countries: this.countriesArray, pageUrls: this.pageUrlArray, devicesUsed: this.deviceUsedArray }, () => {
                                        this.mapCountryUrlsDevicesList();
                                    });
                                }
                            }
                        });
                    }
                }
            });
            const exclude_visitors = [...hidden_visitors];
            hidden_visitors=[];
            const filteredVisitorData = this.visitorData.filter(data => {
                let last_seen = data.last_seen1?.seconds;
                let away_time = this.online_time - last_seen;
                let isHidden = exclude_visitors.includes(data.id);
                // console.log(isHidden,exclude_visitors,data.id,"=====>>>checkRemoval")


                return (this.context.ongoingChatIdes.indexOf(data.id) === -1) && away_time < visitor_time_window && !isHidden ? true : false;
            });

            this.setState({ visitors: filteredVisitorData, countries: this.countriesArray, pageUrls: this.pageUrlArray, devicesUsed: this.deviceUsedArray, refreshVisitors:false }, () => {
                this.mapCountryUrlsDevicesList();
            });
        });
    }
    hideTourPopup = (e) => {
        let ePath = e.path || (e.composedPath && e.composedPath());
        let hidePopup = true;
        if (ePath) {
            ePath.forEach((path) => {
                if (path.className && (path.className === 'tour_guide' || path.id === 'tour_guide')) {
                    hidePopup = false;
                }
            })
        }
        if (hidePopup === true && this.context.visitors_guide === false && this.context.visitors_guide_step < 4 && (this.state.displayPreVisitors.length > 0 || this.state.displayVisitors.length > 0)) {
            this.endGuideTour('visitor', 'visitors_guide');
        }
    }
    ////////// Private function End //////////

    componentWillMount = async () => {
        var body = document.getElementById('root');
        if (body) {
            body.addEventListener("click", this.hideTourPopup, false);
        }
        /* firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').where("visitor_status", "==", NEW_VISITOR_STATUS).orderBy('addedon', 'desc').get().then(async (visitors) => { */
        this.hideVisitorsList();
        this.visitorData = [];
        this.countriesArray = [];
        this.pageUrlArray = [];
        this.deviceUsedArray = [];

        // this.newVisitorsSnapshot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').where("visitor_status", "==", NEW_VISITOR_STATUS).orderBy('updatedon', 'desc').limit(1000).onSnapshot(async (visitors) => {

        //     //console.log("================== visitors.size =====================");
        //     // console.log("visitors.size");


        //     let r = await fetch('https://worldtimeapi.org/api/timezone/Asia/Kolkata')
        //     let data = await r.json();
        //     let online_time = data.unixtime;
        //     visitors.docChanges().forEach((visitor) => {
        //         var vistSnap = visitor.doc.data();
        //         vistSnap.id = visitor.doc.id;

        //         //console.log("--------------visitor----------");
        //         // console.log(visitor);

        //         //console.log("Type: "+ visitor.type + ", ID : " + vistSnap.id + ", incoming : " + vistSnap.incoming + ", visitor_status : " + vistSnap.visitor_status);


        //         //console.log("-------------------------Type: "+ visitor.type + ", ID : " + vistSnap.id + ", incoming : " + vistSnap.incoming + ", latest_msg : " + vistSnap.latest_msg+ ", last_chat : " + vistSnap.last_chat+ ", message_to_show : " + vistSnap.message_to_show+ ", updatedon : " + vistSnap.updatedon + ", visitor_status : " + vistSnap.visitor_status+ ", PageUrl_a : " + vistSnap.PageUrl_a+ "--------------------------");

        //         vistSnap.client_short = nameIntials(vistSnap.client_name);
        //         var client_short_name = vistSnap.client_name;
        //         if (client_short_name.length > 20) {
        //             client_short_name = client_short_name.substring(0, 20) + '...';
        //         }
        //         vistSnap.client_short_name = client_short_name;
        //         vistSnap.timevalue = getTimeFromDate(vistSnap.updatedon);

        //         if (!vistSnap.country) {
        //             vistSnap.country = "Country not detected";
        //         }

        //         if (!vistSnap.operating) {
        //             vistSnap.operating = "OS Default";
        //         }

        //         if (!vistSnap.browser) {
        //             vistSnap.browser = "Browser Default";
        //         }

        //         if (!vistSnap.deviceUsed) {
        //             vistSnap.deviceUsed = "Others";
        //         }

        //         const premiumuser = vistSnap.client_company_name && vistSnap.client_company_name !== 'Appy Pie' ? vistSnap.client_company_name : "";
        //         if (premiumuser) {
        //             //vistSnap.addedon = eval(vistSnap.addedon) + 259200 * 1000;
        //             vistSnap.isPremiumNormal = "premium";
        //         } else {
        //             vistSnap.isPremiumNormal = "normal";
        //         }

        //         if (visitor.type === "added") {
        //             if (vistSnap.incoming === 0) {
        //                 const index = this.visitorData.findIndex(x => x.id === vistSnap.id);
        //                 if (index === -1) {
        //                     this.countriesArray.push(vistSnap.geoDetails.country_name + "-$$$-" + vistSnap.id);
        //                     if (vistSnap.current_pageUrl) {
        //                         this.pageUrlArray.push(vistSnap.current_pageUrl + "-$$$-" + vistSnap.id);
        //                     } else {
        //                         this.pageUrlArray.push(vistSnap.PageUrl_a[0] + "-$$$-" + vistSnap.id);
        //                     }
        //                     if (!vistSnap.deviceUsed) {
        //                         this.deviceUsedArray.push("Others-$$$-" + vistSnap.id);
        //                     }
        //                     else {
        //                         this.deviceUsedArray.push(vistSnap.deviceUsed + "-$$$-" + vistSnap.id);
        //                     }
        //                     this.visitorData.splice(0, 0, vistSnap); // Insert the new one 
        //                 } else {
        //                     this.visitorData.splice(index, 1, vistSnap); // replace value of an index
        //                 }
        //             } else if (vistSnap.visitor_status === "left" || vistSnap.visitor_status === "incoming" || vistSnap.visitor_status === "ongoing" || vistSnap.incoming === 1 || vistSnap.incoming === 2 || vistSnap.incoming === 3 || vistSnap.incoming === 10) {
        //                 const index22 = this.countriesArray.findIndex(x => x === vistSnap.geoDetails.country_name + "-$$$-" + vistSnap.id);
        //                 if (index22 !== -1) {
        //                     this.countriesArray.splice(index22, 1);
        //                 }
        //                 if (vistSnap.current_pageUrl) {
        //                     const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.current_pageUrl + "-$$$-" + vistSnap.id);
        //                     if (index23 !== -1) {
        //                         this.pageUrlArray.splice(index23, 1);
        //                     }
        //                 } else {
        //                     const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.PageUrl_a[0] + "-$$$-" + vistSnap.id);
        //                     if (index23 !== -1) {
        //                         this.pageUrlArray.splice(index23, 1);
        //                     }
        //                 }
        //                 const index24 = this.deviceUsedArray.findIndex(x => x === vistSnap.deviceUsed + "-$$$-" + vistSnap.id);
        //                 if (index24 !== -1) {
        //                     this.deviceUsedArray.splice(index24, 1);
        //                 }
        //                 const index = this.visitorData.findIndex(x => x.id === vistSnap.id);
        //                 if (index !== -1) {
        //                     this.visitorData.splice(index, 1);
        //                 }
        //             }
        //         } else if (visitor.type === "modified") {
        //             if (vistSnap.incoming === 0) {
        //                 const index = this.visitorData.findIndex(x => x.id === vistSnap.id);
        //                 if (index !== -1) {
        //                     this.visitorData.splice(index, 1, vistSnap); // replace value of an index
        //                 } else {
        //                     this.countriesArray.push(vistSnap.geoDetails.country_name + "-$$$-" + vistSnap.id);
        //                     if (vistSnap.current_pageUrl) {
        //                         this.pageUrlArray.push(vistSnap.current_pageUrl + "-$$$-" + vistSnap.id);
        //                     } else {
        //                         this.pageUrlArray.push(vistSnap.PageUrl_a[0] + "-$$$-" + vistSnap.id);
        //                     }
        //                     if (!vistSnap.deviceUsed) {
        //                         this.deviceUsedArray.push("Others-$$$-" + vistSnap.id);
        //                     }
        //                     else {
        //                         this.deviceUsedArray.push(vistSnap.deviceUsed + "-$$$-" + vistSnap.id);
        //                     }
        //                     this.visitorData.splice(0, 0, vistSnap); // Insert the new one 
        //                 }
        //             } else if (vistSnap.visitor_status === "left" || vistSnap.visitor_status === "incoming" || vistSnap.visitor_status === "ongoing" || vistSnap.incoming === 1 || vistSnap.incoming === 2 || vistSnap.incoming === 3 || vistSnap.incoming === 10) {
        //                 const index22 = this.countriesArray.findIndex(x => x === vistSnap.geoDetails.country_name + "-$$$-" + vistSnap.id);
        //                 if (index22 !== -1) {
        //                     this.countriesArray.splice(index22, 1);
        //                 }
        //                 if (vistSnap.current_pageUrl) {
        //                     const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.current_pageUrl + "-$$$-" + vistSnap.id);
        //                     if (index23 !== -1) {
        //                         this.pageUrlArray.splice(index23, 1);
        //                     }
        //                 } else {
        //                     const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.PageUrl_a[0] + "-$$$-" + vistSnap.id);
        //                     if (index23 !== -1) {
        //                         this.pageUrlArray.splice(index23, 1);
        //                     }
        //                 }
        //                 const index24 = this.deviceUsedArray.findIndex(x => x === vistSnap.deviceUsed + "-$$$-" + vistSnap.id);
        //                 if (index24 !== -1) {
        //                     this.deviceUsedArray.splice(index24, 1);
        //                 }
        //                 const index = this.visitorData.findIndex(x => x.id === vistSnap.id);
        //                 if (index !== -1) {
        //                     this.visitorData.splice(index, 1);
        //                 }
        //             }
        //         } else if (visitor.type === "removed") {
        //             if (vistSnap.visitor_status === "left" || vistSnap.visitor_status === "incoming" || vistSnap.visitor_status === "ongoing" || vistSnap.incoming === 1 || vistSnap.incoming === 2 || vistSnap.incoming === 3 || vistSnap.incoming === 10) {
        //                 const index22 = this.countriesArray.findIndex(x => x === vistSnap.geoDetails.country_name + "-$$$-" + vistSnap.id);
        //                 if (index22 !== -1) {
        //                     this.countriesArray.splice(index22, 1);
        //                 }
        //                 if (vistSnap.current_pageUrl) {
        //                     const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.current_pageUrl + "-$$$-" + vistSnap.id);
        //                     if (index23 !== -1) {
        //                         this.pageUrlArray.splice(index23, 1);
        //                     }
        //                 } else {
        //                     const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.PageUrl_a[0] + "-$$$-" + vistSnap.id);
        //                     if (index23 !== -1) {
        //                         this.pageUrlArray.splice(index23, 1);
        //                     }
        //                 }
        //                 const index24 = this.deviceUsedArray.findIndex(x => x === vistSnap.deviceUsed + "-$$$-" + vistSnap.id);
        //                 if (index24 !== -1) {
        //                     this.deviceUsedArray.splice(index24, 1);
        //                 }
        //                 const index = this.visitorData.findIndex(x => x.id === vistSnap.id);
        //                 if (index !== -1) {
        //                     this.visitorData.splice(index, 1);
        //                 }
        //             } else {
        //                 firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(vistSnap.id).get().then((doc) => {
        //                     if (doc.exists) {
        //                         var _data = doc.data();
        //                         if (_data.visitor_status === "left" || _data.visitor_status === "incoming" || _data.visitor_status === "ongoing" || _data.incoming === 1 || _data.incoming === 2 || _data.incoming === 3 || _data.incoming === 10) {
        //                             const index = this.visitorData.findIndex(x => x.id === vistSnap.id);
        //                             if (index !== -1) {
        //                                 const index22 = this.countriesArray.findIndex(x => x === vistSnap.geoDetails.country_name + "-$$$-" + vistSnap.id);
        //                                 if (index22 !== -1) {
        //                                     this.countriesArray.splice(index22, 1);
        //                                 }
        //                                 if (vistSnap.current_pageUrl) {
        //                                     const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.current_pageUrl + "-$$$-" + vistSnap.id);
        //                                     if (index23 !== -1) {
        //                                         this.pageUrlArray.splice(index23, 1);
        //                                     }
        //                                 } else {
        //                                     const index23 = this.pageUrlArray.findIndex(x => x === vistSnap.PageUrl_a[0] + "-$$$-" + vistSnap.id);
        //                                     if (index23 !== -1) {
        //                                         this.pageUrlArray.splice(index23, 1);
        //                                     }
        //                                 }
        //                                 const index24 = this.deviceUsedArray.findIndex(x => x === vistSnap.deviceUsed + "-$$$-" + vistSnap.id);
        //                                 if (index24 !== -1) {
        //                                     this.deviceUsedArray.splice(index24, 1);
        //                                 }
        //                                 this.visitorData.splice(index, 1); // remove from array of an index
        //                             }
        //                             this.setState({ visitors: this.visitorData, countries: this.countriesArray, pageUrls: this.pageUrlArray, devicesUsed: this.deviceUsedArray }, () => {
        //                                 this.mapCountryUrlsDevicesList();
        //                             });
        //                         }
        //                     }
        //                 });
        //             }
        //         }
        //     });

        //     const filteredVisitorData = this.visitorData.filter(data => {
        //         return (this.context.ongoingChatIdes.indexOf(data.id) === -1) ? true : false;
        //     });

        //     this.setState({ visitors: filteredVisitorData, countries: this.countriesArray, pageUrls: this.pageUrlArray, devicesUsed: this.deviceUsedArray }, () => {
        //         this.mapCountryUrlsDevicesList();
        //     });
        // }, (error) => {
        //     try {
        //         this.reloadVisitors();
        //         var addedon = Date.now();
        //         var docId = 'SLOG' + addedon;
        //         firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('snapshotLogs').doc(docId).set({
        //             addedon: addedon,
        //             agent_id: this.agent.agentId,
        //             error_from: "visitor_panel",
        //             error: error,
        //         });
        //     } catch (err) { }
        // });

        /* }); */

        this.getAllOnlineAgents();
        // this.getOngoingVisitors();
        this.getWhatsAppTemplates();
    }

    removeVisitorFromList = (visitor_id) => {
        const objWithIdIndex = this.state.visitors.findIndex((obj) => obj.id === visitor_id);

        if (objWithIdIndex > -1) {
            this.state.visitors.splice(objWithIdIndex, 1);
            this.mapCountryUrlsDevicesList();
        }

    }


    componentDidMount = () => {
        /* For auto fill filter start */
        this.setFiltersIfSelected();
        /* For auto fill filter end */

        /*To get user_id from CommonLogin */
        this.getUserId();
        this.setdefaultValues();
        this.getAgentListFromCommonLoginWithRealm();
    }

    getAgentListFromCommonLoginWithRealm = async () => {
        debugger
        const companyIdentifier = this.agent.company_name;
        debugger
        const user = localStorage.getItem('alias');
        
        // Create the JSON object
        const data = {
            company_identifier: companyIdentifier,
            user: user
        };
        
        // Convert the JSON object to a string
        const jsonString = JSON.stringify(data);
        
        // Send the POST request
        fetch(commonLoginApi + "api/agentcustomer/getagentlist", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: jsonString
        })
        .then(response => response.json())
        .then(data => {
            debugger;
            return this.setState({ allAgentsRealm: data.userdata });
        })
        .catch((error) => {
            return this.setState({ allAgentsRealm: [] });
        });

    }

    
    getUserId = () => {
        let ID =getCookie('APPYID');
        fetch(commonLoginApi + "api/common/getuserdetailnew", { //kdapi
            method: "post",
            body: JSON.stringify({"file_name":ID}),
            headers: {
                "Authorization": "Bearer YXBwbGljYXRpb246c2VjcmV0",
                "Content-Type":"application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json())
            .then(response => {
                
                if (parseInt(response.status) === 200) {
                    let userId = '';
                    response.userdata.apps_access.forEach((apps) => {
                        if (apps.product === 'helpdesk') {
                            userId = apps.user_id;
                        }
                    })
                    this.setState({ deskUserId: userId })
                    return userId;
                }
            })
            .catch(error => console.log('error', error));
    }

    setdefaultValues = () => {
        firebaseServices.db.collection('users').doc(this.agent.ownerId).get().then(user => {
            if (user.exists) {
                if (user.data().ticketingEnabled !== undefined)
                    return user.data().ticketingSettings;
                else
                    return false
            }
            else {
                return false;
            }
        }).then((ticketingSettings) => {
            if (ticketingSettings === false || ticketingSettings === undefined) {
                this.setState({
                    subjectTicket: 'Conversation with Client',
                    descriptionTicket: '',
                    assignedTo: '',
                    assignedToName: '',
                    assignedToEntity: '',
                    assignedToStatus: '',
                    assignedToRoleType: '',
                    statusTicket: '',
                    priorityStatus: '',
                    isAutoTicketing: false
                })
                this.context.updateGlobalData("isAutoTicketing", false);
            }
            else if (ticketingSettings.isAutoTicketing === true) {
                this.setState({
                    subjectTicket: ticketingSettings.subjectTicket,
                    assignedTo: ticketingSettings.assignedTo,
                    assignedToName: ticketingSettings.assignedToName,
                    assignedToEntity: ticketingSettings.assignedToEntity,
                    assignedToStatus: ticketingSettings.assignedToStatus,
                    assignedToRoleType: ticketingSettings.assignedToRoleType,
                    statusTicket: ticketingSettings.statusTicket,
                    priorityStatus: ticketingSettings.priorityStatus,
                    descriptionTicket: ticketingSettings.descriptionTicket,
                    isAutoTicketing: ticketingSettings.isAutoTicketing
                })
                this.context.updateGlobalData("isAutoTicketing", ticketingSettings.isAutoTicketing);
            } else if (ticketingSettings.isAutoTicketing === false) {
                this.setState({
                    subjectTicket: 'Conversation with ',
                    descriptionTicket: '',
                    assignedTo: '',
                    assignedToName: '',
                    assignedToEntity: '',
                    assignedToStatus: '',
                    assignedToRoleType: '',
                    statusTicket: ticketingSettings.statusTicket,
                    priorityStatus: ticketingSettings.priorityStatus,
                    isAutoTicketing: ticketingSettings.isAutoTicketing
                })
                this.context.updateGlobalData("isAutoTicketing", ticketingSettings.isAutoTicketing);
            }
        }).catch((err) => { console.log("Error while getting old settings-->", err); this.setState({ loader: false }); })
    }

    getPreviousTicket = async () => {
        var email = this.context.currentVisitor.client_email;
        var maxPrevTicket = 5; // todo static
        let clientName = this.context.currentVisitor.client_name ? this.context.currentVisitor.client_name : this.context.currentVisitor.client_company_name;
        clientName = clientName ? clientName : '';
        let body = JSON.stringify({
            "count": maxPrevTicket,
            "email": email ? email : 'noreply@appypie.com',
            "user_id": localStorage.deskId,
            "name": clientName
        });
        fetch(DESK_API_URL + `/openTicketApi/api/getPreviousTicket`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Origin": window.location.origin
            },
            body: body
        }).then(res => res.json())
            .then((response) => {
                // console.log("\n\n\n💡🛠 -> file: index.js -> line 1919 -> response", response);
                if (response.success) {
                    this.setState({
                        statusIDs: response.results.status_id,
                        previousTickets: response.results.previous_tickets,
                        deskAgents: response.results.agents,
                        deskTeams: response.results.teams,
                        filteredDeskAgents: response.results.agents,
                        filteredDeskTeams: response.results.teams,
                        statusTicket: response.results.status_id[0].id,
                        deskOrgId: response.results.org_id,
                        deskContactId: response.results.contact_id,
                        subjectTicket: "Conversation with " + clientName,
                        clientName: clientName
                    } //, () => { let subjectDiv = document.getElementById('subjectTicket'); subjectDiv.value = "Conversation with " + clientName; }
                    );
                }
            }).catch((error) => {
                console.log(error);
            });

    }

    closeTicketing = () => {
        if (this.context.rightSectionItemToShow === "ticketing") {
            this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
        }
        this.chatText = '';
        this.chatOrgArray = '';
        this.setdefaultValues();
        this.setState({
            previousTickets: [],
            statusIDs: [],
            deskAgents: [],
            deskTeams: [],
            filteredDeskAgents: [],
            filteredDeskTeams: [],
            subjectTicket_error: '',
            assignedTo_error: '',
            priorityStatus_error: '',
            statusTicket_error: '',
            descriptionTicket_error: '',
            clientEmail_error: '',
            clientName: '',
            showAssignedToMenu: false,
            isAgentTab: true,
            deskContactId: '',
            transcriptURL: ''
        });
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (this.context.rightSectionItemToShow === "visitorPath" && this.state.pastVisits.length === 0) {
            const pastVisits = [];
            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.context.liveChatDocId).get().then((doc) => {
                var queryData = [];
                if (doc.exists) {
                    queryData = doc.data().PageUrl_a;
                }
                queryData.forEach((URL) => {
                    pastVisits.push({ URL });
                });
                this.setState({ pastVisits: pastVisits });
            });
        }

        if (this.context.rightSectionItemToShow !== "visitorPath" && this.state.pastVisits.length > 0) {
            this.closeVisitorPaths();
        }

        if (this.context.rightSectionItemToShow === "notesList" && (this.state.stickyNotes.length !== this.totalStickyNotes || this.state.liveChatDocId !== this.context.liveChatDocId || !this.isStickyNotesCalled)) {
            this.getStickyNotes();
        }

        if (this.context.rightSectionItemToShow !== "notesList" && this.context.rightSectionItemToShow !== "noteForm" && this.state.stickyNotes.length > 0) {
            this.closeStickyNotes();
        }

        /* To get Previous ticket from API by Desk team */
        if (this.context.rightSectionItemToShow === "ticketing" && this.state.statusIDs.length === 0) {
            this.getPreviousTicket();
        }

        if (this.context.rightSectionItemToShow !== "ticketing" && this.state.statusIDs.length > 0) {
            this.closeTicketing();
        }

        /* For transfer and Add Agent Start */

        if (this.context.rightSectionItemToShow === "transferAgent" && this.context.rightSectionItemToShow !== this.state.transferOrAddAgent) {
            this.setState({ transferOrAddAgent: this.context.rightSectionItemToShow, srchagenttoadd: "", agent_department: "" }, () => {
                try {
                    document.getElementById("srchagenttoadd").value = "";
                    document.getElementById("agent_department").value = "";
                    this.searchAgentToTransfer();
                } catch (errr) { }
            });
        }

        if (this.context.rightSectionItemToShow === "addAgent" && this.context.rightSectionItemToShow !== this.state.transferOrAddAgent) {
            this.setState({ transferOrAddAgent: this.context.rightSectionItemToShow, srchagenttotransfer: "" }, () => {
                try {
                    document.getElementById("srchagenttotransfer").value = "";
                    this.searchAgentToAdd();
                } catch (errr) { }
            });
        }

        /* For transfer and Add Agent End */

        /* For Lazy Loader Start */

        if (this.hasLoadedlazy === false && (this.state.filteredVisitors).length > 0) {
            this.hasLoadedlazy = true;
            let curpage = this.state.currentPage;
            let visitors = (this.state.filteredVisitors).slice(curpage * this.state.pagesize, (curpage + 1) * this.state.pagesize);
            this.setState({ displayVisitors: visitors, totalPage: Math.ceil((this.state.filteredVisitors).length / this.state.pagesize) });
        }

        if (this.hasLoadedPrelazy === false && (this.state.filteredPreVisitors).length > 0) {
            this.hasLoadedPrelazy = true;
            let curpage = this.state.currentPrePage;
            let visitors = (this.state.filteredPreVisitors).slice(curpage * this.state.pagesize, (curpage + 1) * this.state.pagesize);
            this.setState({ displayPreVisitors: visitors, totalPage: Math.ceil((this.state.filteredPreVisitors).length / this.state.pagesize) });
        }

        /* For Lazy Loader End */

        /* for checkoing added Agent in chat */

        if (this.state.onlineAgents.length > 0 && Object.keys(this.context.currentVisitor).length > 0 && this.state.currentVisitor !== this.context.currentVisitor) {
            if (this.context.currentVisitor.agent_ids.length > 0 && this.context.currentVisitor.agent_end !== "VISITOR_END_CHAT" && this.context.currentVisitor.isChatend === 0) {
                var onlineAgents = this.state.onlineAgents;
                var filteredAgents = onlineAgents.filter(data => {
                    return (this.context.currentVisitor.agent_ids.indexOf(data.key) === -1) ? true : false;
                });
                this.setState({ currentVisitor: this.context.currentVisitor, filteredAgents: filteredAgents }, () => {
                    this.searchAgentToTransfer();
                });
            }
        }

        if (Object.keys(this.context.currentVisitor).length > 0) {
            if (this.context.currentVisitor.agent_ids.length !== this.state.groupChatAgents.length) {
                this.getGroupChatAgents();
            }
        }

        ///// When Internet re-connects then reload data //////////
        if (this.context.goneOffline !== this.state.checkReload) {
            if (!this.context.goneOffline && this.state.checkReload) {
                this.reloadVisitors();
            }
            this.state.checkReload = this.context.goneOffline;
        }
        ///// When Internet re-connects then reload data //////////

        ////// For Online Agents In Mobile Start ////////

        if (this.context.isMobileDevice && this.context.Mob_showOnlineAgents !== this.state.showAgents) {
            if (this.context.Mob_showOnlineAgents) {
                this.showOnlineAgents();
            }
        }

        ////// For Online Agents In Mobile End ////////

        ////// For Dialer In Mobile Start ////////

        if (this.context.isMobileDevice && this.context.Mob_showPhoneDialer !== this.state.mobShowDialer) {
            this.state.mobShowDialer = this.context.Mob_showPhoneDialer;
            if (this.context.Mob_showPhoneDialer) {
                this.showDialer();
            }
        }

        ////// For Dialer In Mobile End ////////

        ///////// For resizing right Premium and Normal Visitor according length Start //////////

        if (this.state.displayPreVisitors.length !== this.totalDispPremVisitor) {
            this.totalDispPremVisitor = this.state.displayPreVisitors.length;
            this.resizeRightPanel();
        }

        if (this.state.displayVisitors.length !== this.totalDispNormVisitor) {
            this.totalDispNormVisitor = this.state.displayVisitors.length;
            this.resizeRightPanel();
        }

        ///////// For resizing right Premium and Normal Visitor according length End //////////

        ///////// For Client paln data update in whatsapp template start ////////////
        if (this.state.liveChatDocId !== this.context.liveChatDocId || this.context.startClickedChatId === this.state.liveChatDocId) {
            this.isStickyNotesCalled = false;
            this.state.liveChatDocId = this.context.liveChatDocId;
            this.state.showWhatsappTemplates = [];
            if (this.context.startClickedChatId === this.state.liveChatDocId) {
                setTimeout(async () => {
                    let clientPlan = await getPlanData(this.context.liveChatDocId);
                    let tmpTemplates = this.state.whatsappTemplates;
                    if (tmpTemplates && clientPlan && clientPlan.status === "data") {
                        var templatesData2 = [];
                        tmpTemplates.forEach((tmpcont, cindex) => {
                            var tempData = {};
                            var tmpArray = [];
                            var tmpObj = {};
                            tempData.addedon = tmpcont.addedon;
                            tempData.id = tmpcont.id;
                            for (const cntnt2 in tmpcont.content) {
                                var tmpcntn2 = tmpcont.content[cntnt2];
                                tmpcntn2 = tmpcntn2.replace('{{1}}', this.agent.agent_name);
                                tmpcntn2 = tmpcntn2.replace('{{2}}', clientPlan.plan_name);
                                tmpcntn2 = tmpcntn2.replace('{{3}}', clientPlan.app_name);
                                tmpArray.push({ lang: cntnt2, message: tmpcntn2 });
                                tmpObj[cntnt2] = tmpcntn2;
                            }
                            tempData.contentArray = tmpArray;
                            tempData.content = tmpObj;
                            templatesData2.push(tempData);
                        });
                        this.setState({ showWhatsappTemplates: templatesData2 });
                    }
                }, 3000);
            } else {
                let clientPlan = await getPlanData(this.context.liveChatDocId);
                let tmpTemplates = this.state.whatsappTemplates;
                if (tmpTemplates && clientPlan && clientPlan.status === "data") {
                    var templatesData2 = [];
                    tmpTemplates.forEach((tmpcont, cindex) => {
                        var tempData = {};
                        var tmpArray = [];
                        var tmpObj = {};
                        tempData.addedon = tmpcont.addedon;
                        tempData.id = tmpcont.id;
                        for (const cntnt2 in tmpcont.content) {
                            var tmpcntn2 = tmpcont.content[cntnt2];
                            tmpcntn2 = tmpcntn2.replace('{{1}}', this.agent.agent_name);
                            tmpcntn2 = tmpcntn2.replace('{{2}}', clientPlan.plan_name);
                            tmpcntn2 = tmpcntn2.replace('{{3}}', clientPlan.app_name);
                            tmpArray.push({ lang: cntnt2, message: tmpcntn2 });
                            tmpObj[cntnt2] = tmpcntn2;
                        }
                        tempData.contentArray = tmpArray;
                        tempData.content = tmpObj;
                        templatesData2.push(tempData);
                    });
                    this.setState({ showWhatsappTemplates: templatesData2 });
                }
            }
        }
        ///////// For Client paln data update in whatsapp template end ////////////
    }

    getGroupChatAgents = () => {
        try {
            var promises = [];
            for (let i = 0; i < this.context.currentVisitor.agent_ids.length; i++) {
                promises.push(firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("agents").doc(this.context.currentVisitor.agent_ids[i]).get());
            }
            Promise.all(promises).then(allResult => {
                var allAgents = [];
                for (const index in allResult) {
                    const aData = allResult[index];
                    const Agent = {
                        id: aData.id,
                        agent_short: aData.data().agent_short,
                        alias: aData.data().alias,
                        email: aData.data().email,
                        name: aData.data().name,
                        profile_img: aData.data().profile_img
                    };
                    allAgents.push(Agent);
                }
                this.setState({ groupChatAgents: allAgents });
            });

        } catch (err) { console.log("error"); }
    }

    confirmEndChat = () => {
        this.context.updateGlobalData("isCloseBtnClicked", "end");
    }

    confirmLeaveChat = () => {
        this.context.updateGlobalData("isCloseBtnClicked", "leave");
    }

    closePanel = () => {
        var arr = [{ key: "isCloseBtnClicked", value: "" }, { key: "rightSectionItemToShow", value: "visitorslist" }];
        this.context.updateGlobalData("array", arr);
    }

    closeVisitorPaths = () => {
        if (this.context.rightSectionItemToShow === "visitorPath") {
            this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
        }
        this.setState({ pastVisits: [] });
    }

    closeNotes = (section = 'list') => {
        this.isStickyNotesCalled = false;
        if (section === "form") {
            this.context.updateGlobalData("rightSectionItemToShow", "notesList");
        } else {
            this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
        }
    }

    addNote = () => {
        this.context.updateGlobalData("rightSectionItemToShow", "noteForm");
    }

    saveNote = async () => {
        var notes = document.getElementById("notes").value.trim();
        if (notes) {
            this.setState({ stickyNotesLoader: true });
            this.context.updateGlobalData("rightSectionItemToShow", "notesList");
            var addedon = Date.now();
            var docId = 'NOTE' + addedon;
            var notId = this.context.currentVisitor.client_email !== '' && this.context.currentVisitor.client_email !== 'noreply@appypie.com' ? this.context.currentVisitor.client_email : this.context.liveChatDocId;
            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("notes").doc(notId).collection('notes').doc(docId).set({
                addedon: Date.now(),
                agent_id: this.agent.agentId,
                agent_name: this.agent.name,
                notes: notes,
                clientId: this.context.liveChatDocId
            }).then((ref) => {
                document.getElementById("notes").value = "";
                this.getStickyNotes();
            }).catch((error) => {
                this.setState({ stickyNotesLoader: false });
                console.error("Error adding notes: ", error);
            });
        }
    }

    getStickyNotes = () => {
        this.isStickyNotesCalled = true;
        this.setState({ stickyNotesLoader: true });
        let promise1 = new Promise((res, rej) => {
            if (this.context.currentVisitor.client_email !== '' && this.context.currentVisitor.client_email !== undefined) {
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('notes').doc(this.context.currentVisitor.client_email).collection('notes').orderBy('addedon', 'desc').get().then((notes) => {
                    var notesData = [];
                    notes.forEach((note) => {
                        var noteData = note.data();
                        noteData.id = note.id;
                        //var notes = noteData.notes.substring(0, 20) + '...';
                        noteData.addedDate = getTimeFromDate(noteData.addedon);
                        noteData.type = 'email';
                        notesData.push(noteData);
                    });
                    res(notesData);
                });
            } else {
                var notesData = [];
                res(notesData);
            }
        })

        let promise2 = new Promise((Resolve, Reject) => {
            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('notes').doc(this.context.liveChatDocId).collection('notes').orderBy('addedon', 'desc').get().then((notes) => {
                var notesData = [];
                notes.forEach((note) => {
                    var noteData = note.data();
                    noteData.id = note.id;
                    //var notes = noteData.notes.substring(0, 20) + '...';
                    noteData.addedDate = getTimeFromDate(noteData.addedon);
                    noteData.type = 'id';
                    notesData.push(noteData);
                });
                Resolve(notesData);
            });
        })

        Promise.all([promise1, promise2]).then(results => {
            var finalResult = [...results[0], ...results[1]];
            finalResult.sort(function (a, b) {
                return b.addedon - a.addedon;
            });
            var notesMsgs = [];
            finalResult.forEach((fData) => {
                notesMsgs[fData.id] = fData.notes;
            })
            this.totalStickyNotes = finalResult.length;
            this.setState({ stickyNotes: finalResult, notesMsgs: notesMsgs, stickyNotesLoader: false });
        })
    }

    closeStickyNotes = () => {
        if (this.context.rightSectionItemToShow === "notesList" || this.context.rightSectionItemToShow === "noteForm") {
            this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
        }
        this.setState({ stickyNotes: [], stickyNotesLoader: false });
    }

    addCountNotification = async (chatId, msg, agentId, type) => {
        if (type === "transfer") {
            var serverTimestamp = await getDateInUTC();
            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('Notifications').doc('LIVE' + (Date.now()) + Math.ceil(Math.random() * 999999)).set({
                msg: msg,
                msg_type: 6,
                chatId: chatId,
                agents: agentId,
                addedon: serverTimestamp,
                agent_received: [],
                client_id: chatId,
                incoming: 2,
                agent_end: 'TRANSFER_CHAT',
                from: this.agent.name,
                fromType: 2,
                updatedon: serverTimestamp
            });
        } else if (type === "addAgent") {
            var serverTimestamp2 = await getDateInUTC();
            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('Notifications').doc('LIVE' + (Date.now()) + Math.ceil(Math.random() * 999999)).set({
                msg: msg,
                msg_type: 6,
                chatId: chatId,
                agents: agentId,
                addedon: serverTimestamp2,
                agent_received: [],
                client_id: chatId,
                incoming: 2,
                agent_end: 'ADD_AGENT_TO_CHAT',
                from: this.agent.name,
                fromType: 2,
                updatedon: serverTimestamp2
            });
        }
    }

    /* -------------------Transfer functions start ----------------------------*/

    handleChange = (e) => {
        let trgtName = e.target.name ? e.target.name : "";
        if (!trgtName) {
            return false;
        }
        let trgtValue = e.target.value ? e.target.value : "";
        if (trgtName === 'clientEmailTicket') {
            let visitorObj = this.context.currentVisitor;
            visitorObj.client_email = trgtValue;
            this.context.updateGlobalData("currentVisitor", visitorObj);
            this.setState({ clientEmailTicket: trgtValue })
        } else {
            this.setState({ [trgtName]: trgtValue }, () => {
                if (trgtName === "srchagenttoadd") {
                    this.searchAgentToAdd();
                } else if (trgtName === "srchagenttotransfer") {
                    this.searchAgentToTransfer();
                } else if (trgtName === "priorityStatus") {
                    let priorityList = ['low', 'medium', 'high', 'urgent'];
                    priorityList.forEach((priority) => {
                        let priorityDiv = document.getElementById(`priorityStatus_${priority}`);
                        priorityDiv.style.background = "#ffffff";
                    });
                    let priorityDiv = document.getElementById(`priorityStatus_${trgtValue}`);
                    priorityDiv.style.background = "#DFF0FD";
                }
            });
        }
    }

    handleDepartmentChange = (e) => {
        this.setState({ agent_department: e.target.value }, () => {
            this.searchAgentToAdd();
        });
    }

    closeTransfor = () => {
        this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
    }

    itemRemove = (arrayData, itemId) => {
        let returnArrayData = arrayData;
        if (returnArrayData.length > 0) {
            if (returnArrayData.includes(itemId)) {
                var index = returnArrayData.indexOf(itemId);
                if (index !== -1) {
                    returnArrayData.splice(index, 1);
                }
            }
        }
        return returnArrayData;
    }

    transferAgent = () => {

        if (this.context.goneOffline) {
            return false;
        }

        this.context.updateGlobalData("isDisableIncomingPanel", true);
        const added_agent = this.state.added_agent;
        const chatId = this.context.liveChatDocId;
        this.setState({ transferBtnText: "Transfering..." });
        var docRef = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatId);
        docRef.get().then((visitor) => {
            if (visitor.exists) {
                var visitorData = visitor.data();
                visitorData.id = visitor.id;
                const agent_ides = visitorData.agent_ids;
                const leaveagentIds = visitorData.agent_leave;
                let allChatAgents = visitorData.agents ? visitorData.agents : {};
                var chatlisting = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(chatId).collection('chat_data');
                added_agent.forEach(async (agentData) => {
                    agent_ides.push(agentData.key);
                    var agentReference = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').doc(agentData.key);
                    docRef.collection('agent_data').doc(agentData.key)
                        .set({
                            addedon: (new Date()).getTime() + this.state.offset,
                            agent_added_by: this.agent.agentId,
                            agent_added_by_name: this.agent.name,
                            agent_id: agentData.key,
                            agent_name: agentData.name,
                            agent_profile_img: agentData.profile_img,
                            agent_ref: agentReference,
                            status: 1,
                            calling_status: agentData.calling_status ? agentData.calling_status : 0
                        }).then((ref) => {
                        }).catch((error) => {
                            console.error("Error adding document: ", error);
                        });

                    const increment = firebase1.firestore.FieldValue.increment(1);
                    agentReference.update({ chat_total: increment });

                    //var addedon = (new Date()).getTime() + this.state.offset;

                    /* Save Message Start */
                    var serverTimestamp = await getDateInUTC();
                    var docId = 'LIVE' + serverTimestamp + Math.ceil(Math.random() * 999999);
                    var actionType = "transferagent";
                    var msgContent = 'Agent ' + this.agent.name + ' transferred chat to agent ' + agentData.name;
                    var msg_type = 6;
                    chatlisting.doc(docId).set({
                        addedon: serverTimestamp,
                        agent_id: this.agent.agentId,
                        agent_profile_img: this.agent.profile_img,
                        agent_name: this.agent.name,
                        msg: msgContent,
                        msg_type: msg_type,
                        msg_url: '',
                        msg_read_status: 1,
                        contenttype: "",
                        agent_short: this.agent.agent_short,
                        filesize: 0,
                        action: actionType
                    }).then((ref) => { }).catch((error) => {
                        console.error("Error adding messege: ", error);
                    });
                    /* Save Message End */

                    //// Send Notification ///////////
                    this.addCountNotification(chatId, 'Agent ' + this.agent.name + ' transferred chat to agent you', agentData.key, "transfer");
                    //// Send Notification ///////////

                    /* For 786 Start */
                    if (Object.keys(allChatAgents).indexOf(this.agent.agentId) !== -1) {
                        delete allChatAgents[this.agent.agentId];
                    }

                    if (Object.keys(allChatAgents).indexOf(agentData.key) === -1) {
                        allChatAgents[agentData.key] = { name: agentData.agent_name, alias: agentData.agent_short, agentShort: agentData.name_short, email: agentData.email, profileImg: agentData.profile_img };
                    }
                    /* For 786 End */

                    if (leaveagentIds.indexOf(this.agent.agentId) === -1) {
                        leaveagentIds.push(this.agent.agentId);
                    }

                    let assignedchatagent = this.itemRemove(agent_ides, this.agent.agentId);
                    docRef.update({ updatedon: serverTimestamp, latest_msg: msgContent, front_read_count: increment, agent_ids: assignedchatagent, agent_leave: leaveagentIds, agents: allChatAgents }).then(res => {
                        docRef.update({ [this.agent.agentId]: 0, updatedon: eval(serverTimestamp) + 10 });
                    });
                });
                docRef.collection('agent_data').doc(this.agent.agentId).update({ status: 3 });
                this.setState({ added_agent: [] });
                setTimeout(() => {
                    this.setState({ transferBtnText: "Transfer" });
                    var arr = [{ key: "rightSectionItemToShow", value: "visitorslist" }, { key: "liveChatDocId", value: "" }, { key: "isDisableIncomingPanel", value: false },{ key: "showMidHeader", value: false },{ key: "showMidHeader", value: false },{
                        key: "blankChatScreenMsg",
                        value: "No ongoing chat is assigned to you. Select an incoming chat or initiate a chat by clicking on a visitor from visitor panel."
                    }];
                    this.context.updateGlobalData("array", arr);
                }, 10);
            }
        });
    }

    removeAgentList = (agent) => {
        var added_agent = [];
        var added_agent_list = this.state.added_agent;
        added_agent_list.forEach((agentData) => {
            if (agentData.key !== agent.key) {
                added_agent.push(agentData);
            }
        });
        this.setState({ added_agent: added_agent });
        this.searchAgentToTransfer(); //Refresh the available_agents list
    }

    agentExists = (key) => {
        return this.state.availableAgents.some(function (el) {
            return el.key === key;
        });
    }

    searchAgentToTransfer = async () => {
        /*  var element = document.getElementById('srchagenttotransfer');
         var searchuser = (typeof (element) !== 'undefined' && element !== null) ? (element.value).toLowerCase().trim() : "";
         var elementDept = document.getElementById('agent_department');
         var department = (typeof (elementDept) !== 'undefined' && elementDept !== null) ? elementDept.value : ""; */
        var searchuser = this.state.srchagenttotransfer ? this.state.srchagenttotransfer : "";
        if (searchuser) {
            searchuser = searchuser.toLowerCase();
        }
        var department = (this.state.agent_department && this.state.agent_department !== "all") ? this.state.agent_department : "";
        var availableAgents = [];
        var all_online_agent = this.state.filteredAgents;
        var utcTime = await getDateInUTC();
        var minActiveTime = utcTime - this.state.idleTime * 60 * 1000;
        all_online_agent.forEach((agentData) => {
            var agentName = agentData.name.toLowerCase();
            var agentsAlias = agentData.alias.toLowerCase();
            var departmentData = agentData.departments;
            var searchCheck = false;
            var departmentCheck = false;
            var lastActivityCheck = false;

            if (searchuser === "") {
                searchCheck = true;
            } else if (agentName.includes(searchuser) || agentsAlias.includes(searchuser)) {
                searchCheck = true;
            }

            if (department === "") {
                departmentCheck = true;
            } else if (departmentData.includes(department)) {
                departmentCheck = true;
            }

            if (this.state.inactive_check) {
                if (agentData.last_activity_time === undefined) {
                    lastActivityCheck = false;
                } else if (agentData.last_activity_time >= minActiveTime) {
                    lastActivityCheck = true;
                }
            } else {
                lastActivityCheck = true;
            }

            if (departmentCheck === true && searchCheck === true && lastActivityCheck === true) {
                availableAgents.push(agentData);
            }
        });

        this.setState({ availableAgents: availableAgents }, () => {
            if (this.state.added_agent.length > 0) {
                if (availableAgents.length > 0) {
                    let tmpAddedAgents = [];
                    this.state.added_agent.forEach((tmpAgent) => {
                        var isExist = this.agentExists(tmpAgent.key);
                        if (isExist) {
                            tmpAddedAgents.push(tmpAgent);
                        }
                    });
                    this.setState({ added_agent: tmpAddedAgents });
                } else {
                    this.setState({ added_agent: [] });
                }
            }

        });
    }

    /* -------------------Transfer functions End ----------------------------*/

    /* -------------------Add Agent in chat Start ----------------------------*/

    addAgentList = (agent) => {
        var added_agent = this.state.added_agent;
        if (added_agent.filter(item => item.key === agent.key).length === 0) {
            added_agent.push({ key: agent.key, profile_img: agent.profile_img, name: agent.name, agent_short: agent.agent_short, agent_name: agent.agent_name, name_short: agent.name_short, email: agent.email, calling_status: agent.calling_status });
            this.setState({ added_agent: added_agent }, () => {
                this.searchAgentToAdd();
            });
        } else {
            this.removeAgent(agent);
        }
    }

    removeAgent = (agent) => {
        var added_agent = [];
        var added_agent_list = this.state.added_agent;
        added_agent_list.forEach((agentData) => {
            if (agentData.key !== agent.key) {
                added_agent.push(agentData);
            }
        });
        this.setState({ added_agent: added_agent });
        this.searchAgentToAdd(); //Refresh the available_agents list

        var checkboxes = document.getElementsByClassName('addAgentCheckbox');
        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].type === 'checkbox' && checkboxes[i].value === agent.key) {
                checkboxes[i].checked = false;
            }
        }
    }

    searchAgentToAdd = () => {
        /* var element = document.getElementById('srchagenttoadd');
        var searchuser = (typeof (element) !== 'undefined' && element !== null) ? (element.value).toLowerCase().trim() : ""; 
        var elementDept = document.getElementById('agent_department');
        var department = (typeof (elementDept) !== 'undefined' && elementDept !== null) ? elementDept.value : ""; */

        var searchuser = this.state.srchagenttoadd ? this.state.srchagenttoadd : "";
        if (searchuser) {
            searchuser = searchuser.toLowerCase();
        }
        var department = (this.state.agent_department && this.state.agent_department !== "all") ? this.state.agent_department : "";
        var availableAgents = [];
        var all_online_agent = this.state.filteredAgents;

        all_online_agent.forEach((agentData) => {
            var agentName = agentData.name.toLowerCase();
            var agentsAlias = agentData.alias.toLowerCase();
            var departmentData = agentData.departments;
            var searchCheck = false;
            var departmentCheck = false;

            if (searchuser === "") {
                searchCheck = true;
            } else if (agentName.includes(searchuser) || agentsAlias.includes(searchuser)) {
                searchCheck = true;
            }

            if (department === "") {
                departmentCheck = true;
            } else if (departmentData.includes(department)) {
                departmentCheck = true;
            }

            if (departmentCheck === true && searchCheck === true) {
                availableAgents.push(agentData);
            }
        });
        this.setState({ availableAgents: availableAgents });
    }

    addAgent = () => {

        if (this.context.goneOffline) {
            return false;
        }

        this.setState({ addAgentBtnText: "Adding..." });
        const chatId = this.context.liveChatDocId;
        const added_agent = this.state.added_agent;
        const agentRef = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatId).collection('agent_data');
        const chatlisting = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(chatId).collection('chat_data');
        var agentId = this.agent.agentId;

        var docRef = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatId);
        docRef.get().then((visitor) => {
            if (visitor.exists) {
                var visitorData = Object.assign({}, visitor.data());
                visitorData.id = visitor.id;
                const assignedchatagent = visitorData.agent_ids;
                const agentleave = visitorData.agent_leave;

                let allChatAgents = visitorData.agents ? visitorData.agents : {};

                if (agentleave.length > 0) {
                    if (agentleave.includes(agentId)) {
                        var index = agentleave.indexOf(agentId);
                        if (index > -1) {
                            agentleave.splice(index, 1);
                        }
                    }
                }
                docRef.update({ agent_leave: agentleave });
                added_agent.forEach(async (agentData) => {
                    if (assignedchatagent.indexOf(agentData.key) === -1) {
                        assignedchatagent.push(agentData.key);
                    }
                    const agentReference = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').doc(agentData.key);
                    agentRef.doc(agentData.key).set({
                        addedon: (new Date()).getTime() + this.state.offset,
                        agent_added_by: agentId,
                        agent_added_by_name: this.agent.name,
                        agent_id: agentData.key,
                        agent_name: agentData.name,
                        agent_profile_img: agentData.profile_img,
                        agent_ref: agentReference,
                        status: 1,
                        calling_status: agentData.calling_status ? agentData.calling_status : 0
                    }).then((ref) => { }).catch((error) => {
                        console.error("Error adding document: ", error);
                    });
                    const increment = firebase1.firestore.FieldValue.increment(1);
                    agentReference.update({ chat_total: increment });
                    // var addedon = (new Date()).getTime() + this.state.offset;
                    /* Save Message Start */
                    ///var docId = 'LIVE' + (addedon - 1);
                    var serverTimestamp = await getDateInUTC();
                    var docId = 'LIVE' + serverTimestamp + Math.ceil(Math.random() * 999999);
                    var actionType = "addagent";
                    var msgContent = 'Agent ' + this.agent.name + ' added agent ' + agentData.name + " to the chat.";
                    var msg_type = 6;
                    chatlisting.doc(docId).set({
                        addedon: serverTimestamp,
                        agent_id: this.agent.agentId,
                        agent_profile_img: this.agent.profile_img,
                        agent_name: this.agent.name,
                        msg: msgContent,
                        msg_type: msg_type,
                        msg_url: "",
                        msg_read_status: 1,
                        contenttype: "",
                        agent_short: this.agent.agent_short,
                        filesize: 0,
                        action: actionType,
                        agent_id_of_added_agent: agentData.key
                    }).then((ref) => { }).catch((error) => {
                        console.error("Error adding messege: ", error);
                    });
                    /* Save Message End */

                    //// Send Notification ///////////
                    this.addCountNotification(chatId, 'Agent ' + this.agent.name + ' added agent ' + agentData.name + " to the chat.", agentData.key, "addAgent");

                    /* For 786 Start */
                    if (Object.keys(allChatAgents).indexOf(agentData.key) === -1) {
                        allChatAgents[agentData.key] = { name: agentData.agent_name, alias: agentData.agent_short, agentShort: agentData.name_short, email: agentData.email, profileImg: agentData.profile_img };
                    }
                    /* For 786 End */

                    docRef.update({ agent_ids: assignedchatagent, updatedon: serverTimestamp, latest_msg: msgContent, front_read_count: increment, agents: allChatAgents }).then(res => {
                        docRef.update({ [this.agent.agentId]: 0, updatedon: eval(serverTimestamp) + 10 });
                    });
                });
                this.setState({ added_agent: [] });
                this.searchAgentToAdd();
                setTimeout(() => {
                    this.setState({ addAgentBtnText: "Done" });
                    this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
                }, 200);
            }
        });
    }

    closeAddAgent = () => {
        this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
    }

    /* -------------------Add Agent in chat End ------------------------------*/

    showVisitorsList = () => {
    this.getCurrentTimeOnline().then(r=>{
            this.updateVisitors();
            this.getOngoingVisitors();
        });
        this.closeVisitorpanel = false;
        this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
        var element = document.getElementById('visitorGroupRightIcon');
        if(element){
            element.classList.add("active");
        }
        
    }

    setFilterOrderByOption = () => {
        var corval = "none";
        var ele = document.getElementsByName('optgroupby');
        for (let i = 0; i < ele.length; i++) {
            if (ele[i].checked) {
                corval = ele[i].value;
            }
        }

        try {
            let filtrD = this.state.filterOptions;
            filtrD.groupBy = corval;
            insertOrUpdateFilters(filtrD);
        } catch (errr) { }

        this.setState({ optionFilterBy: corval }, () => {
            this.mapCountryUrlsDevicesList();
        });

        this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
    }

    resetFilterOrderByOption = () => {
        document.getElementById("optnone").checked = true;
        try {
            let filtrD = this.state.filterOptions;
            filtrD.groupBy = "";
            insertOrUpdateFilters(filtrD);
        } catch (errr) { }
        this.setState({ optionFilterBy: "none" }, () => {
            this.mapCountryUrlsDevicesList();
        });
    }

    updateCountry = (e) => {
        let { value } = e.target;
        let tmpFilterOptions = this.state.filterOptions;
        let countArr = tmpFilterOptions.countries ? tmpFilterOptions.countries : [];
        //let urlArr = tmpFilterOptions.pageUrls ? tmpFilterOptions.pageUrls : [];
        if (countArr.indexOf(value) === -1) {
            countArr.push(value);
        } else {
            countArr.splice(countArr.indexOf(value), 1);
        }
        tmpFilterOptions.countries = countArr;
        tmpFilterOptions.total = Date.now();
        this.setState({ callRender: Date.now(), filterOptions: tmpFilterOptions }, () => {
            this.setState({});
        });
        //this.setState({ filterOptions: { type: "", groupBy: "", searchText: "", countries: countArr, pageUrls: urlArr } });
    }

    updatePageUrl = (e) => {
        let { value } = e.target;
        let tmpFilterOptions = this.state.filterOptions;
        //let countArr = tmpFilterOptions.countries ? tmpFilterOptions.countries : [];
        let urlArr = tmpFilterOptions.pageUrls ? tmpFilterOptions.pageUrls : [];
        if (urlArr.indexOf(value) === -1) {
            urlArr.push(value);
        } else {
            urlArr.splice(urlArr.indexOf(value), 1);
        }
        tmpFilterOptions.pageUrls = urlArr;
        tmpFilterOptions.total = Date.now();
        this.setState({ callRender: Date.now(), filterOptions: tmpFilterOptions });
        /* this.setState({ filterOptions: { type: "", groupBy: "", searchText: "", countries: countArr, pageUrls: urlArr } }); */
    }

    updateDeviceUsed = (e) => {
        let { value } = e.target;
        let tmpFilterOptions = this.state.filterOptions;
        //let countArr = tmpFilterOptions.countries ? tmpFilterOptions.countries : [];
        let devicesArr = tmpFilterOptions.devicesUsed ? tmpFilterOptions.devicesUsed : [];
        if (devicesArr.indexOf(value) === -1) {
            devicesArr.push(value);
        } else {
            devicesArr.splice(devicesArr.indexOf(value), 1);
        }
        tmpFilterOptions.devicesUsed = devicesArr;
        tmpFilterOptions.total = Date.now();
        this.setState({ callRender: Date.now(), filterOptions: tmpFilterOptions });
        /* this.setState({ filterOptions: { type: "", groupBy: "", searchText: "", countries: countArr, pageUrls: urlArr } }); */
    }

    /* accordion function start */

    toggle = (code, event) => {
        var tmpOpenArr = this.state.open;
        const index = tmpOpenArr.indexOf(code);
        if (index === -1) {
            tmpOpenArr.push(code);
        } else {
            tmpOpenArr.splice(index, 1);
        }
        this.setState({ open: tmpOpenArr }, () => {
            this.mapCountryUrlsDevicesList();
            //this.setState({ doRender: Date.now() });
        });
    }


    /* accordion function End */

    /* Get Idle Time Start */

    getIdleTime = () => {
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('settings').doc('IDLETIME').get().then((docs) => {
            if (docs.exists) {
                var setting = {};
                setting.agent_inactive_min = parseInt(docs.data().agent_inactive_min);
                setting.agent_inactive_status = parseInt(docs.data().agent_inactive_status);
                setting.agent_reply_min = parseInt(docs.data().agent_reply_min);
                setting.agent_reply_check = docs.data().agent_reply_check;

                this.setState({
                    idleTime: docs.data().agent_inactive_min,
                    inactive_check: docs.data().agent_inactive_check,
                    setting: setting
                })
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    /* Get Idle Time End */

    /* To Show Online Agents Start */

    showOnlineAgents = () => {
        this.setState({ showAgents: true, showDialer: false }, () => {
            try {
                document.getElementById("shadowMasking2").style.display = "block";
                document.getElementById("chat-visitore-nav").style.zIndex = "9999";
                document.getElementById('onlineAgentsRightIcon').classList.add("active");
                document.getElementById('new-Main-Chat').classList.remove('VRP3');
            } catch (err) { }
        });
    }

    closeOnlineAgents = () => {
        if (this.context.isMobileDevice) {
            this.context.updateGlobalData("Mob_showOnlineAgents", false);
        }
        this.setState({ showAgents: false }, () => {
            try {
                document.getElementById("shadowMasking2").style.display = "none";
                document.getElementById("chat-visitore-nav").style.zIndex = "99";
                document.getElementById('onlineAgentsRightIcon').classList.remove("active");
                document.getElementById('new-Main-Chat').classList.remove('VRP2');
            } catch (err) { }
        });
    }

    searchAgent = () => {
        var element = document.getElementById('srchagentonline');
        var searchuser = (typeof (element) !== 'undefined' && element !== null) ? (element.value).toLowerCase().trim() : "";
        var all_online_agent = this.state.allOnlineAgents;
        const checkindex = all_online_agent.findIndex(x => x.key === this.agent.agentId);
        if (checkindex !== -1) {
            const currentAgent = all_online_agent[checkindex];
            all_online_agent.splice(checkindex, 1);
            all_online_agent.splice(0, 0, currentAgent);
        }

        ////console.log('all_online_agent >>> ', all_online_agent);

        if (searchuser && all_online_agent.length > 0) {
            var seachedAgents = [];
            all_online_agent.forEach((agentData) => {
                var agentName = agentData.name.toLowerCase();
                var agentsAlias = agentData.alias.toLowerCase();
                if (agentName.includes(searchuser) || agentsAlias.includes(searchuser)) {
                    seachedAgents.push(agentData);
                }
            });
            this.setState({ seachedAgents: seachedAgents });
        } else {
            this.setState({ seachedAgents: all_online_agent });
        }
    }

    /* To Show Online Agents End */

    /* Added by dharmesh for notes on 28/09/2020 start here */

    deleteNote = (noteId, notType) => {
        var notId = notType === 'email' ? this.context.currentVisitor.client_email : this.context.liveChatDocId;
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('notes').doc(notId).collection('notes').doc(noteId).delete().then(() => {
            this.getStickyNotes();
        })
    }

    editNote = (noteId) => {
        document.getElementById("note-inner-" + noteId).style.display = "none";
        document.getElementById("note-edit-" + noteId).style.display = "block";
    }

    cancelUpdate = (noteId) => {
        document.getElementById("note-inner-" + noteId).style.display = "block";
        document.getElementById("note-edit-" + noteId).style.display = "none";
    }

    textChangeHandle = (e, notId) => {
        var notes = this.state.notesMsgs;
        var target = e.target;
        notes[notId] = target.value;
        this.setState({
            notesMsgs: notes,
            testMsg: target.value
        })
    }

    updateNote = async (noteId, notType) => {
        var value = document.getElementById("note-text-" + noteId).value.trim();
        if (value) {
            var notId = notType === 'email' ? this.context.currentVisitor.client_email : this.context.liveChatDocId;
            var servertimeStamp = await getDateInUTC();
            var agentName = this.agent.alias ? this.agent.alias : this.agent.name;
            var logs = { agent: this.agent.agentId, time: servertimeStamp };
            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('notes').doc(notId).collection('notes').doc(noteId).update({
                notes: value,
                last_updated: servertimeStamp,
                last_updated_agent_id: this.agent.agentId,
                last_updated_agent_name: agentName,
                edit_logs: firebase1.firestore.FieldValue.arrayUnion(logs)
            }).then(() => {
                this.getStickyNotes();
                document.getElementById("note-inner-" + noteId).style.display = "block";
                document.getElementById("note-edit-" + noteId).style.display = "none";
            })
        }
    }

    /* Added by dharmesh for notes on 28/09/2020 ends here */

    /* For Active and served visitors start */

    getOngoingVisitors = async () => {
        var ongoingVisitors = [];
        var ongoingVisitorsWithMe = [];

        this.servedVisitorSnapshot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').where("visitor_status", "==", "ongoing").where("isChatend", "==", 0).orderBy('updatedon', 'desc').get().then((visitors) => {
            if(visitors.docs.length > 0 && this.agent && this.agent.agentId){
                log("Line 3568", "src/modules/livechat/view/rightSection/index.js", "servedVistorCount", this.agent.agent_name+":"+this.agent.agentId,visitors.docs.length)
            }
            else{
                log("Line 3568", "src/modules/livechat/view/rightSection/index.js", "servedVistorCount","VistorCount")
            }

            visitors.docChanges().forEach((visitor) => {
                var vistSnap = visitor.doc.data();
                vistSnap.id = visitor.doc.id;
                const visitor_Id = visitor.doc.id;
                const vincomingval = vistSnap.incoming;

                // let last_seen1 = vistSnap.last_seen1?.seconds;
                // if(last_seen1){
                //     let away_time = this.online_time - last_seen1;
                //     if(away_time > visitor_time_window){
                //         return
                //     }
                // }else{
                //     return
                // }

                /* for count badges */
                //const agent_read_count = vistSnap[this.agent.agentId] ? vistSnap[this.agent.agentId] : "";
                vistSnap.agent_read_count = 0;
                /* for count badges */

                const client_company_name2 = (vistSnap.client_company_name && (vistSnap.client_name === "" || vistSnap.client_name.indexOf("#Visitor") !== -1)) ? vistSnap.client_company_name : "";
                if (client_company_name2) {
                    vistSnap.client_short = nameIntials(client_company_name2);
                } else {
                    vistSnap.client_short = nameIntials(vistSnap.client_name);
                }

                const premiumuser2 = vistSnap.client_company_name && vistSnap.client_company_name !== 'Appy Pie' ? vistSnap.client_company_name : "";
                if (premiumuser2) {
                    //vistSnap.addedon = eval(vistSnap.addedon) + 259200 * 1000;
                    vistSnap.isPremiumNormal = "premium";
                } else {
                    vistSnap.isPremiumNormal = "normal";
                }

                var client_short_name = vistSnap.client_name;
                if (client_short_name.length > 20) {
                    client_short_name = client_short_name.substring(0, 20) + '...';
                }
                vistSnap.client_short_name = client_short_name;
                vistSnap.timevalue = getTimeFromDate(vistSnap.updatedon);

                if (vistSnap.geoDetails.country_name === "Country not detected") {
                    vistSnap.geoDetails.country_name = "N/A";
                }

                if (vistSnap.geoDetails.country_ip === "Not detected") {
                    vistSnap.geoDetails.country_ip = "N/A";
                }

                vistSnap.fromVisitor = "served";

                //console.log("Type: "+ visitor.type + ", ID : " + vistSnap.id + ", incoming : " + vistSnap.incoming + ", visitor_status : " + vistSnap.visitor_status + ", vistSnap.client_name : " + vistSnap.client_name +", vistSnap.client_company_name : " + vistSnap.client_company_name + ", UpdatedOn : " + moment(vistSnap.updatedon).format("YYYY-MM-DD HH:mm:ss"));
                //console.log("------------   vistSnap  ----------");
                //console.log(vistSnap);

                if (visitor.type === "added") {
                    if (!vistSnap.agent_ids.includes(this.agent.agentId)) {
                        if (vincomingval === 2) {
                            const index = ongoingVisitors.findIndex(x => x.id === visitor_Id);
                            if (index === -1) {
                                ongoingVisitors.splice(0, 0, vistSnap);
                            } else {
                                ongoingVisitors.splice(index, 1, vistSnap);
                            }
                        } else if (vincomingval === 3) {
                            const index = ongoingVisitors.findIndex(x => x.id === visitor_Id);
                            if (index !== -1) {
                                ongoingVisitors.splice(index, 1);
                            }
                        }
                    }
                    if (vincomingval === 2) {
                        const index2 = ongoingVisitorsWithMe.findIndex(x => x.id === visitor_Id);
                        if (index2 === -1) {
                            ongoingVisitorsWithMe.splice(0, 0, vistSnap);
                        } else {
                            ongoingVisitorsWithMe.splice(index2, 1, vistSnap);
                        }
                    } else if (vincomingval === 3) {
                        const index2 = ongoingVisitorsWithMe.findIndex(x => x.id === visitor_Id);
                        if (index2 !== -1) {
                            ongoingVisitorsWithMe.splice(index2, 1);
                        }
                    }
                } else if (visitor.type === "modified") {
                    const index = ongoingVisitors.findIndex(x => x.id === visitor_Id);
                    if (vincomingval === 2) {
                        if (index !== -1 && vistSnap.agent_ids.includes(this.agent.agentId)) {
                            ongoingVisitors.splice(index, 1);
                        } else if (index !== -1 && !vistSnap.agent_ids.includes(this.agent.agentId)) {
                            ongoingVisitors.splice(index, 1, vistSnap);
                        } else {
                            if (index === -1 && !vistSnap.agent_ids.includes(this.agent.agentId)) {
                                ongoingVisitors.splice(0, 0, vistSnap);
                            }
                        }
                        const index2 = ongoingVisitorsWithMe.findIndex(x => x.id === visitor_Id);
                        if (index2 !== -1) {
                            ongoingVisitorsWithMe.splice(index2, 1, vistSnap);
                        }

                    } else if (vincomingval === 3) {
                        if (index !== -1) {
                            ongoingVisitors.splice(index, 1);
                        }
                        const index2 = ongoingVisitorsWithMe.findIndex(x => x.id === visitor_Id);
                        if (index2 !== -1) {
                            ongoingVisitorsWithMe.splice(index2, 1);
                        }
                    }
                } else if (visitor.type === "removed") {
                    const index = ongoingVisitors.findIndex(x => x.id === visitor_Id);
                    if (index !== -1) {
                        ongoingVisitors.splice(index, 1);
                        /* Inactive chat if Agent is offline and chat not gets deactive Start */
                        deleteChatCounter(visitor_Id);
                        /* Inactive chat if Agent is offline and chat not gets deactive End */
                    }
                    const index2 = ongoingVisitorsWithMe.findIndex(x => x.id === visitor_Id);
                    if (index2 !== -1) {
                        ongoingVisitorsWithMe.splice(index2, 1);
                    }
                }
            });

            var ongoingVisitorsChats = ongoingVisitorsWithMe.reduce(function (prev, cur) {
                if (cur.agent_ids.length > 0) {
                    cur.agent_ids.forEach(agentId => {
                        prev[agentId] = (prev[agentId] || 0) + 1;
                    });
                }
                return prev;
            }, {});

            this.setState({ servedVisitors: ongoingVisitors, ongoingVisitorsChats: ongoingVisitorsChats });

            /* Inactive chat if Agent is offline and chat not gets deactive Start */
            this.checkDeactiveChats(ongoingVisitors);
            /* Inactive chat if Agent is offline and chat not gets deactive End */

            var visitorType = this.state.visitorType ? this.state.visitorType : "active";
            if (visitorType === "served") {
                this.mapCountryUrlsDevicesList();
            }
        });
    }

    handleDisplayVisitors = (e) => {
        this.setState({ visitorType: e.target.value, currentPrePage: 0, currentPage: 0 }, () => {
            this.mapCountryUrlsDevicesList();
        });
    }

    /* For Active and served visitors end */

    checkDeactiveChats = async (ongoingVisitors) => {
        var agentTimeZone = localStorage.getItem("agentTimeZone") ? JSON.parse(localStorage.getItem('agentTimeZone')) : false;
        if (Object.keys(this.state.setting).length > 0 && this.state.setting.agent_reply_check && agentTimeZone) {
            var clientTime = (new Date()).getTime();
            let currentTime;
            if (agentTimeZone.type === "plus") {
                currentTime = clientTime + parseInt(agentTimeZone.difftime);
            } else {
                currentTime = clientTime - parseInt(agentTimeZone.difftime);
            }
            const triggerWaitTime = parseInt((this.state.setting.agent_reply_min + 2) * 60 * 1000); // In Miliseconds
            for (const k in ongoingVisitors) {
                const chatData = ongoingVisitors[k];
                const chatId = chatData.id;
                const agent_end = chatData.agent_end;
                // const visitor_timezone = chatData.visitor_timezone;
                const updatedon = chatData.updatedon;
                //let currTime = parseInt(moment().tz(visitor_timezone).tz("UTC").format("x"));
                const checkMinutes = currentTime - updatedon;
                if (checkMinutes >= triggerWaitTime) {

                    let isAgentOnline = false;
                    chatData.agent_ids.forEach(agntID => {
                        if (this.state.allOnlineAgentsIdes.indexOf(agntID) !== -1) {
                            isAgentOnline = true;
                        }
                    });

                    const counterFromLocalStorage = await getChatCounter(chatId);
                    if (counterFromLocalStorage) {
                        if (counterFromLocalStorage >= 5) {
                            deleteChatCounter(chatId);
                        } else {
                            updateChatCounter(chatId, counterFromLocalStorage);
                        }
                    }

                    if (agent_end !== 'VISITOR_END_CHAT' && !counterFromLocalStorage && !isAgentOnline) {
                        const increment22 = firebase1.firestore.FieldValue.increment(1);
                        insertChatCounter(chatId, 1);
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatId).collection("chat_data").orderBy("addedon", "desc").limit(1).get().then(result => {
                            if (result.size) {
                                result.forEach(async (doc) => {
                                    const _data = doc.data();
                                    const addedon = _data.addedon;
                                    //let serverTimestamp = parseInt(moment().tz(visitor_timezone).tz("UTC").format("x"));
                                    const diff = currentTime - addedon;
                                    if (diff >= triggerWaitTime) {
                                        const chatStatus = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(chatId);
                                        firebaseServices.db.runTransaction(async (transaction) => {
                                            return transaction.get(chatStatus).then(async (sfDoc) => {
                                                if (!sfDoc.exists) {
                                                    return "NO_CHAT_EXIST";
                                                }
                                                var agentEnd = sfDoc.data().agent_end;
                                                var agentIds = sfDoc.data().agent_ids;
                                                var picktime = sfDoc.data().picktime;

                                                if (agentEnd !== 'VISITOR_END_CHAT') {
                                                    const serverTimestamp2 = await getDateInUTC();
                                                    console.log("chat ended due to inactivity")
                                                    transaction.update(chatStatus, { isChatend: 1, agent_end: "VISITOR_END_CHAT", updatedon: serverTimestamp2, completedon: serverTimestamp2, message_to_show: 'Chat ended due to inactivity' });
                                                    return { status: "DONE", agentIds: agentIds, picktime: picktime };
                                                } else {
                                                    transaction.update(chatStatus, { agent_end: agentEnd });
                                                    return { status: "NOT_DONE", agentIds: agentIds, picktime: picktime };
                                                }
                                            });
                                        }).then(async (resData) => {
                                            if (resData.status === "DONE") {
                                                deleteChatCounter(chatId);
                                                const serverTimestamp3 = await getDateInUTC();
                                                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatId).collection('chat_data').doc("LIVE_CHAT_END_INTIMATION_" + serverTimestamp3).set({
                                                    action: "EndChat1",
                                                    addedon: serverTimestamp3,
                                                    agent_id: 'AGENT_EVENT_TRIGGER',
                                                    agentId: this.agent.agentId,
                                                    agent_profile_img: "",
                                                    msg_type: 6,
                                                    end_from: "SERVED_VISITOR",
                                                    msg: 'Chat ended due to Inactivity',
                                                });

                                                /* Update agent data start */

                                                resData.agentIds.forEach(agent_ID => {
                                                    const agentRefnce = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("agents").doc(agent_ID);
                                                    agentRefnce.get().then(async (doc2) => {
                                                        if (doc2.exists) {
                                                            const _data = doc2.data();
                                                            const avg_duration22 = _data.avg_chat_duration ? _data.avg_chat_duration : 0;
                                                            const chat_closed22 = _data.chat_closed ? _data.chat_closed : 0;
                                                            const chat_completed22 = _data.chat_completed ? _data.chat_completed : 0;
                                                            var new_duration = serverTimestamp3 - resData.picktime;
                                                            new_duration = Math.ceil(new_duration / 1000);
                                                            if (avg_duration22 > 0) {
                                                                var total = (avg_duration22 * chat_closed22) + new_duration;
                                                                var total_closed = eval(chat_closed22) + 1;
                                                                var new_avg = total / total_closed;
                                                                new_duration = Math.ceil(new_avg);
                                                            }
                                                            if (chat_completed22 === 0) {
                                                                agentRefnce.update({ chat_closed: increment22, avg_chat_duration: new_duration, chat_completed: 1 });
                                                            } else {
                                                                agentRefnce.update({ chat_closed: increment22, avg_chat_duration: new_duration, chat_completed: increment22 });
                                                            }
                                                        }
                                                    });
                                                });
                                                /* Update agent data end  */

                                                /* Send Data to algolia */
                                                var body = [`ownerId=${this.agent.ownerId}&visitorId=${chatId}`];
                                                //fetch(MAIL_FUNCTION_URL + "/app/sendchat", {
                                                fetch(GATEWAY_URL + "/sendchat", {
                                                    method: "post",
                                                    body: body,
                                                    headers: {
                                                        "X-API-Key": FUNCTION_API_KEY,
                                                        "Content-Type": "application/x-www-form-urlencoded",
                                                        "Accept": "application/json",
                                                        "Origin": window.location.origin
                                                    }
                                                }).then(res => res.json())
                                                    .then(response => { })
                                                    .catch(e => { });

                                                /* Send Data to algolia  */
                                            }
                                        }).catch(err => {
                                            console.log("---------TRANS--err---------");
                                            console.log(err);
                                        });
                                    }
                                });
                            }
                        }).catch((err) => {
                            return console.log(err);
                        });
                    }
                }
            }
        }
    }

    //* Dynamic height of Right panel start */

    resizeRightPanel = () => {
        try {
            var windowHeight = document.documentElement.clientHeight;
            var heightToDecrease = 249; /////   217 = Search Bar + headings Pre, Normal visitors
            var right_premium_ul = document.getElementById('right_premium_ul').offsetHeight;
            var remainHeight = eval(windowHeight) - heightToDecrease;
            var totminusprem = eval(remainHeight) - eval(right_premium_ul) - 20;
            totminusprem = totminusprem + "px";
            document.getElementById('right_normal_ul').style.height = totminusprem;
        } catch (error) { }

        try {
            if (this.totalDispPremVisitor === 0) {
                console.log(this.totalDispNormVisitor, "this.totalDispNormVisitor")
                document.getElementById('right_normal_ul').style.removeProperty("height");
                document.getElementById('right_normal_ul').classList.add('newNormalVissHeight');
                document.getElementById('right_normal_ul').classList.remove('newPreVisHeight');
            } else {
                document.getElementById('right_normal_ul').classList.add('newPreVisHeight');
                document.getElementById('right_normal_ul').classList.remove('newNormalVissHeight');
            }
        } catch (error) { }

        try {
            if (this.totalDispNormVisitor === 0) {

                document.getElementById('right_premium_ul').style.removeProperty("height");
                document.getElementById('right_premium_ul').classList.add('newNormalVissHeight');
                document.getElementById('right_premium_ul').classList.remove('newPremiumHeight');
            } else {
                document.getElementById('right_premium_ul').classList.add('newPremiumHeight');
                document.getElementById('right_premium_ul').classList.remove('newNormalVissHeight');
            }
        } catch (error) { }
    }

    /* Dialer Functions Start  */

    handlePhoneFocus = (event) => {
        if (event.target.id === "rig_phone_number") {
            try {
                document.getElementById("backspace_number").style.display = "block";
                document.getElementById("backspace_sms").style.display = "none";
            } catch (err) { }
        } else {
            try {
                document.getElementById("backspace_number").style.display = "none";
                document.getElementById("backspace_sms").style.display = "block";
            } catch (err) { }
        }
    }

    handleKeyDown = (event) => {
        const keyCodes = [8, 86, 91, 107];
        if (event.shiftKey && event.which === 187) {
            // Do nothing
        } else if (keyCodes.indexOf(event.which) === -1 && (event.which <= 47 || event.which >= 58)) {
            event.preventDefault();
        }
    }

    handTextChange = (value, country, e, formattedValue) => {
        let txtValue = document.getElementById("rig_phone_number").value;
        txtValue = txtValue.replace(/([a-zA-Z%,&@ ])/g, "");
        txtValue = txtValue.replace(/[`~!@$%^&()_|\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        this.setState({ contactnumber: txtValue });
        document.getElementById("rig_phone_number").value = txtValue;
    }

    showDialer = () => {
        let newStatus = !this.state.showDialer ? true : false;
        this.setState({ showDialer: newStatus, showAgents: false }, () => {
            try {
                document.getElementById("shadowMasking2").style.display = "none";
            } catch (err) { }
        });
    }

    hideDialer = () => {
        this.setState({ showDialer: false }, () => {
            try {
                document.getElementById("shadowMasking2").style.display = "none";
                document.getElementById("chat-visitore-nav").style.zIndex = "99";
                document.getElementById('onlineAgentsRightIcon').classList.remove("active");
                document.getElementById('new-Main-Chat').classList.remove('VRP3');
            } catch (err) { }
        });
    }

    dialNumber = async (val, event) => {
        if (!this.canCalling) {
            return false;
        }
        var prevValue = document.getElementById("rig_phone_number").value;
        if (event.target.classList.contains('forDetectPlus') && (val === 0 || val === "0")) {
            return false;
        }
        if (val === "delete") {
            event.preventDefault();
            prevValue = prevValue.slice(0, -1);
            this.setState({ contactnumber: prevValue });
            document.getElementById("rig_phone_number").value = prevValue;
        } else if (val === "clear") {
            this.setState({ contactnumber: "" });
            document.getElementById("rig_phone_number").value = "";
        } else if (val === "call") {
            var phonenumber = prevValue.trim();
            var isValid = this.isAValidPhoneNumber(phonenumber);
            if (phonenumber && isValid && this.agent.phone_number) {
                this.setState({ showDialer: false });
                try {
                    //document.getElementById("rig_phone_number").style.border = '1px solid #000';
                } catch (errr) { }
                let cChatID = await startCall(phonenumber, this.agent, 'sidebar');
                this.context.updateGlobalData("callChatId", cChatID);
            } else {
                try {
                    //document.getElementById("rig_phone_number").style.border = '1px solid red';
                } catch (errr) { }
                return false;
            }
        } else if (val === "hangup") {
            try {
                document.getElementById("rig_phone_number").value = "";
            } catch (err) { }
            this.setState({ contactnumber: prevValue });
            endCall();
        } else if (val === "deletesms") {
            event.preventDefault();
            var prevSmsValue = document.getElementById("rig_txt_Sms").value;
            prevSmsValue = prevSmsValue.slice(0, -1);
            document.getElementById("rig_txt_Sms").value = prevSmsValue;
        } else {
            this.setState({ contactnumber: prevValue + val });
            document.getElementById("rig_phone_number").value = prevValue + val;
            dialExtension(val);
        }
        return false;
    }

    isAValidPhoneNumber = (inputtxt) => {
        let phoneno = /^\(?([0-9,+]{1,2})\)?[-. ]?([0-9]{2,3})[-. ]?([0-9]{3,4})[-. ]?([0-9]{5,7})$/;
        if (inputtxt.match(phoneno)) {
            return true;
        } else {
            return false;
        }
    }

    isValidPhoneNumber = (value) => {
        var inputtxt = "+" + value;
        let phoneno = /^\(?([0-9,+]{1,2})\)?[-. ]?([0-9]{2,3})[-. ]?([0-9]{3,4})[-. ]?([0-9]{5,7})$/;
        if (inputtxt.match(phoneno)) {
            return true;
        } else {
            return false;
        }
    }

    showHideSmsArea = () => {
        if (!this.canCalling) {
            return false;
        }
        let phoneNumber = document.getElementById("rig_phone_number").value;
        var isValid = this.isAValidPhoneNumber(phoneNumber);
        if (!isValid) {
            try {
                //document.getElementById("rig_phone_number").style.border = '1px solid red';
            } catch (errr) { }
            return false;
        }
        this.setState({ showDialer: false });
        try {
            //document.getElementById("rig_phone_number").style.border = '1px solid #000';;
        } catch (errr) { }

        var tmpChatId = "TEMP" + Date.now();

        var visitor = {
            IPv4: "",
            id: tmpChatId,
            agent_end: "NEW_CHAT",
            bg_color: "visitorcolor-bg-2",
            browser: "",
            client_company_name: "",
            client_id: tmpChatId,
            client_name: phoneNumber,
            client_short_name: phoneNumber,
            client_short: "##",
            client_phone: phoneNumber,
            agent_ids: [],
            client_status: "online",
            country: "IN",
            current_pageUrl: "",
            geoDetails: {
                country_code: "IN",
                country_ip: "",
                country_name: "India"
            },
            deviceUsed: "",
            incoming: 2,
            isChatend: 0,
            message_to_show: "",
            operating: "",
            start_url: "outgoing_sms",
            status: 1,
            visitor_email: "VISITOR" + Date.now(),
            visitor_status: "ongoing",
            fromVisitor: "temp_send_sms_window"
        }

        let key = visitor.id;
        let country = visitor.country;
        let browser = visitor.browser;
        let operating = visitor.operating;

        visitor.agent_read_count = 0;

        if (this.context.isMobileDevice) {
            try {
                document.getElementById('SideBarIO').classList.remove('IOLP');
                document.getElementById('new-Main-Chat').classList.remove('VRP');
                document.getElementById('new-Main-Chat').classList.remove('VRP2');
                document.getElementById('new-Main-Chat').classList.remove('VRP3');
            } catch (err) { }
        }

        if (this.context.liveChatDocId === key) {
            return;
        }

        try {
            setCurrentChat(null);
        } catch (errrr) { }

        var arrayToSet = [];

        if (visitor.client_email === "") {
            visitor.client_email = visitor.visitor_email;
        }

        if (!validateEmail(visitor.client_email)) {
            visitor.client_email = ENTER_EMAIL;
        }

        if (!validateEmail(visitor.visitor_email)) {
            visitor.visitor_email = ENTER_EMAIL;
        }

        var Obj1 = {
            key: "currentVisitor",
            value: visitor
        }

        var Obj2 = {
            key: "midHeadercountry",
            value: country
        }

        var Obj3 = {
            key: "midHeaderbrowser",
            value: browser
        }

        var Obj4 = {
            key: "midHeaderoperating",
            value: operating
        }

        var Obj5 = {
            key: "chatPickStatusTransaction",
            value: { key: key, status: "chatting", Text: "Send SMS", msgToShow: null }
        }

        var Obj6 = {
            key: "showMidHeader",
            value: true
        }

        var Obj7 = {
            key: "liveChatDocId",
            value: key
        }

        arrayToSet.push(Obj1);
        arrayToSet.push(Obj2);
        arrayToSet.push(Obj3);
        arrayToSet.push(Obj4);
        arrayToSet.push(Obj5);
        arrayToSet.push(Obj6);
        arrayToSet.push(Obj7);
        this.context.updateGlobalData("array", arrayToSet);
    }

    startVoipCall = async (selectedAgent) => {
        if (!this.canCalling) {
            return false;
        }
        this.closeOnlineAgents();
        let cChatID = await startVoipCall(selectedAgent.key, this.agent, 'agent', selectedAgent);
        this.context.updateGlobalData("callChatId", cChatID);
    }

    startChatWithAgent = (selectedAgent) => {
        this.closeOnlineAgents();
        var client_short_name = selectedAgent.agent_name; ///this.agent.agent_name;
        if (client_short_name.length > 20) {
            client_short_name = client_short_name.substring(0, 20) + '...';
        }
        var tmpChatId = "TEMP" + Date.now();
        var visitor = {
            id: tmpChatId,
            agent_end: "NEW_CHAT",
            bg_color: "visitorcolor-bg-2",
            browser: "",
            client_company_name: "",
            client_id: tmpChatId,
            client_name: selectedAgent.agent_name, ///this.agent.agent_name,
            client_short_name: client_short_name,
            client_short: nameIntials(selectedAgent.agent_name),
            client_email: selectedAgent.email,
            client_phone: selectedAgent.phone_number,
            agent_ids: [selectedAgent.key],
            client_status: "online",
            country: "IN",
            current_pageUrl: "",
            geoDetails: {
                country_code: "IN",
                country_ip: "",
                country_name: "India"
            },
            deviceUsed: "",
            incoming: 2,
            isChatend: 0,
            message_to_show: "",
            operating: "",
            start_url: "voip_call",
            status: 1,
            visitor_email: "VISITOR" + Date.now(),
            visitor_status: "ongoing",
            fromVisitor: "temp_send_chat_window",
            agent_short: selectedAgent.agent_short,
            alias: selectedAgent.alias,
            profile_img: selectedAgent.profile_img
        }

        let key = visitor.id;
        let country = visitor.country;
        let browser = visitor.browser;
        let operating = visitor.operating;

        visitor.agent_read_count = 0;

        if (this.context.isMobileDevice) {
            try {
                document.getElementById('SideBarIO').classList.remove('IOLP');
                document.getElementById('new-Main-Chat').classList.remove('VRP');
                document.getElementById('new-Main-Chat').classList.remove('VRP2');
            } catch (err) { }
        }

        if (this.context.liveChatDocId === key) {
            return;
        }

        try {
            setCurrentChat(null);
        } catch (errrr) { }

        var arrayToSet = [];

        if (visitor.client_email === "") {
            visitor.client_email = visitor.visitor_email;
        }

        if (!validateEmail(visitor.client_email)) {
            visitor.client_email = ENTER_EMAIL;
        }

        if (!validateEmail(visitor.visitor_email)) {
            visitor.visitor_email = ENTER_EMAIL;
        }

        var Obj1 = {
            key: "currentVisitor",
            value: visitor
        }

        var Obj2 = {
            key: "midHeadercountry",
            value: country
        }

        var Obj3 = {
            key: "midHeaderbrowser",
            value: browser
        }

        var Obj4 = {
            key: "midHeaderoperating",
            value: operating
        }

        var Obj5 = {
            key: "chatPickStatusTransaction",
            value: { key: key, status: "start", Text: "Start Chat", msgToShow: null }   // { key: key, status: "chatting", Text: "Send Chat", msgToShow: null }
        }

        var Obj6 = {
            key: "showMidHeader",
            value: true
        }

        var Obj7 = {
            key: "liveChatDocId",
            value: key
        }

        arrayToSet.push(Obj1);
        arrayToSet.push(Obj2);
        arrayToSet.push(Obj3);
        arrayToSet.push(Obj4);
        arrayToSet.push(Obj5);
        arrayToSet.push(Obj6);
        arrayToSet.push(Obj7);
        this.context.updateGlobalData("array", arrayToSet);
    }

    /* Dialer Functions End  */

    /* WhatsApp Functions start */

    closeWhatsAppTemplates = () => {
        this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
    }

    getWhatsAppTemplates = () => {
        if (this.agent.whatsapp_msg) {
            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('whatsapp_templates').where("status", "==", 1).get().then((templates) => {
                var templatesData = [];
                templates.forEach((template) => {
                    var tempData = template.data();
                    tempData.id = template.id;
                    var tmpArray = [];
                    for (var cntnt in tempData.content) {
                        let tmpcntn = tempData.content[cntnt];
                        tmpArray.push({ lang: cntnt, message: tmpcntn });

                    }
                    tempData.contentArray = tmpArray;
                    templatesData.push(tempData);
                });
                this.setState({ whatsappTemplates: templatesData });
            });
        }
    }

    selectTemplate = (templateData) => {
        this.context.updateGlobalData("whatsappmsg", templateData.message);
        if (this.context.isMobileDevice) {
            this.closeWhatsAppTemplates();
        }
    }

    showHideTemplate = (event, tId) => {
        event.preventDefault();
        try {
            let ckkVisible = document.getElementById("templatecontent_" + tId).style.display;

            if (ckkVisible === "none") {
                document.getElementById("downToggleIcon_" + tId).src = require('../../../../assets/images/livechat/down.svg');
            } else {
                document.getElementById("downToggleIcon_" + tId).src = require('../../../../assets/images/livechat/left.svg');
            }

            $(".donotrmv2").not("#templatecontent_" + tId).slideUp();
            $("#templatecontent_" + tId).slideToggle();
        } catch (errr) { }
    }

    /* WhatsApp Functions end */

    /* Unmount all mounted data start */

    componentWillUnmount = () => {
        try {
            this.onlineAgentsSnapshot();
            this.newVisitorsSnapshot();
            this.servedVisitorSnapshot();
        } catch (errr) { }
    }

    /* Unmount all mounted data end */

    updateLaunchedLiveChat = () => {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails === false) {
            return;
        }
        firebaseServices.db.collection("users").doc(userDetails.ownerId).update({
            havelaunchedLiveChat: true
        });
    }

    launchLivechat = () => {
        var arrayToSet = [];
        var Obj1 = {
            key: "launchlivechatclicked",
            value: true
        }
        arrayToSet.push(Obj1);
        var Obj2 = {
            key: "launchedLiveChat",
            value: true
        }
        arrayToSet.push(Obj2);
        this.context.updateGlobalData("array", arrayToSet);
        this.updateLaunchedLiveChat();
        let organization_alias = this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire;
        let win = window.open("/" + organization_alias + "/test-drive?widget_type=drive", '_blank');
        win.focus();
        if (this.context.livechat_guide === false && this.context.livechat_step === 0) {
            this.guidenextStep('livechat', 1, 'livechat_step');
        }
    }

    endGuideTour = (docId, context) => {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails === false) {
            return;
        }
        firebaseServices.db.collection("users").doc(userDetails.ownerId).collection("onboarding_guide").doc(docId).update({
            status: true
        })
        this.context.updateGlobalData(context, true);
    }

    guidenextStep = (docId, step, context) => {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails === false) {
            return;
        }
        firebaseServices.db.collection("users").doc(userDetails.ownerId).collection("onboarding_guide").doc(docId).update({
            step: step,
        })
        this.context.updateGlobalData(context, step);
    }

    openInstallWidget = () => {
        this.props.props.history.push("/" + this.agent.ownerIdentifire + "/install-widget");
    }

    validateForm = (e) => {
        let returnValue = true;
        if (this.state.subjectTicket.trim() === '') {
            returnValue = false;
            this.setState({
                subjectTicket_error: 'This field can not be blank!'
            })
        }
        // if (this.state.assignedTo.trim() === '') {
        //     returnValue = false;
        //     this.setState({
        //         assignedTo_error: 'Please select Assigned To!'
        //     })
        // }
        if (this.state.priorityStatus === '') {
            returnValue = false;
            this.setState({
                priorityStatus_error: 'Please select Priority!'
            })
        }
        if (this.state.statusTicket === '') {
            returnValue = false;
            this.setState({
                statusTicket_error: 'Please select Status!'
            })
        }
        if (this.state.descriptionTicket.trim() === '') {
            returnValue = false;
            this.setState({
                descriptionTicket_error: 'This field can not be blank!'
            })
        }
        if ((this.context.currentVisitor.client_email === '' || this.context.currentVisitor.client_email === 'noreply@appypie.com') && !this.state.isAutoTicketing) {
            returnValue = false;
            this.setState({
                clientEmail_error: 'Client email is missing/invalid in the header!'
            })
        }
        return returnValue;
    }

    fetchChatTranscript = () => {
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.context.liveChatDocId).get().then((doc) => {
            if (doc.exists) {
                return doc.data().addedon;
            }
            else {
                return moment().tz("UTC").format("x");
            }
        }).then((addedon) => {
            let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
            // fetch(MAIL_FUNCTION_URL + "/app/createChatTranscript", {
            fetch(GATEWAY_URL + "/createChatTranscript", {
                method: 'POST',
                headers: {
                    'X-API-Key': FUNCTION_API_KEY,
                    'Origin': window.location.origin,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    "ownerId": this.agent.ownerId,
                    "visitorId": this.context.liveChatDocId,
                    "addedon": addedon,
                    "client_name": this.state.clientName,
                    "organization_alias": userDetails.organization_alias ? userDetails.organization_alias : 'temp'
                })
            }).then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        let transcriptURL = result.path ? result.path : `https://storage.googleapis.com/${STORAGE_BUCKET}/${userDetails.organization_alias}/transcript_desk/chat_${this.context.liveChatDocId}.txt`;
                        this.setState({ transcriptURL: transcriptURL })
                    } else {
                        this.setState({ transcriptURL: `https://storage.googleapis.com/${STORAGE_BUCKET}/${userDetails.organization_alias}/transcript_desk/chat_${this.context.liveChatDocId}.txt` })
                    }
                })
                .catch(error => console.log('error', error));
        }).catch(error => console.log('error', error));
    }

    createTicket = async (event) => {

        event.preventDefault();
        await this.fetchChatTranscript();
        var validate = await this.validateForm();
        if (!validate) {
            return false;
        }
        else {
            this.setState({ createTicketLoader: true });
            // todo clear form on submission
            let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
            // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdfaWQiOiJGRVZ2cnVlZ2JoUERyd2VJMXdlaiIsInVzZXJfaWQiOiJMRG13YzlUT3hDUDVnM1kxdzNSdyIsInBhcmVudF9pZCI6IkxEbXdjOVRPeENQNWczWTF3M1J3IiwiaWQiOjIyMTUsImZpcnN0bmFtZSI6InJzYW5lIiwibGFzdG5hbWUiOiIiLCJlbWFpbCI6InJzYW5lQGFwcHlwaWVsbHAuY29tIiwicm9sZV90eXBlIjoiYWRtaW4iLCJpc190aWNrZXRfYXBwIjp0cnVlLCJkb21haW5yZWdpc3RlciI6dHJ1ZSwiZG9tYWlubmFtZSI6InJzYW5lIiwiY29tcGFueWlkZW50aWZpZXIiOiJyc2FuZSIsInBvcnRhbG5hbWUiOiJJbnNhbmUiLCJpc19rYl9hcHAiOmZhbHNlLCJjdXN0b21lX2RvbWFpbiI6IiIsImlhdCI6MTY1ODQ4ODk4OCwiZXhwIjoxNjkwMDQ1OTE0fQ.4OHAqBgkdMqV4r7ftL9_0-liwm93Tpq0AFV7kbOqJfg";

            let clientNameSplit = this.state.clientName.split(' ');
            let assignedToNameSplit = this.state.assignedToName.split(' ');
            let serverTime = moment().tz("UTC").format("x");

            let assignedToAgentData = this.state.allAgentsRealm.filter(agent => agent.preferred_username === this.state.assignedTo);
            // console.log("\n\n\n💡🛠 -> file: index.js -> line 3926 -> assignedToAgentData", assignedToAgentData);

            var body = '';
            if (this.state.assignedToEntity === 'U') {
                body = JSON.stringify({
                    "user_id": this.state.deskUserId,
                    "firstname": assignedToNameSplit[0],
                    "lastname": assignedToNameSplit[1] ? assignedToNameSplit[1] : '',
                    "assign_uid": this.state.assignedTo,
                    "assigneeEntity": {
                        "alias": this.state.assignedToName,
                        "company_identifier": userDetails.organization_alias,
                        "email": assignedToAgentData.length > 0 ? assignedToAgentData[0].email : userDetails.ownerEmail,
                        "email_verified": assignedToAgentData.length > 0 ? assignedToAgentData[0].email_verified : true,
                        "entity": "Agent",
                        "firstname": assignedToNameSplit[0],
                        "imageurl": assignedToAgentData.length > 0 ? assignedToAgentData[0].imageurl : "https://userappypie.s3.amazonaws.com",
                        "lastname": assignedToNameSplit[1] ? assignedToNameSplit[1] : '',
                        "phone_number": assignedToAgentData.length > 0 ? assignedToAgentData[0].phone_number : "notexist",
                        "pic": assignedToAgentData.length > 0 ? assignedToAgentData[0].pic : "notexist",
                        "preferred_username": this.state.assignedTo,
                        "role_type": this.state.assignedToRoleType,
                        "status": this.state.assignedToStatus,
                        "thumbnail": assignedToAgentData.length > 0 ? assignedToAgentData[0].thumbnail : "notexist",
                        "dept_name": null,
                        "timezone": assignedToAgentData.length > 0 ? assignedToAgentData[0].timezone : userDetails.timezone,
                        "user_id": this.state.assignedTo
                    },
                    "attachmentDoc": [{
                        "filename": "chat_" + this.context.liveChatDocId + ".txt",
                        "path": `https://storage.googleapis.com/${STORAGE_BUCKET}/${userDetails.organization_alias}/transcript_desk/chat_${this.context.liveChatDocId}.txt`,
                    }],
                    "body": this.state.descriptionTicket + '<br><br>Please refer to the chat transcript for more information.',
                    "contact_id": this.state.deskContactId,
                    "contact_name": this.state.clientName,
                    "contacts": {
                        "contact_id": this.state.deskContactId,
                        "created_on": {
                            "_seconds": serverTime,
                            "_nanoseconds": 0
                        },
                        "email": this.context.currentVisitor.client_email,
                        "firstname": clientNameSplit[0],
                        "keyword": [],
                        "lastname": clientNameSplit[1] ? clientNameSplit[1] : '',
                        "name": this.state.clientName,
                        "org_id": this.state.deskOrgId,
                        "updated_on": {
                            "_seconds": serverTime,
                            "_nanoseconds": 0
                        },
                    },
                    "dept_id": "",
                    "dept_name": "",
                    "entity_to_asign": this.state.assignedToEntity,
                    "followers": [],
                    "is_reopen": "No",
                    "org_id": this.state.deskOrgId,
                    "priority": this.state.priorityStatus,
                    "source": "chat",
                    "status": this.state.statusTicket,
                    "subject": this.state.subjectTicket,
                    "tags": [],
                    "total_reply": 0
                });
            } else if (this.state.assignedToEntity === 'T') {
                body = JSON.stringify({
                    "user_id": this.state.deskUserId,
                    "firstname": this.state.assignedToName,
                    "lastname": "",
                    "assign_uid": this.state.assignedTo,
                    "assignee_dept": {
                        "dept_id": null,
                        "dept_name": null
                    },
                    "assigneeEntity": {
                        "name": this.state.assignedToName,
                        "status": this.state.assignedToStatus,
                        "team_id": this.state.assignedTo,
                        "entity": "Team"
                    },
                    "attachmentDoc": [{
                        "filename": "chat_" + this.context.liveChatDocId + ".txt",
                        "path": `https://storage.googleapis.com/${STORAGE_BUCKET}/${userDetails.organization_alias}/transcript_desk/chat_${this.context.liveChatDocId}.txt`,
                    }],
                    "body": this.state.descriptionTicket + '<br><br>Please refer to the chat transcript for more information.',
                    "contact_id": this.state.deskContactId,
                    "contact_name": this.state.clientName,
                    "contacts": {
                        "contact_id": this.state.deskContactId,
                        "created_on": {
                            "_seconds": serverTime,
                            "_nanoseconds": 0
                        },
                        "email": this.context.currentVisitor.client_email,
                        "firstname": clientNameSplit[0],
                        "keyword": [],
                        "lastname": clientNameSplit[1] ? clientNameSplit[1] : '',
                        "name": this.state.clientName,
                        "org_id": this.state.deskOrgId,
                        "updated_on": {
                            "_seconds": serverTime,
                            "_nanoseconds": 0
                        },
                    },
                    "dept_id": "",
                    "dept_name": "",
                    "entity_to_asign": this.state.assignedToEntity,
                    "followers": [],
                    "is_reopen": "No",
                    "org_id": this.state.deskOrgId,
                    "priority": this.state.priorityStatus,
                    "source": "chat",
                    "status": this.state.statusTicket,
                    "subject": this.state.subjectTicket,
                    "tags": [],
                    "total_reply": 0
                });
            } else {
                body = JSON.stringify({
                    "user_id": this.state.deskUserId,
                    "firstname": "",
                    "lastname": "",
                    "assign_uid": "",
                    "assignee_dept": {
                        "dept_id": null,
                        "dept_name": null
                    },
                    "assigneeEntity": "",
                    "attachmentDoc": [{
                        "filename": "chat_" + this.context.liveChatDocId + ".txt",
                        "path": `https://storage.googleapis.com/${STORAGE_BUCKET}/${userDetails.organization_alias}/transcript_desk/chat_${this.context.liveChatDocId}.txt`,
                    }],
                    "body": this.state.descriptionTicket + '<br><br>Please refer to the chat transcript for more information.',
                    "contact_id": this.state.deskContactId,
                    "contact_name": this.state.clientName,
                    "contacts": {
                        "contact_id": this.state.deskContactId,
                        "created_on": {
                            "_seconds": serverTime,
                            "_nanoseconds": 0
                        },
                        "email": this.context.currentVisitor.client_email,
                        "firstname": clientNameSplit[0],
                        "keyword": [],
                        "lastname": clientNameSplit[1] ? clientNameSplit[1] : '',
                        "name": this.state.clientName,
                        "org_id": this.state.deskOrgId,
                        "updated_on": {
                            "_seconds": serverTime,
                            "_nanoseconds": 0
                        },
                    },
                    "dept_id": "",
                    "dept_name": "",
                    "entity_to_asign": "O",
                    "followers": [],
                    "is_reopen": "No",
                    "org_id": this.state.deskOrgId,
                    "priority": this.state.priorityStatus,
                    "source": "chat",
                    "status": this.state.statusTicket,
                    "subject": this.state.subjectTicket,
                    "tags": [],
                    "total_reply": 0
                });
            }

            // console.log("--------> file: index.js -> line 3730 -> body <------------");
            // console.log(body);

            try {
                fetch(DESK_API_URL + '/openTicketApi/api/createTickets', {
                    method: 'post',
                    headers: {
                        // 'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Origin': window.location.origin
                    },
                    body: body
                })
                    .then(response => response.json())
                    .then(response => {
                        // console.log(response);
                        this.setState({ createTicketLoader: false });
                        if (response.success) {
                            toast.success("Ticket Created successfully!");
                            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.context.liveChatDocId).update({
                                ticketResponse: response.results,
                                client_email: this.context.currentVisitor.client_email
                            }).catch(err => console.log('error while storing ticket key in DB -> ', err));
                            let priorityDiv = document.getElementById(`priorityStatus_${this.state.priorityStatus}`);
                            priorityDiv.style.background = "#ffffff";
                            document.getElementById("createTicketForm").reset();
                            // document.getElementById('subjectTicket').value = 'Conversation with ' + this.state.clientName;
                            this.setdefaultValues();
                            this.setState({
                                // subjectTicket: '', // todo
                                // assignedTo: '',
                                // assignedToName: '',
                                // assignedToEntity: '',
                                // assignedToStatus: '',
                                // assignedToRoleType: '',
                                // statusTicket: '',
                                // priorityStatus: '',
                                // descriptionTicket: '',
                                subjectTicket_error: '',
                                assignedTo_error: '',
                                statusTicket_error: '',
                                priorityStatus_error: '',
                                descriptionTicket_error: '',
                                clientEmail_error: '',
                                transcriptURL: ''
                            });
                            this.chatText = '';
                            this.chatOrgArray = '';
                            this.closeTicketing();
                            // this.transcriptLink = '';
                        }
                        else {
                            toast.error("Something went wrong! Please try again after some time");
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        toast.error("Something went wrong! Please try again after some time");
                    });
            } catch (err) {
                console.log('error', err);
                toast.error("Something went wrong! Please try again after some time");
            }
        }
    }

    addAssignedTo = () => {
        this.setState((prevState) => ({ showAssignedToMenu: !prevState.showAssignedToMenu }));
    }

    filterAgents = (e) => {
        if (e.target.value) {
            let filteredDeskAgents = this.state.deskAgents.filter(agent => agent.firstname.toLowerCase().includes(e.target.value.toLowerCase()) || agent.lastname.toLowerCase().includes(e.target.value.toLowerCase()));
            this.setState({ filteredDeskAgents: filteredDeskAgents });
        } else {
            this.setState(prevState => ({ filteredDeskAgents: prevState.deskAgents }));
        }
    }

    filterTeams = (e) => {
        if (e.target.value) {
            let filteredDeskTeams = this.state.deskTeams.filter(team => team.name.toLowerCase().includes(e.target.value.toLowerCase()));
            this.setState({ filteredDeskTeams: filteredDeskTeams });
        } else {
            this.setState(prevState => ({ filteredDeskTeams: prevState.deskTeams }));
        }
    }

    createFile = async () => {
        let joiningText = '\n\n------------------------------------------------------------------\nPlease find the chat transcript below:-\n\n';
        if (this.chatText === '') {
            this.chatText = await this.getChat();
        }
        if (!this.state.descriptionTicket.includes(this.chatText)) {
            this.setState((prevState) => ({ descriptionTicket: prevState.descriptionTicket + joiningText + this.chatText }))
        }
        // var data = new Blob([chatText], { type: "text/plain" });
        // this.transcriptLink = window.URL.createObjectURL(data);
        // console.log("\n\n\n💡🛠 -> file: index.js -> line 4087 -> this.transcriptLink", this.transcriptLink);
        // document.getElementById("downloadlink").href = this.transcriptLink;
    };

    getChat = () => {
        if (!this.context.liveChatDocId) {
            return null;
        }
        ///////////// To getting chat messages Start /////////////
        return firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.context.liveChatDocId).collection('chat_data').orderBy('addedon', 'asc').get().then(docs => {
            var chatArray = [];
            for (const index in docs.docs) {
                const change = docs.docs[index];
                const docData = change.data();
                chatArray.push(docData);
            }
            this.chatOrgArray = chatArray;
        }).then(() => {
            return this.createTranscript()
        }).catch(err => { console.log('error while getting chat data->', err) });
        ///////////// To getting chat messages End /////////////
    }

    createTranscript = () => {
        return firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.context.liveChatDocId).get().then((doc) => {
            if (doc.exists) {
                return { addedon: doc.data().addedon, name: doc.data().client_name };
            }
        }).then((visitor) => {

            var str = "Chat Started on " + visitor.addedon + "\n";
            this.chatOrgArray.map(j => {
                var tmpDate = Math.ceil(j.addedon / 1000);
                var utcTime = moment.tz(tmpDate, "X", "UTC").tz(moment.tz.guess(true)).format("hh:mm:ss");
                if (j.msg_type === 8) {
                    str = str + `[${utcTime}]: BOT: \n `;
                    j.msg.map(e => {
                        str = str + `Oues: ${e.Q} \n Ans: ${e.A} \n`;
                    });
                } else {
                    if (j.agent_id === null && (j.action === "AGENT_TRANSFER" || j.action === "WELCOME_PAID_VISITOR")) {
                        return;
                    } else {
                        if (j.msg_type === 6 || j.msg_type === 7) {
                            if (j.msg.trim() === 'You ended the chat.') {
                                str += `[${utcTime}]: \t ${visitor.name} ended the chat  \n`;
                            } else {
                                str += `[${utcTime}]: \t ${j.msg} \n`;
                            }
                        } else {
                            let jMsg = j.msg_type === 12 ? removeHtmlTags(j.msg) : j.msg;
                            if (j.agent_id === null) {
                                str += `[${utcTime}]: \t ${visitor.name}: ${jMsg} \n`;
                            } else {
                                str += `[${utcTime}]: \t ${j.agent_name ? j.agent_name : "BOT"}: ${jMsg} \n`;
                            }
                        }
                    }
                }
            });
            return str;
        }).catch(err => { console.log('error while creating chat transcript-->', err) })
    };
    render() {
        // console.log(this.context.picked_visitors,"visitor_id_to_remove",this.visitorData.length);
        if(this.context.picked_visitors){
            const visitor_id = this.context.picked_visitors;
            hidden_visitors.push(visitor_id);
            this.removeVisitorFromList(visitor_id);
            this.context.updateGlobalData("picked_visitors",null);
        }
        var filterBy = "none";
        var showStickynotes = "none";
        var showNoteForm = "none";
        var showAddAgentList = "none";
        var showTransfor = "none";
        var showVisitorPaths = "none";
        var showConfirm = "none";
        var showConfirmLeave = "none";
        var filterList = "none";
        var hideOther = "none";
        var orderByList = "none";
        var simpleList = "block";
        var showWhatsAppTemplates = "none";
        var showTicketing = "none";
        var widgetVerify = this.context.widgetVerify;
        let loginType = this.agent.roleType ? this.agent.roleType : "agent";
        if (!this.state.showAgents && !this.state.showDialer) {
            if (this.context.isCloseBtnClicked === "yes") {
                showConfirm = "block";
                hideOther = "none"
            } else if (this.context.isCloseBtnClicked === "leavenow") {
                showConfirmLeave = "block";
                hideOther = "none";
            } else if (this.context.rightSectionItemToShow === "filterBy") {
                filterBy = "block";
            } else if (this.context.rightSectionItemToShow === "filterList") {
                filterList = "block";
            } else if (this.context.rightSectionItemToShow === "notesList") {
                showStickynotes = "block";
            } else if (this.context.rightSectionItemToShow === "noteForm") {
                showNoteForm = "block";
            } else if (this.context.rightSectionItemToShow === "addAgent") {
                showAddAgentList = "block";
            } else if (this.context.rightSectionItemToShow === "transferAgent") {
                showTransfor = "block";
            } else if (this.context.rightSectionItemToShow === "visitorPath") {
                showVisitorPaths = "block";
            } else if (this.context.rightSectionItemToShow === "whatsapptemplates") {
                showWhatsAppTemplates = "block";
            } else if (this.context.rightSectionItemToShow === "ticketing") {
                showTicketing = "block";
                this.state.clientEmailTicket = this.context.currentVisitor.client_email !== 'noreply@appypie.com' ? this.context.currentVisitor.client_email : '';
                // this.createFile(); // todo remove this code if unnecessary
            } else if (this.context.rightSectionItemToShow === "visitorslist") {
                if (this.state.optionFilterBy !== "none") {
                    orderByList = "block";
                    simpleList = "none";
                    hideOther = "block";
                } else {
                    hideOther = "block";
                }
                if (this.closeVisitorpanel) {
                    hideOther = "none";
                }
            }
        }
        /*
        var backgroundColor = this.context.launchlivechatclicked ? '#eaeaea' : "#0984f6";
        var textColor = this.context.launchlivechatclicked ? '#000' : "#fff";
        backgroundColor = this.state.onButton ? (backgroundColor === '#eaeaea' ? '#0984f6' : backgroundColor) : backgroundColor;
        textColor = this.state.onButton ? (textColor === '#000' ? '#fff' : textColor) : textColor;
        */
        //var incHeight = "1000px";
        /* var incHeight = "500px";
        if (this.state.displayVisitors.length < 10) {
            incHeight = "auto";
        }*/

        var disab = "";
        if (this.context.isDisableIncomingPanel) {
            disab = "none";
        }

        var allAddedAgents = [];
        if (Object.keys(this.context.currentVisitor).length > 0) {
            allAddedAgents = this.context.currentVisitor.agent_ids;
        }

        /* For showing premium or normal visitors start */
        var showPremium = false;
        var showNormal = false;
        if (this.state.displayPreVisitors.length > 0 && this.state.displayVisitors.length > 0) {
            showPremium = true;
            showNormal = true;
        } else if (this.state.displayPreVisitors.length > 0 && this.state.displayVisitors.length === 0) {
            showPremium = true;
        } else if ((this.state.displayPreVisitors.length === 0 && this.state.displayVisitors.length > 0) || (this.state.displayPreVisitors.length === 0 && this.state.displayVisitors.length === 0)) {
            showNormal = true;
        }
        /* For showing premium or normal visitors end  */

        const AddedAgentsList = () => {
            if (this.state.added_agent.length > 0) {
                return (<>
                    <div className="addAgentUpClickCrose">
                        <ul>{this.state.added_agent.map(agent =>
                            <li key={agent.key} id={agent.key}>
                                <span>
                                    <span className="newaddAgentCrose" onClick={() => this.removeAgentList(agent)}>X</span>
                                    {agent.profile_image ? <img alt="" src={agent.profile_img} width="50px" /> : <strong className="newagentimg">{agent.agent_short}</strong>}
                                    <p>{agent.name}</p>
                                </span>
                            </li>)}
                        </ul>
                    </div>
                    <div className="newadd" ><button disabled={this.state.transferBtnText === "Transfering..." ? true : false} id="btnTransferAgent" onClick={() => this.transferAgent()}>{this.state.transferBtnText}</button></div>
                </>);
            } else {
                return null;
            }
        }


        var showOverLay = false;
        if (this.context.visitors_guide === false && this.context.visitors_guide_step < 4 && (this.state.displayVisitors.length > 0 || this.state.displayPreVisitors.length > 0)) {
            showOverLay = true;
        } else if (this.context.livechat_guide === false && this.context.livechat_step === 0 && !widgetVerify) {
            // showOverLay = true;
        } else {
            showOverLay = false;
        }

        // let showOldFreePlan = this.context.showOldFreePlan === true && this.context.noChatServed < 500 ? true : false;
        let showOldFreePlan = true;

        var isShowPaidButtonSec = this.context.showPaywall === true && !this.context.paymentDetails.paymentStatus && showOldFreePlan === false ? true : false;
        const showInstalWidget = isShowPaidButtonSec && this.context.launchedLiveChat && Object.keys(this.context.currentVisitor).length > 0 ? true : false;

        return (<>
            <div id="chat-1-info" className="chat-sidebar">
                <div className="d-flex h-100 flex-column">

                    <div className="border-bottom py-4 py-lg-6">
                        <div className="container-fluid">

                            <ul className="nav justify-content-between align-items-center">

                                <li className="nav-item list-inline-item">
                                    <span className="nav-link text-muted px-0" data-chat-sidebar-close="">
                                        <i className="icon-md fe-chevron-left"></i>
                                    </span>
                                </li>



                                <li className="nav-item list-inline-item">
                                    <div className="dropdown">
                                        <span className="nav-link text-muted px-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="icon-md fe-sliders"></i>
                                        </span>
                                        <div className="dropdown-menu">
                                            <span className="dropdown-item d-flex align-items-center">Mute<span className="ml-auto fe-bell"></span></span>
                                            <span className="dropdown-item d-flex align-items-center">Delete<span className="ml-auto fe-trash-2"></span></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* for confirmation of end chat start */}
            <div style={{ display: showConfirm }} id="endchatwindow" className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="border-bottom py-2 py-lg-5 px-lg-2">
                        <div className="px-3">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>Confirm</h5>
                                </li>
                                <li className="nav-item ml-2 closeinGroup" onClick={() => this.closePanel()}>
                                    <span className="nav-link text-muted px-0">
                                        <img alt="" src={require('../../../../assets/images/livechat/crose.svg')} />
                                    </span>
                                </li>
                            </ul>

                        </div>
                    </div>


                    <div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat">
                        <div className="k-flex align-item-center filter-right-panel">
                            <div className="newconfirmParaSection py-4 px-4">
                                <h5>Do you wish to end chat?</h5>
                                <div className="newconfirmBtbn mt-4">
                                    <button className="newendChatBtn mr-2" onClick={() => this.confirmEndChat()}>End Chat</button>
                                    <button className="newcancelEndChatBtn" onClick={() => this.closePanel()}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for confirmation of end chat End */}

            {/* for confirmation of leaving chat Start */}
            <div style={{ display: showConfirmLeave }} id="leavechatwindow" className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="border-bottom py-2 py-lg-5 px-lg-2">
                        <div className="px-3">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>Confirm</h5>
                                </li>
                                <li className="nav-item ml-2 closeinGroup" onClick={() => this.closePanel()}>
                                    <span className="nav-link text-muted px-0">
                                        <img alt="" src={require('../../../../assets/images/livechat/crose.svg')} />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat">
                        <div className="k-flex align-item-center filter-right-panel">
                            <div className="newconfirmParaSection py-4 px-4">
                                <h5>Do you wish to end chat? You can also leave the chat as well.</h5>
                                <div className="newconfirmBtbn mt-4">
                                    <button className="newendChatBtn mr-2" onClick={() => this.confirmLeaveChat()}>Leave Chat</button>
                                    <button className="newendChatBtn mr-2" onClick={() => this.confirmEndChat()}>End Chat</button>
                                    <button className="newcancelEndChatBtn" onClick={() => this.closePanel()}>Cancel</button>
                                </div>
                                <div>&nbsp;</div>
                                <div className="newconfirmBtbn mt-4 newvisitPathListing">
                                    {this.state.groupChatAgents.length > 1 ? (
                                        <ul>Agents in this group chat :
                                            {this.state.groupChatAgents.map((agent, index) =>
                                                <li key={index}>{agent.name}</li>
                                            )}
                                        </ul>
                                    ) : (null)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for confirmation of leaving chat End */}

            {/* for showing Visitor path Start */}
            <div style={{ display: showVisitorPaths }} id="visitorpaths" className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="border-bottom py-2 py-lg-5 px-lg-2">
                        <div className="px-3">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>Visitor Path</h5>
                                </li>
                                <li className="nav-item ml-2 closeinGroup" onClick={() => this.closeVisitorPaths()}>
                                    <span className="nav-link text-muted px-0">
                                        <img alt="" src={require('../../../../assets/images/livechat/crose.svg')} />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat">
                        <div className="k-flex align-item-center filter-right-panel">
                            <div className="newconfirmParaSection py-4 px-4">
                                <h5>View List</h5>
                                <div className="newconfirmBtbn mt-4 newvisitPathListing">
                                    <ul>
                                        {this.state.pastVisits.map((board, kindex) => <li key={kindex}><p className="card-body-text">{board.URL}</p></li>)}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for showing Visitor path End */}

            {/* for Sticky notes start */}
            <div style={{ display: showStickynotes }} id="stickynotes" className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="border-bottom py-2 py-lg-5 px-lg-2">
                        <div className="px-3">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>Notes</h5>
                                </li>
                                <li className="nav-item ml-2">
                                    <button className="btnBlue px-4 py-2" onClick={() => this.addNote()} style={{ 'lineHeight': '18px', 'background': '#3e4360', 'border': '1px solid #3e4360' }}>
                                        <small>Add Note</small>
                                    </button>
                                </li>
                                <li className="nav-item ml-2 closeinGroup" onClick={() => this.closeNotes('list')}>
                                    <span className="nav-link text-muted px-0">
                                        <img alt="" src={require('../../../../assets/images/livechat/crose.svg')} />
                                    </span>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat">
                        <div className="k-flex align-item-center filter-right-panel">
                            <div className="newconfirmParaSection py-4 px-4">
                                <h5>List</h5>
                                <div className="newconfirmBtbn mt-4">
                                    {this.state.stickyNotesLoader ? (<div className="loader">
                                        <div className="spinner">
                                            <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                            <div className="bounce3"></div>
                                        </div>
                                    </div>) : (<ul>
                                        {this.state.stickyNotes.length === 0 ? <li style={{ listStyle: 'none' }} key="nodata">Notes not available</li> : (null)}
                                        {this.state.stickyNotes.map((board, kindex) => {
                                            var editedOn = board.last_updated ? moment.tz(board.last_updated, "x", "UTC").tz(moment.tz.guess(true)).format("h:mm a Do MMM YYYY") : '';
                                            var editedBy = board.last_updated_agent_name ? "Edited by " + board.last_updated_agent_name + " on " + editedOn : '';
                                            let divId = "note-main-" + board.id;
                                            let divInnerId = "note-inner-" + board.id;
                                            let divEditId = "note-edit-" + board.id;
                                            let divtextId = "note-text-" + board.id;
                                            var noteId = board.id;
                                            var noteVal = this.state.notesMsgs[noteId];
                                            return <li id={divId} key={kindex} className="position-relative mb-4 pb-4">
                                                <div id={divInnerId} className="notesChatNot">
                                                    <div className="k-flex align-items-center">
                                                        <p className="notesDate">{moment.tz(board.addedon, "x", "UTC").tz(moment.tz.guess(true)).format("DD/MM/YYYY")}
                                                            {board.last_updated ? (<span className="position-relative editedBtnTooltip"><span className="btn-edites"> Edited</span><span className="titleShowHover"><span><h6> {editedBy}</h6></span></span></span>) : (null)}</p>
                                                        {this.agent.agentId === board.agent_id ? (<div className="nav-item ml-auto" >
                                                            <span className="text-muted" style={{ cursor: 'pointer' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                <span className="heder-icon-img icon-rightpanel-icon">
                                                                    <img alt="" src={require('../../../../assets/img/icon/table-more-vertical.svg')} />
                                                                </span>
                                                            </span>
                                                            <div className="dropdown-menu dropdown-menu-right px-0 py-2">
                                                                <span className="dropdown-item d-flex align-items-center edit-agent-clik" style={{ cursor: 'pointer' }}
                                                                    onClick={() => this.editNote(board.id)}>
                                                                    Edit
                                                                </span>
                                                                <span className="dropdown-item d-flex align-items-center edit-agent-clik" style={{ cursor: 'pointer' }}
                                                                    onClick={() => this.deleteNote(board.id, board.type)}>
                                                                    Delete
                                                                </span>
                                                            </div>
                                                        </div>) : (null)}

                                                    </div>
                                                    <p className="notesDescrption">{board.notes} </p>
                                                    <p className="notesBy">By {board.agent_name} on chat - {board.clientId}</p>
                                                </div>
                                                <div id={divEditId} style={{ display: 'none' }}>
                                                    <div className="input-group material-input mb-5">
                                                        <TextField className="form-control w-100" minRows={4} id={divtextId} label="Note" variant="outlined" name={divtextId} value={noteVal ? noteVal : ""} onChange={(e) => this.textChangeHandle(e, noteId)} multiline />
                                                    </div>
                                                    {/* <textarea className="w-100P text-notes notTextarea" id={divtextId} rows="4" value={noteVal} onChange={(e) => this.textChangeHandle(e, noteId)}></textarea> */}
                                                    <button className="btnBlueSettings mr-4" onClick={() => this.updateNote(board.id, board.type)}>Update</button>
                                                    <button className="btnWhiteSettings" onClick={() => this.cancelUpdate(board.id)}>Cancel</button>
                                                </div>
                                            </li>
                                        })}
                                    </ul>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ display: showNoteForm }} id="add_stickynotes" className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="border-bottom py-2 py-lg-5 px-lg-2">
                        <div className="px-3">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>Add Notes</h5>
                                </li>
                                <li className="nav-item ml-2 closeinGroup" onClick={() => this.closeNotes('form')}>
                                    <span className="nav-link text-muted px-0">
                                        <img alt="" src={require('../../../../assets/images/livechat/crose.svg')} />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat">
                        <div className="k-flex align-item-center filter-right-panel">
                            <div className="newconfirmParaSection py-4 px-4">
                                <div className="input-group material-input">
                                    <TextField className="form-control w-100" minRows={4} id="notes" label="Note" variant="outlined" name="notes" multiline />
                                </div>
                                {/* <div className="newconfirmBtbn mt-4">
                                    <textarea className="w-100P text-notes notTextarea" id="notes" name="notes" rows="4"></textarea>
                                </div> */}
                                <div className="newconfirmBtbn mt-4">
                                    <button className="btnBlueSettings mr-4" onClick={() => this.saveNote()}>Save</button>
                                    <button className="btnWhiteSettings" onClick={() => this.closeNotes('form')}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for Sticky notes End */}

            {/* To add Agent in a chat Start */}
            <div style={{ display: showAddAgentList }} id="addagentdiv" className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="border-bottom py-2 py-lg-5 px-lg-2">
                        <div className="px-3">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>Add Agent To Chat</h5>
                                </li>
                                <li className="nav-item ml-2 closeinGroup" onClick={() => this.closeAddAgent()}>
                                    <span className="nav-link text-muted px-0">
                                        <img alt="" src={require('../../../../assets/images/livechat/crose.svg')} />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat">
                        <div className="k-flex align-item-center filter-right-panel">
                            <div className="newconfirmParaSection py-4 px-4">
                                <div className="newconfirmBtbn mt-4">
                                    <div className="newselectOptionSerchSearch add-agent-visitore">
                                        <div className="input-group visitore-search pb-3">
                                            <div className="input-group pr-0 user-table-live search-bar material-input mb-5">
                                                <TextField className="form-control" id="srchagenttoadd" label="Search here" variant="outlined" name="srchagenttoadd" defaultValue={this.state.srchagenttoadd} onKeyUp={this.handleChange} />
                                                <div className="input-group-append">
                                                    <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="submit">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                            <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <input type="text" className="form-control form-control-lg" placeholder="Search here" id="srchagenttoadd" onKeyUp={this.searchAgentToAdd} /> 
                                             <div className="input-group-append">
                                                <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="submit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                        <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000" /></svg>
                                                </button>
                                            </div> */}
                                        </div>
                                        <div className="pb-3">
                                            <div className="material-select">
                                                <FormControl variant="outlined" className="material-outlined w-100">
                                                    <InputLabel id="agent_department-select-outlined-label">Team</InputLabel>
                                                    <Select MenuProps={{ disableScrollLock: true }} labelId="agent_department-select-outlined-label" id="agent_department" name="agent_department" value={this.state.agent_department ? this.state.agent_department : "all"} onChange={this.handleDepartmentChange} label="Department">
                                                        <MenuItem value="all">All Teams</MenuItem>
                                                        {this.state.departmentList.map(agenttype =>
                                                            <MenuItem key={agenttype.key} value={agenttype.key}>{agenttype.name}</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {/* <div className="wrapperInfo">
                                                <select className="customArrow" id="agent_department" onChange={this.searchAgentToAdd}>
                                                    <option value="">All agents</option>
                                                    {this.state.departmentList.map(agenttype =>
                                                        <option key={agenttype.key} value={agenttype.key} >{agenttype.name}</option>
                                                    )}
                                                </select>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div id="addagent">
                                        <div className="addAgentUpClickCrose">
                                            <ul>{this.state.added_agent.map(agent =>
                                                <li key={agent.key} id={agent.key}>
                                                    <span>
                                                        <span className="newaddAgentCrose" onClick={() => this.removeAgent(agent)}>X</span>
                                                        {agent.profile_image ? <img alt="" src={agent.profile_img} width="50px" /> : <strong className="newagentimg">{agent.agent_short}</strong>}
                                                        <p>{agent.name}</p>
                                                    </span>
                                                </li>)}
                                            </ul>
                                        </div>
                                        {this.state.added_agent.length > 0 ? (<div className="newadd" ><button disabled={this.state.addAgentBtnText === "Adding..." ? true : false} id="btnAddAgent" onClick={() => this.addAgent()}>{this.state.addAgentBtnText}</button></div>) : ""}
                                    </div>
                                    <p className="titleSDesign"><span>Online Agent</span></p>
                                    <div className="newaddToAgentList scrollChatMessage scroll-2">
                                        <ul>
                                            {this.state.availableAgents.map(agent =>
                                                (allAddedAgents.indexOf(agent.key) === -1) ? (<li key={agent.key} id={agent.key}>
                                                    <span className="main-checkbox-all agent-checkbox">
                                                        <label className="containerCheckBox containerafterchecked">
                                                            <input type="checkbox" name="option1" className="addAgentCheckbox" onClick={() => this.addAgentList(agent)} value={agent.key} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </span>

                                                    <div htmlFor="option1" className="k-flex align-items-center">
                                                        {agent.profile_image ?
                                                            <img alt="" src={agent.profile_img} width="50px" /> :
                                                            <strong className="newagentimg">{agent.agent_short}</strong>
                                                        }
                                                        <div className="newaddToAgentListName">
                                                            <p>{agent.alias}</p>
                                                        </div>
                                                    </div>
                                                </li>) : (null))}
                                            {this.state.availableAgents.length ? '' : 'No online agent found'}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* To add Agent in a chat End */}

            {/* for Transfor chat Start */}
            <div style={{ display: showTransfor }} id="transferagent" className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="border-bottom py-2 py-lg-5 px-lg-2">
                        <div className="px-3">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>Transfer Chat</h5>
                                </li>
                                <li className="nav-item ml-2 closeinGroup" onClick={() => this.closeTransfor()}>
                                    <span className="nav-link text-muted px-0">
                                        <img alt="" src={require('../../../../assets/images/livechat/crose.svg')} />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat">
                        <div className="k-flex align-item-center filter-right-panel">
                            <div className="newconfirmParaSection py-4 px-4">
                                <div className="newconfirmBtbn mt-4">
                                    <div className="newselectOptionSerchSelect">
                                        <span className="selectOption">
                                            {/*<select id="agent_department" onChange={this.searchAgent}>
                                                <option value="0">All agents</option>
                                                {this.state.departmentList.map(agenttype =>
                                                    <option key={agenttype.key} value={agenttype.key} >{agenttype.name}</option>
                                                )}
                                                </select> */}
                                        </span>
                                    </div>
                                    <div className="newselectOptionSerchSearch">
                                        <div className="input-group pr-0 user-table-live search-bar material-input mb-5">
                                            <TextField className="form-control" id="srchagenttotransfer" label="Search here" variant="outlined" name="srchagenttotransfer" defaultValue={this.state.srchagenttotransfer} onKeyUp={this.handleChange} />
                                            <div className="input-group-append">
                                                <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="submit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                        <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        {/* <div className="input-group visitore-search pb-3">
                                            <input type="text" className="form-control form-control-lg" placeholder="Search here" id="srchagenttotransfer" onKeyUp={this.searchAgentToTransfer} />
                                            <div className="input-group-append">
                                                <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="submit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                        <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div id="transferagents">
                                        <AddedAgentsList type="transfer" />
                                    </div>
                                    <p className="titleSDesign"><span>Online Agent</span></p>
                                    <div className="newaddToAgentList scrollChatMessage scroll-2">
                                        <ul>
                                            {this.state.availableAgents.map(agent =>
                                                <li key={agent.key} id={agent.key} onClick={() => this.transferAgentList(agent)}>
                                                    {agent.profile_image ? <img alt="" src={agent.profile_img} width="50px" /> : <strong className="newagentimg">{agent.agent_short}</strong>}
                                                    <div className="newaddToAgentListName">
                                                        <p>{agent.alias}</p>
                                                    </div>
                                                </li>)}
                                            {this.state.availableAgents.length ? '' : 'No online agent found'}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for Transfor chat End */}

            {/* Trying to add visitors com here */}
            <div style={{ display: hideOther }} id="chat1-members" className={showOverLay ? "chat-sidebar position-top" : "chat-sidebar"}>
                {showOverLay ? (<div className="overlay-guide dd"></div>) : (null)}
                <div className="d-flex h-100 flex-column position-relative">
                    <div className="border-bottom py-2 py-lg-5 px-lg-2 visRightHeadeOnly">
                        <div className="px-3">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>
                                        {/* Visitors <span className="visitorsCount">({this.state.visitors.length})</span> */}
                                        <div className="dropdown historey-serch-drp" style={{ float: 'left' }}>
                                            <div className="material-select">
                                                <FormControl variant="outlined" className="material-outlined w-150">
                                                    <InputLabel id="visitorType-select-outlined-label">Visitors</InputLabel>
                                                    <Select MenuProps={{ disableScrollLock: true }} labelId="visitorType-select-outlined-label" id="visitorType" name="visitorType" value={this.state.visitorType} onChange={this.handleDisplayVisitors} label="Visitors">
                                                        <MenuItem value="active">Active <strong className="font-ServedVistor pl-6">{this.state.visitors.length}</strong></MenuItem>
                                                        <MenuItem value="served">Served <strong className="font-ActiveVistor pl-4">{this.state.servedVisitors.length}</strong></MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {/* <span className="dropdown-toggle selctVisitoreRight" data-toggle="dropdown">
                                                {this.state.visitorType === "active" ? (<>Active visitors <strong className="font-ServedVistor">{this.state.visitors.length}</strong></>) : (<> Served visitors <strong className="font-ActiveVistor">{this.state.servedVisitors.length}</strong></>)}
                                            </span>
                                            <div className="dropdown-menu visitoresDropdown py-2 px-0">
                                                <span className="dropdown-item" onClick={() => this.handleDisplayVisitors("active")}>Active visitors <strong className="pl-2">{this.state.visitors.length}</strong></span>
                                                <span className="dropdown-item" onClick={() => this.handleDisplayVisitors("served")}>Served visitors <strong className="pl-2">{this.state.servedVisitors.length}</strong></span>
                                            </div> */}
                                        </div>
                                        {!this.state.refreshVisitors ? (<span className="refresh-icon-visitor pl-3 position-relative cursor-pointer" style={{ top: '4px' }} onClick={() => this.reloadVisitors()}>
                                            <svg id="refresh" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22.062 22.062">
                                                <path id="Path_2080" data-name="Path 2080" d="M2.65,12.048a8.728,8.728,0,0,1-.064-1.017A8.41,8.41,0,0,1,16.7,4.813l-.745.758a.661.661,0,0,0-.168.646.676.676,0,0,0,.491.439c.13.026,4.4,1.4,4.132,1.349a.654.654,0,0,0,.763-.763c-.026-.13-.973-4.878-.918-4.606a.64.64,0,0,0-1.086-.323l-.633.62A11.038,11.038,0,0,0,0,11.031v.026c0,.364.017.646.036.861a.646.646,0,0,0,.517.576l1.328.265A.647.647,0,0,0,2.65,12.048Zm0,0" transform="translate(0 0)" fill="#a8a8a8" />
                                                <path id="Path_2081" data-name="Path 2081" d="M41.076,215.706l-1.332-.271a.647.647,0,0,0-.771.712,8.8,8.8,0,0,1,.067,1.024,8.4,8.4,0,0,1-14.112,6.2l.745-.745a.661.661,0,0,0,.168-.646.676.676,0,0,0-.491-.439c-.13-.026-4.4-1.4-4.132-1.349a.633.633,0,0,0-.582.181.644.644,0,0,0-.181.582l.918,4.563a.647.647,0,0,0,.452.491.621.621,0,0,0,.633-.168l.621-.62a11.014,11.014,0,0,0,18.545-8.053v-.039c0-.286-.01-.568-.034-.847A.646.646,0,0,0,41.076,215.706Zm0,0" transform="translate(-19.564 -206.139)" fill="#a8a8a8" />
                                            </svg>
                                        </span>) : (<span className="pl-3 visiter-refresh-procesing">
                                            <img alt="" src={require('../../../../assets/img/icon/type1.gif')} />
                                        </span>)}
                                    </h5>
                                </li>
                                <li className={this.context.visitors_guide === false && this.context.visitors_guide_step === 1 ? "nav-item ml-2 oneClik1 icon-pos-top" : "nav-item ml-2 oneClik1"}>
                                    <span className="nav-link text-muted px-0" onClick={() => this.showFilterGroupList()}>
                                        <img alt="" src={require('../../../../assets/images/livechat/Page-1.svg')} />
                                    </span>
                                    {this.context.visitors_guide === false && this.context.visitors_guide_step === 1 ? (<div className="tour_guide">
                                        <p className="title">Step 2 of 4</p>
                                        <p className="text-center">With this option you can group your visitors by country and URL.</p>
                                        <button className="btn-next" onClick={() => { this.guidenextStep('visitor', 2, 'visitors_guide_step') }}>Next</button><button className="end-btn" onClick={() => { this.endGuideTour('visitor', 'visitors_guide') }}>End tour</button></div>) : (null)}
                                </li>
                                {this.state.visitorType === "active" ? (<li className={this.context.visitors_guide === false && this.context.visitors_guide_step === 2 ? "nav-item ml-2 oneClik2 icon-pos-top" : "nav-item ml-2 oneClik2"}>
                                    <span className="nav-link text-muted px-0" onClick={() => this.showFilterList()}>
                                        <img alt="" src={require('../../../../assets/images/livechat/filter.svg')} />
                                    </span>
                                    {this.context.visitors_guide === false && this.context.visitors_guide_step === 2 ? (<div className="tour_guide">
                                        <p className="title">Step 3 of 4</p>
                                        <p className="text-center">With this option you can filter your visitors by country and URL.</p><button className="btn-next" onClick={() => { this.guidenextStep('visitor', 3, 'visitors_guide_step') }}>Next</button><button className="end-btn" onClick={() => { this.endGuideTour('visitor', 'visitors_guide') }}>End tour</button></div>) : (null)}
                                </li>) : (null)}
                                <li className="nav-item ml-2 oneClik3">
                                    <div className="dropdown">
                                        <span className="nav-link text-muted px-0" onClick={() => this.hideVisitorsList()}>
                                            <img alt="" src={require('../../../../assets/images/livechat/right-arrow.svg')} />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="newselectOptionSerchSearch searchByTextVisitor" >

                            <div className={this.context.visitors_guide === false && this.context.visitors_guide_step === 3 ? "input-group px-3 py-4 visitore-search icon-pos-top" : "input-group px-3 py-4 visitore-search"}>
                                <div className="input-group material-input">
                                    <TextField className="form-control w-100" value={this.state.searchVisitorText ? this.state.searchVisitorText : ''} id="searchvisitor" label="Search here" variant="outlined" name="searchvisitor" onChange={this.handleSearchChange} onKeyUp={this.searchVisitors} />
                                    <div className="input-group-append">
                                        <button onClick={() => { this.searchVisitors(false) }} className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="button">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                {/* <input type="text" className="form-control form-control-lg" defaultValue={this.state.filterOptions.searchText ? this.state.filterOptions.searchText : ''} placeholder="Search here" id="searchvisitor" onKeyUp={(evt) => { this.searchVisitors(evt) }} /> */}

                                {/* <input type="text" className="form-control form-control-lg" placeholder="Search" aria-label="Search for messages or users..." /> */}

                            </div>
                            {this.context.visitors_guide === false && this.context.visitors_guide_step === 3 ? (<div className="tour_guide">
                                <p className="title">Step 4 of 4</p>
                                <p className="text-center">With this option you can search visitors based on country, page URL or device on which they're present.</p><button className="end-btn" onClick={() => { this.endGuideTour('visitor', 'visitors_guide') }}>End tour</button></div>) : (null)}
                        </div>
                        {(this.state.optionFilterBy !== "none" || this.state.filterOptions.type === "filters") ? (<div className="px-3">
                            <ul className="nav visitore-filter-tag">
                                {this.createFiltersList()}
                            </ul>
                        </div>) : (null)}

                    </div>
                    {widgetVerify || loginType === 'agent' ? (<div className="bg-white">
                        {(this.state.optionFilterBy === "none" && showPremium) ? (<h5 className="py-3 px-5 mt-5">Premium Visitors</h5>) : (null)}
                        {showPremium ? (<div style={{ display: simpleList }} className={this.context.visitors_guide === false && this.context.visitors_guide_step === 0 ? "flex-fill information listing-right-panel premium-visitor" : "flex-fill information listing-right-panel"}>
                            {this.context.visitors_guide === false && this.context.visitors_guide_step === 0 ? (<div className="tour_guide">
                                <p className="title">Step 1 of 4</p>
                                <p className="text-center">This is a visitor who is currently present on your website.</p>
                                <button className="btn-next" onClick={() => { this.guidenextStep('visitor', 1, 'visitors_guide_step') }}>Next</button><button className="end-btn" onClick={() => { this.endGuideTour('visitor', 'visitors_guide') }}>End tour</button></div>) : (null)}
                                {this.state.displayPreVisitors.length > 0 ?  <ul style={{ pointerEvents: disab }} id="right_premium_ul" className="list-group list-group-flush newPremiumHeight scroll-4">
                                <ListComponent
                                    items={this.state.displayPreVisitors}
                                    centerClientLoading={this.centerClientLoading}
                                    visitor_status={NEW_VISITOR_STATUS}
                                    type="premium"
                                /> 
                            </ul>: null}
                        </div>) : (null)}

                        {(showNormal && this.state.optionFilterBy === "none" && !showInstalWidget) ? (<h5 className="py-3 px-5 mt-5">Visitors</h5>) : (null)}
                        {showNormal ? (<div style={{ display: simpleList }} className="flex-fill information listing-right-panel">
                            {this.context.visitors_guide === false && this.context.visitors_guide_step === 0 && !showPremium && this.state.displayVisitors.length > 0 ? (<div className="tour_guide mt-11" id="tour_guide">
                                <p className="title">Step 1 of 4</p>
                                <p className="text-center">This is a visitor who is currently present on your website.</p>
                                <button className="btn-next" onClick={() => { this.guidenextStep('visitor', 1, 'visitors_guide_step') }}>Next</button><button className="end-btn" onClick={() => { this.endGuideTour('visitor', 'visitors_guide') }}>End tour</button></div>) : (null)}
                            <ul style={{ pointerEvents: disab }} id="right_normal_ul" className={this.context.visitors_guide === false && this.context.visitors_guide_step === 0 ? "list-group list-group-flush newPreVisHeight scroll-4 visitor-top" : "list-group list-group-flush newPreVisHeight scroll-4"}>

                            <ListComponent
                                    items={this.state.displayVisitors}
                                    centerClientLoading={this.centerClientLoading}
                                    visitor_status={NEW_VISITOR_STATUS}
                                    type="normal"
                                /> 
                            </ul>
                        </div>) : (null)}

                    </div>) :
                        (null)}

                    {showInstalWidget ? (<div className="bg-white text-center px-8 test-drive pt-6 w-100">
                        <img alt="" src={require('../../../../assets/images/install-livechat.svg')} />
                        <h6 className="card-title mt-8"> Install Livechat widget on to your website</h6>
                        <button className="mt-3 upper-div" onClick={this.openInstallWidget} style={{ background: "#0984f6", color: "#fff", borderRadius: 2, padding: '5px 8px', border: 'none' }} onMouseEnter={() => this.setState({ onButton: true })} onMouseLeave={() => { this.setState({ onButton: false }) }}>Install widget</button>
                        {this.context.livechat_guide === false && this.context.livechat_step === 0 ? (<div className="tour_guide">
                            <p className="title">Step 1 of 4</p>
                            <p className="text-center">Click on this button to test livechat.</p>
                            <button className="btn-next" onClick={() => { this.guidenextStep('livechat', 1, 'livechat_step') }}>Next</button><button className="end-btn" onClick={() => { this.endGuideTour('livechat', 'livechat_guide') }}>End tour</button></div>) : (null)}

                    </div>) : (null)}
                    <div style={{ display: orderByList }} className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat px-4">
                        {this.state.groupByHtmlData}
                    </div>
                </div>
            </div>

            <div id="filterByList" style={{ display: filterList }} className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="border-bottom py-2 py-lg-5 px-lg-2">
                        <div className="px-3">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>Filters</h5>
                                </li>
                                {/*<li className="text-center d-block d-lg-none">
                                    <h6 className="mb-n2">William Wright</h6>
                                    <small className="text-muted">User Details</small>
                                            </li> */}
                                <li className="nav-item ml-2 btn-visitore btn-blacktheme" onClick={() => this.setFilterData()}>Apply</li>
                                <li className="nav-item ml-2 btn-visitore btn-empty" onClick={() => this.resetFilterData()}>Reset</li>
                                <li className="nav-item ml-2 closeinGroup">
                                    <span className="nav-link text-muted px-0" onClick={() => this.closeAddAgent()}>
                                        <img alt="" src={require('../../../../assets/images/livechat/crose.svg')} />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/*<div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat oneClik1show"> */}
                    <div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat">
                        <div className="k-flex align-item-center filter-right-panel">
                            <div className="left-filter-nav">
                                <ul>
                                    <li><span id="countryLi" className="country active" onClick={() => this.setActiveClass('countryLi', ["pageurlLi", "deviceUsedLi"])}>Country</span></li>
                                    <li><span id="pageurlLi" className="pageurl" onClick={() => this.setActiveClass('pageurlLi', ["countryLi", "deviceUsedLi"])}>Page URL</span></li>
                                    <li><span id="deviceUsedLi" className="deviceUsed" onClick={() => this.setActiveClass('deviceUsedLi', ["countryLi", "pageurlLi"])}>Device</span></li>
                                </ul>
                            </div>
                            <ul className="scrollbar list-group list-group-flush list-style-none right-filter-nav pageurl-show" style={{ display: this.state.filterOptionSelected === "pageurl" ? "block" : "none" }}>
                                {Object.keys(this.state.pageUrlWithTotalUser).map(purl =>
                                    <li key={purl} className="px-4">
                                        <div className="py-2 k-flex align-item-center">
                                            <p className="small mb-0 text-screen1 word-break-all pr-4"><input onChange={this.updatePageUrl} checked={this.state.filterOptions.pageUrls.indexOf(purl) >= 0 ? true : false} defaultValue={purl} type="checkbox" name="filter_by_url" /> {purl}</p>
                                            <p className="small mb-0 text-screen1">{this.state.pageUrlWithTotalUser[purl]}</p>
                                        </div>
                                    </li>)}
                            </ul>
                            <ul className="scrollbar list-group list-group-flush list-style-none right-filter-nav country-show" style={{ display: this.state.filterOptionSelected === "country" ? "block" : "none" }}>
                                {Object.keys(this.state.countryWithTotalUser).map(country =>
                                    <li key={country} className="px-4">
                                        <div className="py-2 k-flex align-item-center px-4 country-names-listing">
                                            <p className="small mb-0 text-screen1 word-break-all pr-4"><input onChange={this.updateCountry} checked={this.state.filterOptions.countries.indexOf(country) >= 0 ? true : false} type="checkbox" defaultValue={country} name="filter_by_country" />  {country} </p>
                                            <p className="small mb-0 text-screen1 ml-auto">{this.state.countryWithTotalUser[country]}</p>
                                        </div>
                                    </li>)}
                            </ul>
                            <ul className="scrollbar list-group list-group-flush list-style-none right-filter-nav deviceUsed-show" style={{ display: this.state.filterOptionSelected === "deviceUsed" ? "block" : "none" }}>
                                {Object.keys(this.state.deviceUsedWithTotalUser).map(deviceUsed =>
                                    <li key={deviceUsed} className="px-4">
                                        <div className="py-2 k-flex align-item-center px-4 country-names-listing">
                                            <p className="small mb-0 text-screen1 word-break-all pr-4"><input onChange={this.updateDeviceUsed} checked={this.state.filterOptions.devicesUsed.indexOf(deviceUsed) >= 0 ? true : false} type="checkbox" defaultValue={deviceUsed} name="filter_by_deviceUsed" />  {deviceUsed} </p>
                                            <p className="small mb-0 text-screen1 ml-auto">{this.state.deviceUsedWithTotalUser[deviceUsed]}</p>
                                        </div>
                                    </li>)}
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <div id="filterByOptions" style={{ display: filterBy }} className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="border-bottom py-4 py-lg-6">
                        <div className="container-fluid">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>Group</h5>
                                </li>

                                <li className="nav-item ml-2 btn-visitore btn-blacktheme" onClick={() => this.setFilterOrderByOption()}>Apply All</li>
                                <li className="nav-item ml-2 btn-visitore btn-empty" onClick={() => this.resetFilterOrderByOption()}>Reset</li>
                                <li className="nav-item ml-2 closeinGroup">
                                    <span className="nav-link text-muted px-0" onClick={() => this.closeAddAgent()}>
                                        <img alt="" src={require('../../../../assets/images/livechat/crose.svg')} />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hide-scrollbar flex-fill">
                        <div className="country-none-url  py-4 px-4">
                            <ul className="list-none">
                                <li>
                                    <label className="radio-inline">
                                        <input id="optnone" defaultValue="none" defaultChecked={this.state.optionFilterBy === "none" ? true : false} type="radio" name="optgroupby" /><span className="text-truncate pl-2">By None</span>
                                    </label>
                                </li>
                                <li>
                                    <label className="radio-inline">
                                        <input id="optcountry" defaultValue="country" defaultChecked={this.state.optionFilterBy === "country" ? true : false} type="radio" name="optgroupby" /><span className="text-truncate pl-2">By Country</span>
                                    </label>
                                </li>
                                <li>
                                    <label className="radio-inline">
                                        <input id="optpageurl" defaultValue="pageUrl" defaultChecked={this.state.optionFilterBy === "pageUrl" ? true : false} type="radio" name="optgroupby" /><span className="text-truncate pl-2">By Page URL</span>
                                    </label>
                                </li>
                                <li>
                                    <label className="radio-inline">
                                        <input id="optdeviceused" defaultValue="deviceUsed" defaultChecked={this.state.optionFilterBy === "deviceUsed" ? true : false} type="radio" name="optgroupby" /><span className="text-truncate pl-2">By Device Type</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>

            {/* for showing online agents Start */}
            <div style={{ display: this.state.showAgents ? "block" : "none" }} id="onlineagents" className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="border-bottom py-2 py-lg-5 px-lg-2">
                        <div className="px-3">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>Online Agents ({this.state.seachedAgents.length})</h5>
                                </li>
                                <li className="nav-item ml-2 closeinGroup" onClick={() => this.closeOnlineAgents()}>
                                    <span className="nav-link text-muted px-0">
                                        <img alt="" src={require('../../../../assets/images/livechat/crose.svg')} />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat">
                        <div className="k-flex align-item-center filter-right-panel">
                            <div className="newconfirmParaSection py-4 px-3">
                                <div className="newconfirmBtbn mt-4">
                                    <div className="newselectOptionSerchSearch">
                                        <div className="input-group visitore-search pb-3 material-input">

                                            <TextField className="form-control" id="srchagentonline" label="Search here" variant="outlined" name="srchagentonline" onKeyUp={this.searchAgent} />

                                            {/* <input type="text" className="form-control form-control-lg" placeholder="Search here" id="srchagentonline" onKeyUp={this.searchAgent} /> */}

                                            <div className="input-group-append">
                                                <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="submit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                        <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="newaddToAgentList scrollChatMessage onlineAgent scroll-4 px-4">
                                        <ul>
                                            {this.state.seachedAgents.map(agent =>
                                                <li key={agent.key} id={agent.key}>
                                                    {agent.profile_img ? <span className="online-agentSpan"><img alt="" src={agent.profile_img} /><span className="online-Agents"></span></span> : <strong className="newagentimg">{agent.agent_short}<span className="online-Agents"></span></strong>}
                                                    <div className="newaddToAgentListName"><div className="k-flex align-items-center agnt_online_icon"><p className="mr-auto card-body-text">{this.agent.agentId === agent.key ? agent.alias + " (Self)" : agent.alias}</p>
                                                        {this.agent.agentId !== agent.key ? (
                                                            <span onClick={() => this.startChatWithAgent(agent)} className="agent_smsbtn cursor-pointer mr-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30.811" height="23.263" viewBox="0 0 35.811 28.263">
                                                                    <g id="chat-bubbles-with-ellipsis" transform="translate(0 -3.24)">
                                                                        <path id="Path_2294" data-name="Path 2294" d="M31.856,9.67h-.981V20.451a2.919,2.919,0,0,1-2.937,3.2H8.743v.513c0,1.484,1.688,2.982,3.421,2.982H26.84l5.615,3.3-.814-3.3h.215c1.732,0,2.514-1.494,2.514-2.982V12.229A2.31,2.31,0,0,0,31.856,9.67Z" transform="translate(1.441 1.06)" fill="#3e4360" />
                                                                        <path id="Path_2295" data-name="Path 2295" d="M26.514,3.24H4.219C2.257,3.24,0,4.983,0,6.662V20.175c0,1.547,1.913,2.664,3.747,2.836L2.553,27.543l7.651-4.5h16.31c1.962,0,3.772-1.189,3.772-2.868V6.662A3.85,3.85,0,0,0,26.514,3.24ZM7.62,14.646a2.014,2.014,0,1,1,2.014-2.014A2.014,2.014,0,0,1,7.62,14.646Zm7.523,0a2.014,2.014,0,1,1,2.014-2.014A2.014,2.014,0,0,1,15.143,14.646Zm7.524,0a2.014,2.014,0,1,1,2.014-2.014A2.015,2.015,0,0,1,22.667,14.646Z" transform="translate(0 0)" fill="#3e4360" />
                                                                    </g>
                                                                </svg>
                                                            </span>) : (null)}
                                                        {(this.agent.agentId !== agent.key && agent.calling_status === 1 && (this.canCalling === "phone_voip" || this.canCalling === "voip")) ? (<><button className="agent_callbtn agent_callbtn_hover" onClick={() => this.startVoipCall(agent)}>
                                                            <svg id="telephone" xmlns="http://www.w3.org/2000/svg" width="12.996" height="12.996" viewBox="0 0 13.996 13.996">
                                                                <g id="Group_2755" data-name="Group 2755" transform="translate(6.998 3.732)">
                                                                    <g id="Group_2754" data-name="Group 2754">
                                                                        <path id="Path_2242" data-name="Path 2242" d="M240.467,128a.467.467,0,1,0,0,.933,1.868,1.868,0,0,1,1.866,1.866.467.467,0,0,0,.933,0A2.8,2.8,0,0,0,240.467,128Z" transform="translate(-240 -128)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                                <g id="Group_2757" data-name="Group 2757" transform="translate(6.998 1.866)">
                                                                    <g id="Group_2756" data-name="Group 2756">
                                                                        <path id="Path_2243" data-name="Path 2243" d="M240.467,64a.467.467,0,0,0,0,.933,3.736,3.736,0,0,1,3.732,3.732.467.467,0,1,0,.933,0A4.671,4.671,0,0,0,240.467,64Z" transform="translate(-240 -64)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                                <g id="Group_2759" data-name="Group 2759" transform="translate(6.998)">
                                                                    <g id="Group_2758" data-name="Group 2758">
                                                                        <path id="Path_2244" data-name="Path 2244" d="M240.467,0a.467.467,0,1,0,0,.933,5.6,5.6,0,0,1,5.6,5.6.467.467,0,1,0,.933,0A6.539,6.539,0,0,0,240.467,0Z" transform="translate(-240 0)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                                <g id="Group_2761" data-name="Group 2761" transform="translate(0 0.933)">
                                                                    <g id="Group_2760" data-name="Group 2760">
                                                                        <path id="Path_2245" data-name="Path 2245" d="M12.6,41.331a8.66,8.66,0,0,1-3.835-.876.458.458,0,0,0-.357-.021.471.471,0,0,0-.268.238L7.4,42.189a11.735,11.735,0,0,1-4.527-4.527l1.517-.735a.466.466,0,0,0,.216-.625,8.66,8.66,0,0,1-.876-3.835A.467.467,0,0,0,3.266,32H.467A.467.467,0,0,0,0,32.467a12.611,12.611,0,0,0,12.6,12.6.467.467,0,0,0,.467-.467V41.8A.467.467,0,0,0,12.6,41.331Z" transform="translate(0 -32)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg> </button></>) : (null)}
                                                        <span className="onlineAgentCount ml-3">({this.state.ongoingVisitorsChats[agent.key] ? this.state.ongoingVisitorsChats[agent.key] : 0})</span></div></div>
                                                </li>)}
                                            {this.state.seachedAgents.length ? '' : 'No agent is online.'}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for showing online agents End */}

            {/* for call dialer Start */}
            {(this.canCalling === "phone_voip" || this.canCalling === "phone") ? (
                <div style={{ display: this.state.showDialer ? "block" : "none" }} id="calldialer" className="chat-sidebar">
                    <div className="d-flex h-100 flex-column">
                        <div className="border-bottom py-2 py-lg-5 px-lg-2" style={{ background: '#1C223A' }}>
                            <div className="px-3">
                                <ul className="nav justify-content-between align-items-center">
                                    <li className="nav-item mr-auto text-heading-screen1">
                                        <h5 style={{ color: '#fff' }}>Call</h5>
                                    </li>
                                    <li className="nav-item ml-2 closeinGroup" onClick={this.hideDialer}>
                                        <span className="nav-link text-muted px-0" style={{ background: '#fff' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.166" height="16.166" viewBox="0 0 16.166 16.166">
                                                <g id="Group_1639" data-name="Group 1639" transform="translate(8.083 22.229) rotate(-135)">
                                                    <rect id="Rectangle_1271" data-name="Rectangle 1271" width="2.858" height="20.005" rx="1.429" transform="translate(8.574 0)" fill="#2c3049" />
                                                    <rect id="Rectangle_1272" data-name="Rectangle 1272" width="2.858" height="20.005" rx="1.429" transform="translate(0 11.431) rotate(-90)" fill="#2c3049" />
                                                </g>
                                            </svg>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat">
                            <div className="k-flex align-item-center filter-right-panel" style={{ background: '#fff' }}>
                                <div className="newconfirmParaSection py-4">
                                    <div className="newconfirmBtbn mt-4">

                                        <div className="newaddToAgentList scrollChatMessage onlineAgent scroll-4 px-4 donthidebyclkonbdy">
                                            {/* <span className="dialer-crose" onClick={this.hideDialer}><img src={require('../../../../assets/img/icon/right-panel-crose.svg')} /></span> */}
                                            <div className="rightdialpad rightcompact donthidebyclkonbdy">
                                                <span className="enter-Numbers-Dialed">Enter number</span>

                                                <div className="number donthidebyclkonbdy number_dialedRight" id="displayphonenumber">

                                                    <PhoneInput
                                                        country={'IN'}
                                                        value={this.state.contactnumber ? this.state.contactnumber : "+91"}
                                                        enableSearch={true}
                                                        onChange={this.handTextChange}
                                                        onKeyDown={this.handleKeyDown}
                                                        inputProps={{
                                                            name: 'rig_phone_number',
                                                            id: 'rig_phone_number',
                                                            required: true,
                                                            autoFocus: true
                                                        }}
                                                        autoFormat={false}
                                                        isValid={this.isValidPhoneNumber}
                                                        placeholder={"+911234567890"}
                                                    //prefix="+"
                                                    />

                                                    {/*<PhoneInput
                                                international
                                                defaultCountry="IN"
                                                name="rig_phone_number"
                                                value={this.state.contactnumber}
                                                placeholder="+91 2345678564"
                                                id="rig_phone_number"
                                                onChange={this.handTextChange}
                                                onKeyDown = {this.handleKeyDown}
                                                /> */}
                                                </div>

                                                {/* <div className="number donthidebyclkonbdy">
                                                <input className="donthidebyclkonbdy" onFocus={(event) => this.handlePhoneFocus(event)} onChange={this.handTextChange} onKeyDown={this.handleKeyDown} type="text" name="rig_phone_number" id="rig_phone_number" />
                                            </div> */}

                                                <div className="rightdials donthidebyclkonbdy">
                                                    <ol className="rightnumberdials donthidebyclkonbdy">
                                                        <li onClick={(e) => this.dialNumber(1, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">1</strong></p></li>
                                                        <li onClick={(e) => this.dialNumber(2, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">2</strong></p></li>
                                                        <li onClick={(e) => this.dialNumber(3, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">3</strong></p></li>
                                                        <li onClick={(e) => this.dialNumber(4, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">4</strong></p></li>
                                                        <li onClick={(e) => this.dialNumber(5, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">5</strong></p></li>
                                                        <li onClick={(e) => this.dialNumber(6, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">6</strong></p></li>
                                                        <li onClick={(e) => this.dialNumber(7, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">7</strong></p></li>
                                                        <li onClick={(e) => this.dialNumber(8, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">8</strong></p></li>
                                                        <li onClick={(e) => this.dialNumber(9, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">9</strong></p></li>
                                                        <li onClick={(e) => this.dialNumber("*", e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">*</strong></p></li>
                                                        <li onClick={(e) => this.dialNumber(0, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">0</strong><sub className="forDetectPlus donthidebyclkonbdy english-word" id="forDetectPlus" style={{ fontSize: "26px" }} onClick={(e) => this.dialNumber("+", e)}>+</sub></p></li>
                                                        <li onClick={(e) => this.dialNumber("#", e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">#</strong></p></li>
                                                    </ol>
                                                </div>

                                                <div className="rightdialerbuttons">
                                                    <ul className="dbuttons">
                                                        <li onClick={this.showHideSmsArea} className="digits donthidebyclkonbdy">
                                                            <p className="smsNumberIcon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="40.667" height="46.477" viewBox="0 0 40.667 46.477">
                                                                    <g id="phone" transform="translate(-32)">
                                                                        <g id="Group_2805" data-name="Group 2805" transform="translate(32)">
                                                                            <g id="Group_2804" data-name="Group 2804">
                                                                                <path id="Path_2261" data-name="Path 2261" d="M61.048,8.714V2.9a2.908,2.908,0,0,0-2.9-2.9H34.9A2.91,2.91,0,0,0,32,2.9V43.572a2.908,2.908,0,0,0,2.9,2.9H58.143a2.906,2.906,0,0,0,2.9-2.9V34.858h-2.9v2.9H34.9V5.81H58.143v2.9Z" transform="translate(-32)" />
                                                                            </g>
                                                                        </g>
                                                                        <g id="Group_2807" data-name="Group 2807" transform="translate(49.429 11.619)">
                                                                            <g id="Group_2806" data-name="Group 2806">
                                                                                <path id="Path_2262" data-name="Path 2262" d="M244.334,128H226.9a2.91,2.91,0,0,0-2.9,2.9v11.619a2.873,2.873,0,0,0,2.9,2.9h2.9v2.9a1.455,1.455,0,0,0,.8,1.3,1.476,1.476,0,0,0,.651.154,1.454,1.454,0,0,0,.871-.29l5.423-4.067h6.777a2.873,2.873,0,0,0,2.9-2.9V130.9A2.873,2.873,0,0,0,244.334,128ZM231.32,138.167a1.465,1.465,0,0,1-1.467-1.452,1.439,1.439,0,0,1,1.438-1.452h.029a1.452,1.452,0,0,1,0,2.9Zm8.714,0a1.467,1.467,0,0,1-1.467-1.452,1.441,1.441,0,0,1,1.438-1.452h.029a1.452,1.452,0,1,1,0,2.9Z" transform="translate(-224 -128)" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </p>
                                                        </li>
                                                        <li id="rig_btncall" onClick={(e) => this.dialNumber("call", e)} className="digits pad-action"><p className="donthidebyclkonbdy" style={{ padding: '30px 0px' }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 107 107">
                                                                <g id="Group_2843" data-name="Group 2843" transform="translate(-1546 -735)">
                                                                    <circle id="Ellipse_277" data-name="Ellipse 277" cx="53.5" cy="53.5" r="53.5" transform="translate(1546 735)" fill="#77c075" />
                                                                    <g id="telephone" transform="translate(1575.171 764.171)">
                                                                        <g id="Group_2755" data-name="Group 2755" transform="translate(24.157 12.884)">
                                                                            <g id="Group_2754" data-name="Group 2754">
                                                                                <path id="Path_2242" data-name="Path 2242" d="M241.61,128a1.61,1.61,0,0,0,0,3.221,6.448,6.448,0,0,1,6.442,6.442,1.61,1.61,0,0,0,3.221,0A9.675,9.675,0,0,0,241.61,128Z" transform="translate(-240 -128)" fill="#fff" />
                                                                            </g>
                                                                        </g>
                                                                        <g id="Group_2757" data-name="Group 2757" transform="translate(24.157 6.442)">
                                                                            <g id="Group_2756" data-name="Group 2756">
                                                                                <path id="Path_2243" data-name="Path 2243" d="M241.61,64a1.61,1.61,0,0,0,0,3.221A12.9,12.9,0,0,1,254.494,80.1a1.61,1.61,0,1,0,3.221,0A16.123,16.123,0,0,0,241.61,64Z" transform="translate(-240 -64)" fill="#fff" />
                                                                            </g>
                                                                        </g>
                                                                        <g id="Group_2759" data-name="Group 2759" transform="translate(24.157 0)">
                                                                            <g id="Group_2758" data-name="Group 2758">
                                                                                <path id="Path_2244" data-name="Path 2244" d="M241.61,0a1.61,1.61,0,0,0,0,3.221,19.345,19.345,0,0,1,19.325,19.325,1.61,1.61,0,1,0,3.221,0A22.573,22.573,0,0,0,241.61,0Z" transform="translate(-240)" fill="#fff" />
                                                                            </g>
                                                                        </g>
                                                                        <g id="Group_2761" data-name="Group 2761" transform="translate(0 3.221)">
                                                                            <g id="Group_2760" data-name="Group 2760">
                                                                                <path id="Path_2245" data-name="Path 2245" d="M43.482,64.209a29.894,29.894,0,0,1-13.238-3.024,1.581,1.581,0,0,0-1.234-.074,1.624,1.624,0,0,0-.924.821l-2.532,5.24A40.507,40.507,0,0,1,9.927,51.544l5.237-2.538a1.608,1.608,0,0,0,.744-2.158A29.894,29.894,0,0,1,12.884,33.61,1.611,1.611,0,0,0,11.273,32H1.61A1.611,1.611,0,0,0,0,33.61,43.531,43.531,0,0,0,43.482,77.092a1.611,1.611,0,0,0,1.61-1.61V65.819A1.611,1.611,0,0,0,43.482,64.209Z" transform="translate(0 -32)" fill="#fff" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </p>
                                                        </li>
                                                        <li style={{ display: "none", cursor: "pointer" }} id="rig_btnhangup" onClick={(e) => this.dialNumber("hangup", e)} className="digits pad-hangup donthidebyclkonbdy">
                                                            <p className='donthidebyclkonbdy smsNumberIcon pl-0 pr-0'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 107 107">
                                                                    <g id="Group_2843" data-name="Group 2843" transform="translate(-1546 -735)">
                                                                        <circle id="Ellipse_277" data-name="Ellipse 277" cx="53.5" cy="53.5" r="53.5" transform="translate(1546 735)" fill="#f73b2e" />
                                                                        <g id="telephone" transform="translate(1575.171 764.171)">
                                                                            <g id="Group_2755" data-name="Group 2755" transform="translate(24.157 12.884)">
                                                                                <g id="Group_2754" data-name="Group 2754">
                                                                                    <path id="Path_2242" data-name="Path 2242" d="M241.61,128a1.61,1.61,0,0,0,0,3.221,6.448,6.448,0,0,1,6.442,6.442,1.61,1.61,0,0,0,3.221,0A9.675,9.675,0,0,0,241.61,128Z" transform="translate(-240 -128)" fill="#fff" />
                                                                                </g>
                                                                            </g>
                                                                            <g id="Group_2757" data-name="Group 2757" transform="translate(24.157 6.442)">
                                                                                <g id="Group_2756" data-name="Group 2756">
                                                                                    <path id="Path_2243" data-name="Path 2243" d="M241.61,64a1.61,1.61,0,0,0,0,3.221A12.9,12.9,0,0,1,254.494,80.1a1.61,1.61,0,1,0,3.221,0A16.123,16.123,0,0,0,241.61,64Z" transform="translate(-240 -64)" fill="#fff" />
                                                                                </g>
                                                                            </g>
                                                                            <g id="Group_2759" data-name="Group 2759" transform="translate(24.157 0)">
                                                                                <g id="Group_2758" data-name="Group 2758">
                                                                                    <path id="Path_2244" data-name="Path 2244" d="M241.61,0a1.61,1.61,0,0,0,0,3.221,19.345,19.345,0,0,1,19.325,19.325,1.61,1.61,0,1,0,3.221,0A22.573,22.573,0,0,0,241.61,0Z" transform="translate(-240)" fill="#fff" />
                                                                                </g>
                                                                            </g>
                                                                            <g id="Group_2761" data-name="Group 2761" transform="translate(0 3.221)">
                                                                                <g id="Group_2760" data-name="Group 2760">
                                                                                    <path id="Path_2245" data-name="Path 2245" d="M43.482,64.209a29.894,29.894,0,0,1-13.238-3.024,1.581,1.581,0,0,0-1.234-.074,1.624,1.624,0,0,0-.924.821l-2.532,5.24A40.507,40.507,0,0,1,9.927,51.544l5.237-2.538a1.608,1.608,0,0,0,.744-2.158A29.894,29.894,0,0,1,12.884,33.61,1.611,1.611,0,0,0,11.273,32H1.61A1.611,1.611,0,0,0,0,33.61,43.531,43.531,0,0,0,43.482,77.092a1.611,1.611,0,0,0,1.61-1.61V65.819A1.611,1.611,0,0,0,43.482,64.209Z" transform="translate(0 -32)" fill="#fff" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </p></li>
                                                        <li id="backspace_number"  className="digits donthidebyclkonbdy">
                                                            <p className="donthidebyclkonbdy" id="backspace_no" onClick={(e)=>this.dialNumber("delete", e) }>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="51.872" height="38.904" viewBox="0 0 51.872 38.904">
                                                                    <g id="delete" transform="translate(0.001 -40.118)">
                                                                        <path id="Path_2287" data-name="Path 2287" d="M47.009,40.118H17.747A4.849,4.849,0,0,0,13.98,41.9L.365,58.544a1.621,1.621,0,0,0,0,2.052L13.982,77.24a4.846,4.846,0,0,0,3.764,1.782H47.009a4.868,4.868,0,0,0,4.863-4.863V44.981A4.868,4.868,0,0,0,47.009,40.118ZM48.63,74.159a1.622,1.622,0,0,1-1.621,1.621H17.747a1.617,1.617,0,0,1-1.255-.594L3.715,59.57,16.49,43.955a1.619,1.619,0,0,1,1.257-.6H47.009a1.622,1.622,0,0,1,1.621,1.621S48.63,74.159,48.63,74.159Z" transform="translate(0)" />
                                                                        <path id="Path_2288" data-name="Path 2288" d="M139.5,103.232l-6.959,6.959-6.959-6.959-2.292,2.292,6.959,6.959-6.959,6.959,2.292,2.292,6.959-6.959,6.959,6.959,2.292-2.292-6.959-6.959,6.959-6.959Z" transform="translate(-103.365 -52.913)" />
                                                                    </g>
                                                                </svg>
                                                            </p>
                                                            <p className='donthidebyclkonbdy' id="mutebutton" style={{display:"none", cursor:"pointer"}}>
                                                                <span className="calling-circle-left mute_from_ong mute-black mr-2" style={{ width: "65px", height: "65px", lineHeight: '60px' , display:"block"}} id="mute_btn">
                                                                    <svg id="voice" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 11.731 19.063">
                                                                        <g id="Group_2898" data-name="Group 2898" transform="translate(0)">
                                                                            <path id="Path_2297" data-name="Path 2297" d="M34.475,14.454a3.408,3.408,0,0,0,3.4-3.4V3.4a3.4,3.4,0,0,0-6.808,0V11.05A3.408,3.408,0,0,0,34.475,14.454Z" transform="translate(-28.609)" fill="#fff" />
                                                                            <path id="Path_2298" data-name="Path 2298" d="M29.925,45.715h-.678a.4.4,0,0,0-.394.394V47.5a4.4,4.4,0,0,1-8.8,0V46.109a.4.4,0,0,0-.394-.394h-.678a.4.4,0,0,0-.394.394V47.5a5.949,5.949,0,0,0,5.132,5.819V54.3H20.693a.4.4,0,0,0-.394.394v.678a.4.4,0,0,0,.394.394h7.521a.4.4,0,0,0,.394-.394v-.678a.4.4,0,0,0-.394-.394H25.187v-.982A5.949,5.949,0,0,0,30.319,47.5V46.109A.394.394,0,0,0,29.925,45.715Z" transform="translate(-18.588 -36.7)" fill="#fff" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span style={{ display: "none", width: "65px", height: "65px", lineHeight: '60px' , cursor:"pointer"}} id="unmute_btn" className="calling-circle-left unmute_from_ong mute-black mr-2">
                                                                    <svg id="mute-microphone_copy" data-name="mute-microphone copy" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15.48 19.063">
                                                                        <g id="Group_2897" data-name="Group 2897" transform="translate(0)">
                                                                            <path id="Path_2294" data-name="Path 2294" d="M43.955,45.851v-2.5l-5.316,5.316a3.4,3.4,0,0,0,5.316-2.814Z" transform="translate(-32.81 -34.801)" fill="#fff" />
                                                                            <path id="Path_2295" data-name="Path 2295" d="M36.89,45.715h-.678a.4.4,0,0,0-.394.394V47.5a4.4,4.4,0,0,1-6.876,3.634l-1.034,1.034a5.763,5.763,0,0,0,2.778,1.151V54.3H27.658a.4.4,0,0,0-.394.394v.678a.4.4,0,0,0,.394.394h7.521a.4.4,0,0,0,.394-.394v-.678a.4.4,0,0,0-.394-.394H32.152v-.982A5.949,5.949,0,0,0,37.284,47.5V46.109A.394.394,0,0,0,36.89,45.715Z" transform="translate(-23.679 -36.7)" fill="#fff" />
                                                                            <path id="Path_2296" data-name="Path 2296" d="M24.245,1.517a1.085,1.085,0,0,0-1.534,0L20.226,4V3.4a3.4,3.4,0,1,0-6.808,0V10.81l-.9.9a4.384,4.384,0,0,1-.1-.912V9.409a.4.4,0,0,0-.394-.394h-.678a.4.4,0,0,0-.394.394V10.8a5.686,5.686,0,0,0,.4,2.077L9.4,14.828a1.085,1.085,0,0,0,1.534,1.534L24.244,3.051A1.084,1.084,0,0,0,24.245,1.517Z" transform="translate(-9.082)" fill="#fff" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </p>
                                                        </li>
                                                        <li id="backspace_sms" style={{ display: "none" }} onClick={(e) => this.dialNumber("deletesms", e)} className="digits donthidebyclkonbdy">
                                                            <p className="donthidebyclkonbdy">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="51.872" height="38.904" viewBox="0 0 51.872 38.904">
                                                                    <g id="delete" transform="translate(0.001 -40.118)">
                                                                        <path id="Path_2287" data-name="Path 2287" d="M47.009,40.118H17.747A4.849,4.849,0,0,0,13.98,41.9L.365,58.544a1.621,1.621,0,0,0,0,2.052L13.982,77.24a4.846,4.846,0,0,0,3.764,1.782H47.009a4.868,4.868,0,0,0,4.863-4.863V44.981A4.868,4.868,0,0,0,47.009,40.118ZM48.63,74.159a1.622,1.622,0,0,1-1.621,1.621H17.747a1.617,1.617,0,0,1-1.255-.594L3.715,59.57,16.49,43.955a1.619,1.619,0,0,1,1.257-.6H47.009a1.622,1.622,0,0,1,1.621,1.621S48.63,74.159,48.63,74.159Z" transform="translate(0)" />
                                                                        <path id="Path_2288" data-name="Path 2288" d="M139.5,103.232l-6.959,6.959-6.959-6.959-2.292,2.292,6.959,6.959-6.959,6.959,2.292,2.292,6.959-6.959,6.959,6.959,2.292-2.292-6.959-6.959,6.959-6.959Z" transform="translate(-103.365 -52.913)" />
                                                                    </g>
                                                                </svg>
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : (null)}
            {/* for call dialer end */}

            {/* for Whats App Templates start */}

            <div style={{ display: showWhatsAppTemplates }} id="whatsapptemplates" className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="border-bottom py-2 py-lg-5 px-lg-2">
                        <div className="px-3">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>WhatsApp Templates</h5>
                                </li>
                                <li className="nav-item ml-2 closeinGroup" onClick={this.closeWhatsAppTemplates}>
                                    <span className="nav-link text-muted px-0">
                                        <img alt="" src={require('../../../../assets/images/livechat/crose.svg')} />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat">
                        <div className="k-flex align-item-center filter-right-panel">
                            <div className="newconfirmParaSection py-4 px-4">
                                {this.state.showWhatsappTemplates.map((template, tindex) => {
                                    return <div key={tindex}>
                                        <div onClick={(e) => this.showHideTemplate(e, tindex)} className="whtsapptemplates k-flex align-item-center justify-content-center">
                                            <h5>{template.id}</h5>
                                            <span style={{ marginLeft: "20px" }}>
                                                ({template.contentArray.length})
                                            </span>
                                            <span className="ml-auto">
                                                <img alt="" id={"downToggleIcon_" + tindex} src={require('../../../../assets/images/livechat/left.svg')} />
                                            </span>
                                        </div>
                                        <div id={"templatecontent_" + tindex} className="newconfirmBtbn mt-4 donotrmv2" style={{ display: "none" }}>
                                            <ul>
                                                {template.contentArray.map((contentobj, tcindex) => {
                                                    return <li key={tcindex} className="position-relative mb-4 pb-4">
                                                        <div style={{ cursor: "pointer" }} onClick={() => this.selectTemplate(contentobj)} className="notesChatNot">
                                                            <p className="notesDescrption">{contentobj.lang}</p>
                                                            <p className="notesBy">{contentobj.message}</p>
                                                        </div>
                                                    </li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for Whats App Templates End */}

            {/* for ticketing start */}
            <div style={{ display: showTicketing }} id="ticketing" className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="border-bottom py-2 py-lg-5 px-lg-2">
                        <div className="px-3">
                            <ul className="nav justify-content-between align-items-center">
                                <li className="nav-item mr-auto text-heading-screen1">
                                    <h5>Ticketing by Appy Pie Desk</h5>
                                </li>
                                <li className="nav-item ml-2 closeinGroup" onClick={() => this.closePanel()}>
                                    <span className="nav-link text-muted px-0">
                                        <img alt="" src={require('../../../../assets/images/livechat/crose.svg')} />
                                    </span>
                                </li>
                            </ul>

                        </div>
                    </div>


                    <div className="hide-scrollbar flex-fill information listing-right-panel bg-screen-chat">
                        <div className="k-flex align-item-center filter-right-panel">
                            <div className="newconfirmParaSection">

                                {/* <form action="/action_page.php"> */}
                                <div className='ticket-section'>
                                    <h5 className='mb-5'>Current Ticket</h5>
                                    <form id="createTicketForm" onSubmit={this.createTicket}>
                                        <div className='ticket-subject '>
                                            {/* <label htmlFor="subjectTicket">
                                                <h5>Subject</h5>
                                            </label> */}
                                            <div className='input-group material-input mb-5'>
                                                <TextField className="form-control w-150" id="subjectTicket" name="subjectTicket" label="Subject" placeholder="Conversation with Client" variant="outlined" onChange={this.handleChange} value={this.state.subjectTicket} />
                                            </div>
                                            {/* <input type="text" id="subjectTicket" name="subjectTicket" placeholder="Conversation with Client" onChange={this.handleChange} value={this.state.subjectTicket} /> */}
                                            {this.state.subjectTicket_error ? (<p className="error">{this.state.subjectTicket_error}</p>) : (null)}

                                        </div>
                                        <div className='ticket-subject'>
                                            {/* <label htmlFor="clientEmailTicket">
                                                <h5>Client Email</h5>
                                            </label> */}
                                            <div className='input-group material-input mb-5'>
                                                <TextField className="form-control w-150" id="clientEmailTicket" name="clientEmailTicket" label="Client Email" value={this.state.clientEmailTicket} onChange={this.handleChange} variant="outlined" placeholder="noreply@appypie.com" />
                                            </div>
                                            {/* <input type="text" id="clientEmailTicket" name="clientEmailTicket" placeholder={this.context.currentVisitor.client_email ? this.context.currentVisitor.client_email : 'noreply@appypie.com'} onChange={this.handleChange} /> */}
                                            {this.state.clientEmail_error ? (<p className="error"></p>) : (null)}
                                        </div>
                                        <div className='ticket-row border-bottom pb-4 mb-4 mt-4 ps-relative'>
                                            <label htmlFor="assigned_to"><h6>Assigned To</h6></label>
                                            {this.state.assignedTo === '' ?
                                                (<>
                                                    <button className='link-btn' type="button" id="assigned_to" onClick={this.addAssignedTo}>+ Add New</button>
                                                    {this.state.assignedTo_error ? (<p className="error">{this.state.assignedTo_error}</p>) : (null)}
                                                    {/* Start: Assigned to dropdown */}
                                                    {this.state.showAssignedToMenu ?
                                                        (<div className="navbarDropdown dropdown-menu w-300 dropdown-menu-right top60">
                                                            <div className="agentTeamTab mx-3 mb-2">
                                                                <span className={this.state.isAgentTab ? "active" : ""} onClick={() => this.setState({ isAgentTab: true })}>Agent</span><span> | </span><span className={this.state.isAgentTab ? "" : "active"} onClick={() => this.setState({ isAgentTab: false })}>Team</span>
                                                            </div>
                                                            {this.state.isAgentTab ?
                                                                (<>
                                                                    <div className="departmentSearch pl-3 pr-3">
                                                                        <div>
                                                                            <div className="md-form md-form-custom ps-relative ">
                                                                                <span className="searchIcon-dep cursor-pointer">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                        <path id="ic_zoom_out_24px"
                                                                                            d="M13.006,11.805h-.632l-.224-.216a5.211,5.211,0,1,0-.56.56l.216.224v.632l4,3.994L17,15.807Zm-4.8,0a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,8.2,11.805Z"
                                                                                            transform="translate(-3 -3)" fill="#64b5f6"></path>
                                                                                    </svg>
                                                                                </span>
                                                                                <input type="text" className="form-control" autoComplete="off" placeholder="Search Agent" onChange={this.filterAgents} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="department-acc pb-2 scrollbar department-acc-height">
                                                                        {this.state.filteredDeskAgents.map((deskAgent) => (<div key={deskAgent.user_id} className="partment-row pl-3 pr-3" onClick={() => { this.setState({ assignedTo: deskAgent.user_id, assignedToName: deskAgent.firstname + ' ' + deskAgent.lastname, assignedToEntity: 'U', assignedToStatus: deskAgent.status, assignedToRoleType: deskAgent.role_type }, () => this.addAssignedTo()) }}>
                                                                            <div className="d-flex align-items-center cursor-pointer">
                                                                                <img src="data:image/svg+xml,%3Csvg id='Group_1955' data-name='Group 1955' xmlns='http://www.w3.org/2000/svg' width='42.658' height='42.66' viewBox='0 0 42.658 42.66'%3E %3Cpath id='Path_1761' data-name='Path 1761' d='M16.823,35.006l-6.364,3.472a5.719,5.719,0,0,0-1.021.727,21.316,21.316,0,0,0,27.388.071,5.646,5.646,0,0,0-1.122-.752l-6.815-3.407a2.6,2.6,0,0,1-1.437-2.325V30.118a10.292,10.292,0,0,0,.645-.829,15.681,15.681,0,0,0,2.119-4.269,2.141,2.141,0,0,0,1.518-2.036V20.129a2.131,2.131,0,0,0-.713-1.582V14.422S31.867,8,23.172,8s-7.848,6.421-7.848,6.421v4.126a2.128,2.128,0,0,0-.713,1.582v2.854a2.14,2.14,0,0,0,.987,1.8,14.164,14.164,0,0,0,2.581,5.339v2.608A2.6,2.6,0,0,1,16.823,35.006Z' transform='translate(-1.841 -1.561)' fill='%23e7eced' /%3E %3Cg id='Group_1917' data-name='Group 1917' transform='translate(0 0)'%3E %3Cpath id='Path_1762' data-name='Path 1762' d='M21.695,0A21.316,21.316,0,0,0,7.6,37.637a5.668,5.668,0,0,1,1.012-.72l6.364-3.472a2.6,2.6,0,0,0,1.355-2.282V28.556a14.15,14.15,0,0,1-2.581-5.339,2.141,2.141,0,0,1-.987-1.8V18.567a2.131,2.131,0,0,1,.713-1.582V12.86s-.848-6.421,7.848-6.421,7.848,6.421,7.848,6.421v4.126a2.128,2.128,0,0,1,.713,1.582v2.854a2.141,2.141,0,0,1-1.518,2.036,15.681,15.681,0,0,1-2.118,4.269,10.294,10.294,0,0,1-.645.829V31.23a2.6,2.6,0,0,0,1.437,2.325l6.815,3.407a5.675,5.675,0,0,1,1.119.75A21.325,21.325,0,0,0,21.695,0Z' transform='translate(0 0)' fill='%23556080' /%3E %3C/g%3E %3C/svg%3E" alt="" />
                                                                                &nbsp;&nbsp;<div className="fnt-13 clr-grey">{deskAgent.firstname} {deskAgent.lastname}</div>
                                                                            </div>
                                                                        </div>))}
                                                                    </div></>
                                                                ) : (
                                                                    <>
                                                                        <div className="departmentSearch pl-3 pr-3">
                                                                            <div>
                                                                                <div className="md-form md-form-custom ps-relative ">
                                                                                    <span className="searchIcon-dep cursor-pointer">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                            <path id="ic_zoom_out_24px"
                                                                                                d="M13.006,11.805h-.632l-.224-.216a5.211,5.211,0,1,0-.56.56l.216.224v.632l4,3.994L17,15.807Zm-4.8,0a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,8.2,11.805Z"
                                                                                                transform="translate(-3 -3)" fill="#64b5f6"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <input type="text" className="form-control" autoComplete="off" placeholder="Search Team" onChange={this.filterTeams} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="department-acc pb-2 scrollbar department-acc-height">
                                                                            {this.state.filteredDeskTeams.map((deskTeam) => (<div key={deskTeam.team_id} className="partment-row pl-3 pr-3" onClick={() => { this.setState({ assignedTo: deskTeam.team_id, assignedToName: deskTeam.name, assignedToEntity: 'T', assignedToStatus: deskTeam.status }, () => this.addAssignedTo()) }}>
                                                                                <div className="d-flex align-items-center cursor-pointer">
                                                                                    <img src="data:image/svg+xml,%3Csvg id='Group_1955' data-name='Group 1955' xmlns='http://www.w3.org/2000/svg' width='42.658' height='42.66' viewBox='0 0 42.658 42.66'%3E %3Cpath id='Path_1761' data-name='Path 1761' d='M16.823,35.006l-6.364,3.472a5.719,5.719,0,0,0-1.021.727,21.316,21.316,0,0,0,27.388.071,5.646,5.646,0,0,0-1.122-.752l-6.815-3.407a2.6,2.6,0,0,1-1.437-2.325V30.118a10.292,10.292,0,0,0,.645-.829,15.681,15.681,0,0,0,2.119-4.269,2.141,2.141,0,0,0,1.518-2.036V20.129a2.131,2.131,0,0,0-.713-1.582V14.422S31.867,8,23.172,8s-7.848,6.421-7.848,6.421v4.126a2.128,2.128,0,0,0-.713,1.582v2.854a2.14,2.14,0,0,0,.987,1.8,14.164,14.164,0,0,0,2.581,5.339v2.608A2.6,2.6,0,0,1,16.823,35.006Z' transform='translate(-1.841 -1.561)' fill='%23e7eced' /%3E %3Cg id='Group_1917' data-name='Group 1917' transform='translate(0 0)'%3E %3Cpath id='Path_1762' data-name='Path 1762' d='M21.695,0A21.316,21.316,0,0,0,7.6,37.637a5.668,5.668,0,0,1,1.012-.72l6.364-3.472a2.6,2.6,0,0,0,1.355-2.282V28.556a14.15,14.15,0,0,1-2.581-5.339,2.141,2.141,0,0,1-.987-1.8V18.567a2.131,2.131,0,0,1,.713-1.582V12.86s-.848-6.421,7.848-6.421,7.848,6.421,7.848,6.421v4.126a2.128,2.128,0,0,1,.713,1.582v2.854a2.141,2.141,0,0,1-1.518,2.036,15.681,15.681,0,0,1-2.118,4.269,10.294,10.294,0,0,1-.645.829V31.23a2.6,2.6,0,0,0,1.437,2.325l6.815,3.407a5.675,5.675,0,0,1,1.119.75A21.325,21.325,0,0,0,21.695,0Z' transform='translate(0 0)' fill='%23556080' /%3E %3C/g%3E %3C/svg%3E" alt="" />
                                                                                    &nbsp;&nbsp;<div className="fnt-13 clr-grey">{deskTeam.name}</div>
                                                                                </div>
                                                                            </div>))}
                                                                        </div>
                                                                    </>
                                                                )}
                                                        </div>) : (null)}
                                                    {/* End: Assigned to dropdown */}
                                                </>) : (
                                                    <span className='link-btn assigned' type="button" id="assignedTicket">{this.state.assignedToName}
                                                        <span className="ml-3 cursor-pointer" onClick={() => { this.setState({ assignedTo: '', assignedToName: '', assignedToEntity: '', assignedToStatus: '', assignedToRoleType: '' }) }}><svg xmlns="http://www.w3.org/2000/svg" width="9.5" height="9.5" viewBox="0 0 9.5 9.5"><path id="ic_clear_24px" d="M14.5,5.957,13.543,5,9.75,8.793,5.957,5,5,5.957,8.793,9.75,5,13.543l.957.957L9.75,10.707,13.543,14.5l.957-.957L10.707,9.75Z" transform="translate(-5 -5)" fill="#fff"></path></svg></span>
                                                    </span>
                                                )}
                                        </div>
                                        <div className='ticket-row mb-4'>
                                            <label htmlFor="statusTicket"><h6>Status</h6></label>
                                            <select name="statusTicket" id="statusTicket" onChange={this.handleChange} value={this.state.statusTicket}>
                                                {this.state.statusIDs.map((status) => (<option key={status.id} value={status.id} checked={status.id === this.state.statusTicket}>{status.status_name}</option>))}
                                            </select>
                                            {this.state.statusTicket_error ? (<p className="error">{this.state.statusTicket_error}</p>) : (null)}
                                        </div>
                                        <div className='ticket-row mt-5'>
                                            <h6>Priority</h6>
                                            <div className='priority-view mt-4'>
                                                <div className='pri-btn' id="priorityStatus_low" style={{ background: this.state.priorityStatus === 'low' ? "#DFF0FD" : "#ffffff" }}>
                                                    <input type="radio" id="low" name="priorityStatus" defaultValue="low" onClick={this.handleChange} />
                                                    <label htmlFor="low">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.87" height="15" viewBox="0 2 25 20">
                                                            <path id="right-arrow" d="M11.243.222a.75.75,0,0,0-1.066,1.055l5.4,5.4H.746A.743.743,0,0,0,0,7.429a.751.751,0,0,0,.746.757H15.582l-5.4,5.395a.765.765,0,0,0,0,1.066.747.747,0,0,0,1.066,0l6.684-6.684a.734.734,0,0,0,0-1.055Z" transform="translate(14.87 0) rotate(90)" fill="#ccc" />
                                                        </svg>
                                                        Low</label>
                                                </div>
                                                <div className='pri-btn' id="priorityStatus_medium" style={{ background: this.state.priorityStatus === 'medium' ? "#DFF0FD" : "#ffffff" }}>
                                                    <input type="radio" id="medium" name="priorityStatus" defaultValue="medium" onClick={this.handleChange} />
                                                    <label htmlFor="medium">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.87" height="15" viewBox="0 2 25 20">
                                                            <path id="right-arrow" d="M11.243.222a.75.75,0,0,0-1.066,1.055l5.4,5.4H.746A.743.743,0,0,0,0,7.429a.751.751,0,0,0,.746.757H15.582l-5.4,5.395a.765.765,0,0,0,0,1.066.747.747,0,0,0,1.066,0l6.684-6.684a.734.734,0,0,0,0-1.055Z" transform="translate(14.87 0) rotate(90)" fill="#95e452" />
                                                        </svg>
                                                        Medium</label>
                                                </div>
                                                <div className='pri-btn' id="priorityStatus_high" style={{ background: this.state.priorityStatus === 'high' ? "#DFF0FD" : "#ffffff" }}>
                                                    <input type="radio" id="high" name="priorityStatus" defaultValue="high" onClick={this.handleChange} />
                                                    <label htmlFor="high">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.87" height="15" viewBox="0 -5 12 24" style={{ transform: 'rotate(180deg)' }}>
                                                            <path id="right-arrow" d="M11.243.222a.75.75,0,0,0-1.066,1.055l5.4,5.4H.746A.743.743,0,0,0,0,7.429a.751.751,0,0,0,.746.757H15.582l-5.4,5.395a.765.765,0,0,0,0,1.066.747.747,0,0,0,1.066,0l6.684-6.684a.734.734,0,0,0,0-1.055Z" transform="translate(14.87 0) rotate(90)" fill="#e68484" />
                                                        </svg>
                                                        High</label>
                                                </div>
                                                <div className='pri-btn' id="priorityStatus_urgent" style={{ background: this.state.priorityStatus === 'urgent' ? "#DFF0FD" : "#ffffff" }}>
                                                    <input type="radio" id="urgent" name="priorityStatus" defaultValue="urgent" onClick={this.handleChange} />
                                                    <label htmlFor="urgent">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.87" height="15" viewBox="0 -5 12 24" style={{ transform: 'rotate(180deg)' }}>
                                                            <path id="right-arrow" d="M11.243.222a.75.75,0,0,0-1.066,1.055l5.4,5.4H.746A.743.743,0,0,0,0,7.429a.751.751,0,0,0,.746.757H15.582l-5.4,5.395a.765.765,0,0,0,0,1.066.747.747,0,0,0,1.066,0l6.684-6.684a.734.734,0,0,0,0-1.055Z" transform="translate(14.87 0) rotate(90)" fill="#f00" />
                                                        </svg>
                                                        Urgent</label>
                                                </div>
                                                {this.state.priorityStatus_error ? (<p className="error">{this.state.priorityStatus_error}</p>) : (null)}
                                            </div>
                                        </div>
                                        <div className='ticket-row mt-5 description'>
                                            <label className='mb-0' htmlFor="descriptionTicket"><h6 >Description</h6></label>
                                            <span className='small-font'>The chat transcript will be included in the ticket description, you can add additional info.</span>
                                            <textarea id="descriptionTicket" name="descriptionTicket" rows={4} cols={38} placeholder="Type Description..." onChange={this.handleChange} value={this.state.descriptionTicket} />
                                            {this.state.descriptionTicket_error ? (<p className="error">{this.state.descriptionTicket_error}</p>) : (null)}
                                            {/* {this.state.clientEmail_error ? (<p className="error">{this.state.clientEmail_error}</p>) : (null)} */}
                                            <button className="startChatBtn btnBlueSettings" type="submit">
                                                {this.state.createTicketLoader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />) : (null)}
                                                &nbsp;&nbsp;Create Ticket
                                            </button>

                                            {/* <input type="submit" value="Create Ticket" />
                                            {this.state.createTicketLoader ? (<div className="loader">
                                                <div className="spinner">
                                                    <div className="bounce1"></div>
                                                    <div className="bounce2"></div>
                                                    <div className="bounce3"></div>
                                                </div>
                                            </div>) : (null)} */}
                                        </div>
                                    </form>
                                    <div className='ticket-history mt-5'>
                                        <h6 className='mb-0'>Previous tickets</h6>
                                        <div>
                                            {
                                                this.state.previousTickets.length > 0 ?
                                                    (this.state.previousTickets.map((ticket) => {
                                                        const serverTimestamp = Date.now() / 1000;
                                                        let daysAgo = Math.floor((serverTimestamp - ticket.created_on._seconds) / (24 * 60 * 60));
                                                        let dayRemainder = (serverTimestamp - ticket.created_on._seconds) % (24 * 60 * 60);
                                                        let hoursAgo = Math.floor(dayRemainder / (60 * 60));
                                                        let agoTime = daysAgo > 0 ? `${daysAgo} days ago` : (hoursAgo > 0 ? `${hoursAgo} hours ago` : `Few minutes ago`);
                                                        let priority = ticket.priority ? ticket.priority : '';
                                                        let arrowSvg = ['0 2 25 20', '', '#72bf31'];
                                                        if (priority === 'Low') {
                                                            arrowSvg = ['0 2 25 20', '', '#ccc'];
                                                        } else if (priority === 'Medium') {
                                                            arrowSvg = ['0 2 25 20', '', '#95e452'];
                                                        } else if (priority === 'High') {
                                                            arrowSvg = ['0 -5 12 24', 'rotate(180)', '#e68484'];
                                                        } else if (priority === 'Urgent') {
                                                            arrowSvg = ['0 -5 12 24', 'rotate(180)', '#f00'];
                                                        }
                                                        let firstname = ticket.client_details.firstname.trim();
                                                        let lastname = ticket.client_details.lastname.trim();
                                                        let shortForm = '';

                                                        if (lastname) {
                                                            shortForm = firstname[0] + lastname[0];
                                                        }
                                                        else {
                                                            let firstnameSplit = firstname.split(' ')[0];
                                                            shortForm = firstnameSplit.length > 1 ? firstnameSplit[0][0] + firstnameSplit[1][0] : firstnameSplit[0].slice(0, 2);
                                                        }
                                                        return (
                                                            <div key={'ticket_' + ticket.ticket_number} className='ticket-list d-flex align-items-center flex-wrap justify-content-between' onClick={() => (window.open(`${ticket.link}`, '_newtab'))}>
                                                                <div className='icon-view'>
                                                                    <img src={require('../../../../assets/img/icon/computer-icon.svg')} alt="icon" />
                                                                </div>
                                                                <div className='ticket-title px-3'>
                                                                    <strong>#{ticket.ticket_number} |</strong> {ticket.subjects}
                                                                    <div><strong>{firstname}</strong> <small>{agoTime}</small></div>
                                                                </div>
                                                                <div className='priority'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.87" height="15" viewBox={arrowSvg[0]} transform={arrowSvg[1]}>
                                                                        <path id="right-arrow" d="M11.243.222a.75.75,0,0,0-1.066,1.055l5.4,5.4H.746A.743.743,0,0,0,0,7.429a.751.751,0,0,0,.746.757H15.582l-5.4,5.395a.765.765,0,0,0,0,1.066.747.747,0,0,0,1.066,0l6.684-6.684a.734.734,0,0,0,0-1.055Z" transform="translate(14.87 0) rotate(90)" fill={arrowSvg[2]} />
                                                                    </svg>
                                                                    {priority}
                                                                </div>
                                                                <div className='edit-view'>
                                                                    {shortForm.toUpperCase()}
                                                                    <img src={require('../../../../assets/img/icon/edit-icon.svg')} alt="Edit" />
                                                                </div>
                                                            </div>
                                                        )
                                                    })) : (
                                                        <p className='card-body-text'>There is no previous ticket.</p>
                                                    )
                                            }


                                            {/* <div className='ticket-list d-flex align-items-center flex-wrap'>
                                                <div className='icon-view'>
                                                    <img src={require('../../../../assets/img/icon/computer-icon.svg')} alt="icon" />
                                                </div>
                                                <div className='ticket-title px-3'>
                                                    <strong>#76888 |</strong> Handoffs not working for chatbot
                                                    <div><strong>requests</strong> <small>4 days ago</small></div>
                                                </div>
                                                <div className='priority'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.87" height="15" viewBox="0 2 25 20">
                                                        <path id="right-arrow" d="M11.243.222a.75.75,0,0,0-1.066,1.055l5.4,5.4H.746A.743.743,0,0,0,0,7.429a.751.751,0,0,0,.746.757H15.582l-5.4,5.395a.765.765,0,0,0,0,1.066.747.747,0,0,0,1.066,0l6.684-6.684a.734.734,0,0,0,0-1.055Z" transform="translate(14.87 0) rotate(90)" fill="#95e452" />
                                                    </svg>
                                                    Medium
                                                </div>
                                                <div className='edit-view'>
                                                    RM
                                                    <img src={require('../../../../assets/img/icon/edit-icon.svg')} alt="Edit" />
                                                </div>
                                            </div>
                                            <div className='ticket-list d-flex align-items-center flex-wrap'>
                                                <div className='icon-view'>
                                                    <img src={require('../../../../assets/img/icon/computer-icon.svg')} alt="icon" />
                                                </div>
                                                <div className='ticket-title px-3'>
                                                    <strong>#76888 |</strong> Handoffs not working for chatbot
                                                    <div><strong>requests</strong> <small>4 days ago</small></div>
                                                </div>
                                                <div className='priority'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.87" height="15" viewBox="0 2 25 20">
                                                        <path id="right-arrow" d="M11.243.222a.75.75,0,0,0-1.066,1.055l5.4,5.4H.746A.743.743,0,0,0,0,7.429a.751.751,0,0,0,.746.757H15.582l-5.4,5.395a.765.765,0,0,0,0,1.066.747.747,0,0,0,1.066,0l6.684-6.684a.734.734,0,0,0,0-1.055Z" transform="translate(14.87 0) rotate(90)" fill="#95e452" />
                                                    </svg>
                                                    Medium
                                                </div>
                                                <div className='edit-view'>
                                                    RM
                                                    <img src={require('../../../../assets/img/icon/edit-icon.svg')} alt="Edit" />
                                                </div>
                                            </div>
                                            <div className='ticket-list d-flex align-items-center flex-wrap'>
                                                <div className='icon-view'>
                                                    <img src={require('../../../../assets/img/icon/computer-icon.svg')} alt="icon" />
                                                </div>
                                                <div className='ticket-title px-3'>
                                                    <strong>#76888 |</strong> Handoffs not working for chatbot
                                                    <div><strong>requests</strong> <small>4 days ago</small></div>
                                                </div>
                                                <div className='priority'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.87" height="15" viewBox="0 2 25 20">
                                                        <path id="right-arrow" d="M11.243.222a.75.75,0,0,0-1.066,1.055l5.4,5.4H.746A.743.743,0,0,0,0,7.429a.751.751,0,0,0,.746.757H15.582l-5.4,5.395a.765.765,0,0,0,0,1.066.747.747,0,0,0,1.066,0l6.684-6.684a.734.734,0,0,0,0-1.055Z" transform="translate(14.87 0) rotate(90)" fill="#95e452" />
                                                    </svg>
                                                    Medium
                                                </div>
                                                <div className='edit-view'>
                                                    RM
                                                    <img src={require('../../../../assets/img/icon/edit-icon.svg')} alt="Edit" />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for ticketing ends */}

            <div id="chat-visitore-nav" className="chat-sidebar">
                <div className="d-flex h-100 flex-column">
                    <div className="right-panel-visitore">
                        <ul className="nav navbar-nav py-3 py-lg-0">
                            <li id="visitorGroupRightIcon" onClick={() => this.showVisitorsList()} className="text-center group1 rightPanelTabSecActive">
                                <span className={this.closeVisitorpanel ? "nav-link position-relative p-0 py-xl-4 cursorPointer rightPanelTabSec" : "nav-link position-relative p-0 py-xl-4 rightPanelTabSec"} title="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="31.773" height="33.073" viewBox="0 0 31.773 33.073">
                                        <g id="group" transform="translate(-5.839)">
                                            <path id="Path_2196" data-name="Path 2196" d="M43.555,6.89a.833.833,0,0,0,1.168-.157,12.856,12.856,0,0,1,20.455,0A.833.833,0,1,0,66.5,5.722a14.522,14.522,0,0,0-23.1,0A.833.833,0,0,0,43.555,6.89Z" transform="translate(-33.225)" fill="#9092a9" />
                                            <path id="Path_2197" data-name="Path 2197" d="M228.037,81.019a2.6,2.6,0,1,0,2.6-2.6A2.605,2.605,0,0,0,228.037,81.019Z" transform="translate(-197.455 -69.685)" fill="#9092a9" />
                                            <path id="Path_2198" data-name="Path 2198" d="M218.741,131.016l-.662-.2a.779.779,0,0,0-.694.121l-1.307.979-1.307-.979a.778.778,0,0,0-.694-.121l-.662.2a2.576,2.576,0,0,0-1.764,2.447V137.4a.776.776,0,0,0,.128.427l1.319,2.008v5.273a.778.778,0,0,0,.778.778h4.4a.778.778,0,0,0,.778-.778v-5.273l1.319-2.008a.777.777,0,0,0,.128-.427v-3.934a2.577,2.577,0,0,0-1.764-2.447Z" transform="translate(-182.893 -116.215)" fill="#9092a9" />
                                            <path id="Path_2199" data-name="Path 2199" d="M24.826,83.622a2.6,2.6,0,1,0-2.6-2.6A2.605,2.605,0,0,0,24.826,83.622Z" transform="translate(-14.56 -69.685)" fill="#9092a9" />
                                            <path id="Path_2200" data-name="Path 2200" d="M12.929,131.016l-.662-.2a.779.779,0,0,0-.694.121l-1.307.979-1.307-.979a.778.778,0,0,0-.694-.121l-.662.2a2.576,2.576,0,0,0-1.764,2.447V137.4a.776.776,0,0,0,.128.427l1.319,2.008v5.273a.778.778,0,0,0,.778.778h4.4a.778.778,0,0,0,.778-.778v-5.273l1.319-2.008a.777.777,0,0,0,.128-.427v-3.934A2.577,2.577,0,0,0,12.929,131.016Z" transform="translate(0 -116.215)" fill="#9092a9" />
                                            <path id="Path_2201" data-name="Path 2201" d="M125.071,42.066a3.357,3.357,0,1,0-3.357,3.357A3.36,3.36,0,0,0,125.071,42.066Z" transform="translate(-99.989 -34.398)" fill="#9092a9" />
                                            <path id="Path_2202" data-name="Path 2202" d="M106.53,111.135l-.006,0-.958-.294a.3.3,0,0,0-.367.183l-2.014,5.527a.364.364,0,0,1-.683,0l-2.014-5.527a.3.3,0,0,0-.279-.2c-.029,0-1.045.307-1.045.307a2.974,2.974,0,0,0-2.041,2.825V119.8a.3.3,0,0,0,.049.163l2.1,3.2v8.093a.3.3,0,0,0,.3.3h6.546a.3.3,0,0,0,.3-.3v-8.093l2.1-3.2a.3.3,0,0,0,.049-.163v-5.862A2.955,2.955,0,0,0,106.53,111.135Z" transform="translate(-81.118 -98.485)" fill="#9092a9" />
                                            <path id="Path_2203" data-name="Path 2203" d="M142.1,111.468a.443.443,0,0,0-.328-.136h-.918a.444.444,0,0,0-.328.136.426.426,0,0,0-.056.5l.491.74-.23,1.938.452,1.2a.138.138,0,0,0,.259,0l.452-1.2-.23-1.938.491-.74A.426.426,0,0,0,142.1,111.468Z" transform="translate(-119.591 -98.934)" fill="#9092a9" />
                                        </g>
                                    </svg>
                                </span>
                            </li>

                            <li id="onlineAgentsRightIcon" className="text-center group1 rightPanelTabSecActive">
                                <span className={this.state.showAgents ? "nav-link position-relative p-0 py-xl-4 rightPanelTabSec" : "nav-link position-relative p-0 py-xl-4 cursorPointer rightPanelTabSec"} data-toggle="tab" onClick={this.showOnlineAgents}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30.11" height="33.109" viewBox="0 0 30.11 33.109">
                                        <g id="Group_4597" data-name="Group 4597" transform="translate(-117 1373)">
                                            <g id="Group_2739" data-name="Group 2739" transform="translate(-1748.002 -1482)">
                                                <g id="specialist-user" transform="translate(1840.33 109)">
                                                    <path id="Path_2229" data-name="Path 2229" d="M101.642,18.672c2.284,0,7.655-2.749,7.655-9.336C109.3,4.18,108.6,0,101.642,0s-7.655,4.18-7.655,9.336C93.987,15.923,99.357,18.672,101.642,18.672ZM95.131,7.778a4.212,4.212,0,0,1,4.2-2.188s1.491,3.616,8.81,1.992c.03.578.039,1.167.039,1.754a8.324,8.324,0,0,1-2.614,6.321,6.686,6.686,0,0,1-3.926,1.9,6.686,6.686,0,0,1-3.926-1.9A8.324,8.324,0,0,1,95.1,9.336C95.1,8.815,95.109,8.292,95.131,7.778Z" transform="translate(-62.755)" fill="#9092a9" />
                                                    <path id="Path_2230" data-name="Path 2230" d="M53.1,198.486c-.391-2.43-1.2-5.56-2.809-6.672-1.1-.76-4.947-2.814-6.588-3.691l-.028-.015a.536.536,0,0,0-.584.051,6.974,6.974,0,0,1-2.8,1.352.537.537,0,0,0-.385.333l-1.016,2.679-1.016-2.679a.537.537,0,0,0-.385-.333,6.974,6.974,0,0,1-2.8-1.352.536.536,0,0,0-.584-.051c-1.622.867-5.514,2.963-6.612,3.7-1.858,1.251-2.67,5.781-2.814,6.676a.537.537,0,0,0,.024.264c.066.188,1.771,4.6,14.187,4.6s14.121-4.409,14.187-4.6a.536.536,0,0,0,.023-.262ZM47.377,197l-2.43.757L42.516,197v-1.3h4.86Z" transform="translate(0 -170.239)" fill="#9092a9" />
                                                </g>
                                                <g id="Group_2700" data-name="Group 2700" transform="translate(-11.473 84)">
                                                    <g id="Group_2697" data-name="Group 2697" transform="translate(9.999)">
                                                        <g id="Ellipse_263" data-name="Ellipse 263" transform="translate(1882.59 44.111)" fill="#0d8" stroke="#fff" strokeWidth="2">
                                                            <circle cx="6.998" cy="6.998" r="6.998" stroke="none" />
                                                            <circle cx="6.998" cy="6.998" r="5.998" fill="none" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </li>

                            {/* Dialer Start */}

                            {(this.canCalling === "phone_voip" || this.canCalling === "phone") ? (<><li className="text-center group1 rightPanelTabSecActive">
                                <span onClick={this.showDialer} className="nav-link position-relative p-0 py-xl-4 cursorPointer rightPanelTabSec" data-toggle="tab" title="Dialer" role="tab">
                                    <span className="donthidebyclkonbdy"><img width="40px" className="donthidebyclkonbdy" src={require('../../../../assets/images/livechat/dialer.png')} /></span>
                                </span>
                            </li></>) : (null)}

                            {/* Dialer End */}

                        </ul>
                    </div>
                </div>
            </div>
        </>
        );
    }
}

export default React.memo(Livechat);